// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import farooq_1 from '../familyPictures/farooq_1.jpg'
// import farooq_2 from '../familyPictures/farooq_2.jpg'


const Farooq = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Farooq Family'
  img1 = {farooq_1}
  caption1 = 'Left to Right: Mariam Farooq, Khadija Farooq, Sauleha Farooq'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“When my mother passed away, I knelt down and touched the soil, and realized that the foundation of my family was now mixed into this very land. Nothing will ever change that, no matter how many political crises, terrorist threats, racist attacks, or white supremacists try to make me doubt that.”'

  quoteBy = '-Mariam'

  paragraph2 = 'Mariam arrived in Outlook, Saskatchewan from England in the early 1970’s at 5-years-old. Her father, who was a surgeon, was offered interesting positions in either Australia or Canada, and as Mariam recalls it, “he tossed. And we ended up here.” Looking back to her initial experiences in Saskatchewan she told me, “I skated for the first time in my life within three days of arriving in Canada. We lived in a strongly Mennonite community and the Neufeld girls took me out on a frozen pond to skate. I was hooked.” Her family was immediately embraced by this tight knit community, and the community with them. “My father was the town surgeon and was well-known in the small close-knit community. I can remember cross-country skiing to the hospital. We were lucky that everyone knew each other so well, even if that meant that if my friends and I grabbed choke-cherries from someone’s property on the way home from the swimming pool, my mother would know about it before I entered the house.” After three years, their family moved on to live in Edmonton because Mariam’s mother felt it was important for her family to experience a Muslim community. Mariam said, “I consider those “aunties” and “uncles” to be like my family now. They had a pioneering spirit and were always more concerned about building the future for us than about enjoying their own lives. I think they were also very optimistic about our future and felt that they were putting down roots for us to enjoy for generations. I don’t have the heart to question them about it now, because it was so tremendously important to them.”'

  paragraph3 = 'Mariam is now part of how the Muslim community adapts and grows within Edmonton, having been a spearhead in the building of “Masjid in the Park,” a mosque in Sherwood Park. With its modern and contemporary architecture, the mosque responds to the area and neighbourhood around it. Mariam told me, “people often ask us why our mosque in Sherwood Park doesn’t have a dome. When we tell them that in history, Muslims adopted domes when they found them beautiful, that there was nothing inherently Islamic about domes and arches, but that beauty was always taken up and uniquely sacralized. We need to stop worrying about our identity, and strive to live in the fullest, richest way.” She continued to say, “traditionally Islam has always adapted to the culture it is in, like clear water taking on the color of the vessel. I think that our community is afraid that if they let go of any cultural markers, they will lose everything. For me, tradition requires movement and flow. If we try to stop that, we become stagnant, not traditional. The vibrancy of a community requires nourishment, growth and creativity. Fear plugs all of that up.”'

  paragraph4 = "Getting to the truth and heart of why things are is integral to Mariam. Her love of learning and seeking out truth led her down a path of homeschooling her children, which is something that gives her great pride. She told me, “we homeschooled our children from K-12, and for a lot of people in the Pakistani community, that was a real step backwards. Although it was emotionally taxing because of all the anxiety around homeschooling, I’m tremendously grateful for the chance it gave us to think about what it means to learn. It was a humbling experience and still is.” Mariam has a lot of faith in the next generation, even though she believes that it might not be so easy for them. “I worry that my granddaughter has a lot more to stand up to now. Although there was racism when I was young, it didn’t have such a broad, discursive reach. Or perhaps each generation has to face their own demons. I do have a lot of hope though, that these young people will be more savvy, and will not hesitate in claiming their rights out of fear that they won’t be accepted. I think first generation immigrants tend to be a little bit too concerned about fitting in.” When I asked her what her thoughts about belonging are, she told me, “I think that I’m able to think beyond that and realize that we need solutions for real problems. Canada is my home. I literally get tears in my eyes when I see the maple leaf no matter where I am in the world. But I don’t need anyone else to affirm my place here. I don’t need that anymore. Instead I need to find opportunities to love, learn and grow as long as I have breath.”"

  paragraph5 = 'Something that she hopes for her children specifically is that they “can work towards establishing justice in their communities for the most vulnerable. I don’t want them to fit into a system in which there are 1000 Missing and Murdered Indigenous Women. I’d rather they stand out and stand up against conditions that create that kind of statistic.”'

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''

  />
</>
)
}
export default Farooq
