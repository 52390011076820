// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import jaroudi_1 from '../familyPictures/jaroudi_1.jpg'
import jaroudi_2 from '../familyPictures/jaroudi_2.jpg'


const Jaroudi = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Jaroudi Family'
  img1 = {jaroudi_1}
  caption1 = 'Left to Right: Maya Urban, Ahlam Jaroudi'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“As a photographer myself, I am always documenting our life through photographs. Growing up, we didn’t have many photos outside the popular family trips and birthdays. I want my kids to have a little sense of their own history and their upbringing…I want my grandkids and my great great grandkids and so on to know who we were and what we did to get here.”'

  quoteBy = '-Rania'

  paragraph2 = 'Ahlam, 8 months pregnant with Rania, her two other children, and husband came to Canada from Beirut, Lebanon in 1989. They joined Ahlam’s family in Edmonton seeking refuge from the war, stability, and a more prosperous future. After some time in Edmonton, they moved to Vancouver, mainly in search of better weather. Ahlam told me the first while was extremely difficult as she “couldn’t speak the language and it was very frustrating trying to communicate with people. I truly wanted nothing more than to just go home.” There were still moments for luck and laughter, even among the difficulty. Ahlam told me a story regarding her first few months while still pregnant with Rania: “We needed a couch set for our living room. I was going to purchase a set from my brother for $500. However, my sister-in-law called me and wanted to go furniture shopping. I went along with her just to look. But after a while of shopping, I decided I was too tired to continue browsing. I told my sister-in-law to go on without me while I waited in a doorway in the furniture store. All of a sudden people started crowding [around] me and screaming. I was terrified. I didn’t speak English, I didn’t know what I had done for all these people to start coming towards me. They were even photographing me! My sister-in-law came back and I called for her right away to come and see what was happening. I told her I didn’t do anything!! She began laughing. A reporter of some sort came up to me and started asking me questions. My sister-in-law interrupted her and told her, she doesn’t speak English!! The reporter told her, we have a contest on and the person who stands here at this hour for x [amount of] time wins a couch set! I couldn’t believe what I was hearing. It was the most exciting thing that happened and I had no idea it was happening!”'

  paragraph3 = 'When I asked Ahlam how she feels now in Canada she told me, “I definitely feel like I don’t belong, even after all these years. I have lived in Canada for 30 years and I still struggle with the culture here. I miss my home and my family. It’s bittersweet. On one side of it, I feel like I have lived here for 30 years, of course I belong. But on the other side of it, I feel like I have lived here for 30 years and I still don’t feel like this is where I should be. I still don’t speak English very well although I do understand everything for the most part; it is hard for me to translate things in my head to make sense of them quickly. Having stayed home with my kids for the last 30 years, I never needed to speak English really until they all moved out and I had no one to speak for me anymore. That’s when it became even more difficult, making me miss Lebanon and the culture there again as I did in our early years of immigration. I still miss my home and wish I could go back.”'

  paragraph4 = "Rania, having been the first member of her family born and raised in Canada, said she doesn’t know anywhere else, so of course she feels she belongs. She did mention though, that taking classes at her local mosque growing up gave her an opportunity to relate to other kids culturally, something she wasn’t able to do at school. She told me, “In the regular school system, I didn’t know anyone else who was like me but also who had parents like me! My parents were pretty strict growing up, so having an outlet of other peers I could talk to was nice.”"

  paragraph5 = 'Ahlam and Rania acknowledge a large generational gap. Ahlam said, “Every culture has change between each generation. The younger generation will always look at the older generation as difficult or closed minded. But that is not true. We grew up this way and that is all we knew. When you have a change as big as ours which was moving from country to country, of course there will be big differences.” Rania added: “the changes are always cultural. Instead of us forcing our Canadian community to adapt to the ways that my parents were used to in Lebanon, we were obviously forced to adapt to the ways here. I think the biggest thing that has changed is what is expected of a woman to be honest.” She continued to talk about her marriage, and how that stepped outside of cultural expectations: “It was always expected of me to grow up and marry a man who comes from the same religion and cultural background. One who speaks the language and will be able to act as a guide maybe when I fall off track or something. That wasn’t what was written for me. I wish I could change the way people in my culture see my family. I always feel like relatives or people in our community see me as someone who deviated outside of our culture’s expectation. The expectation as a Muslim woman to grow up and marry a Muslim man, likely from the same cultural background or upbringing. I feel like this is an issue in our culture. Why is there this expectation and why does the way my husband looks change the way I should be treated? It’s frustrating at times and even when people tell me “we don’t treat your family differently,” I can think of many instances when we weren’t. That’s definitely something I wish I had the power to change.”'

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {jaroudi_2}

  caption2 = 'Left to Right: Ahlam Jaroudi, Rania Urban'
  extraParagraph = ''

  />
</>
)
}
export default Jaroudi
