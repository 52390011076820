// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import abdi_1 from '../familyPictures/abdi_1.jpg'
// import abdi_2 from '../familyPictures/abdi_2.jpg'


const Abdi = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Abdi / Elmi Family'
  img1 = {abdi_1}
  caption1 = 'Left to Right: Samiya Abdi, Fadumo Elmi, Rahma Gulaid'

  paragraph1 = 'Samiya and her family arrived in Toronto in the mid 1990’s. They are originally from Somalia, but traveled to Canada from the Middle East where they lived for some time due to the Somali Civil War. Her family chose to come to Canada because of “existing familial connections to Canada, as parts of [their] extended family were already here.” Samiya shared, “Our parents chose immigrating to Canada so we, the children, could pursue higher education.” When I asked Samiya, who was in her mid-teens when they moved, what her first few days were like, she told me, “We were fortunate to arrive during the summer months so no winter shock, we came from a multicultural urban city so no culture shock, and [we] spoke good English so we could easily navigate the city... We had not seen our extended family members who lived in Canada for over 10 years, so it was a warm welcome and a welcomed connection.” She told me, “We felt more of a sense of a community in Toronto than in the Middle East where we lived after leaving Somalia, Fridays were Mosque days, there were always picnics, events, and even sleep overs which were something we never experienced before as kids.”'

  paragraph2 = 'When I asked Samiya what she is most proud of in regards to her family she told me, “Achieving material success in the form of gainful employment and education meanwhile being firmly rooted in community service, Islamic faith, and Somali culture.” Samiya, who holds a Master’s degree in Public Health, works as a consultant and speaker in public health, and is passionate about creating health equity, which she describes as: when social justice meets public health. She has led multiple provincial health care initiatives across Ontario. She is passionately working towards the “betterment of society, though activism, advocacy, and community work grounded in social justice.” Her work is “grounded in challenging multiple and intersecting forms of oppression, understanding marginalization in knowledge production, research and practice, and building equitable relationships.”'

  paragraph3 = 'Samiya also actively works with Somali and Muslim communities where she has created engagement programs such as Aspire 2 Lead, The Muslim Youth Political Fellowship, which is a non-partisan civic and political leadership training for Muslim youth leaders in the City of Toronto. She has also co-founded international movements such as Famine Resistors and the Somali Gender Equality Movement.'

  paragraph4 = 'When I discussed gender equality and the importance of women’s stories with Samiya, she told me, “women are the keepers of knowledge and wisdom, they are the storytellers and the ones that have been leading much positive transformation for our communities.” She hopes their stories will be archived as “it means an affirmation of our existence in the here and now, also that we are here to stay!” Samiya shared a Somali proverb with me: ‘Gabri Waa Hooyadeed’ which translates to ‘A girl is her mother.’ She continued to tell me that in Somali tradition there are no family names and a woman does not take the name of her husband but has her own set of names which are given to her.'

  paragraph5 = 'I asked Samiya if there is a dominant issue facing her community that she wishes she could change. She shared with me, “2020 has been a challenging year not only due to COVID-19 that is disproportionately killing Black community members but also due to the persistent 400 years long pandemic of Anti-Black Racism. We are reeling from the countless Black lives that have been taken and continue to be taken! So yes we would love to see the end of white supremacy and the end of Anti-Black racism. We would love to wake up to a world that celebrates and promotes Black joy, Black Art, Black knowledge, Black wisdom and Black brilliance.”'

  paragraph6 = 'Samiya told me her hopes for the future generations are “for them to always feel home, for them to remember whose land they are on, and to actively work towards reconciliation! For them to celebrate their Blackness, Somaliness, and Islam, and carry their identities as badges of honor that brings about positive attributes to who they are as individuals and members of the society. For them never to be asked to compartmentalize their intersecting identities but be viewed as the whole beautifully brilliant beings they are.”'

  img2 = ''
  caption2 = ''
  paragraph7 = ''
  paragraph8 = ''
  paragraph9 = ''
  paragraph10 = ''
  paragraph11 = ''

  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Abdi
