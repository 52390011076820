import { Row, Col,Container} from 'react-bootstrap';

import cancel from "./icons/cancel.png"
import {Link} from 'react-scroll'
import { Link as PageLink} from 'react-router-dom';
const SideMenu = ({ closeMenu, currentFam, isMobile})=>{

return(

<>
{isMobile ?
  <Container className = 'side-menu-mobile'>
    <Row className = 'margin-top-5 margin-bottom-5'>
      <Col className = 'col-30'>
        &nbsp;
      </Col>
      <Col>
        <img src={cancel} style = {{width:'4vw',position:'fixed', marginLeft:'30vw'}} alt="#"  onClick ={closeMenu}/>
      </Col>
    </Row>

    <Row className = 'margin-top-5 margin-bottom-2 horizontal-centered-children'>
        <PageLink to="/about">About</PageLink>
    </Row>
    <Row className = ' horizontal-centered-children'>
        <PageLink to="/land">Land Acknowledgement</PageLink>
    </Row>

    <hr className = "menu-underline_mobile"/>


    <Row className = "horizontal-centered-children margin-bottom-1" style = {{marginTop:'-5vh'}}>
      <Link  to="Hamdon" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Hamdon Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Hamdon</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Ali1" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Ali Family ')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Ali (Vancouver)</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Beydoun" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Beydoun /  Matar Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Beydoun / Matar</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Ahmed" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Ahmed Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Ahmed</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Badawi" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Badawi Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Badawi</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="ashraf" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Junaid / Ashraf Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Junaid / Ashraf</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Bejkosalaj" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Bejkosalaj-Kuki Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Bejkosalaj-Kuki</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Ali2" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Ali Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Ali (Ottawa)</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Habib" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Habib Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Habib</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Aldosky" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Aldosky / Albarzanjy Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Aldosky / Albarzanjy</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Hindy" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Hindy Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Hindy</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Faruqui" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Faruqui Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Faruqui</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Jahangeer" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Jahangeer Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Jahangeer</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Abdi" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Abdi / Elmi Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Abdi / Elmi</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Morsi" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Morsi Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Morsi</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Farooq" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Farooq Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Farooq</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Jaroudi" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Jaroudi Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Jaroudi</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Dada" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Dada Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Dada</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Gilani" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Gilani Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Gilani</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Hussain" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Hussain Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Hussain</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Khan" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Khan Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Khan</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Afsar" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Afsar Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Afsar</p>
      </Link>
    </Row>



    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="hadad" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Hadad /  Alzaabi Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Hadad / Alzaabi</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="Jamal" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Jamal Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Jamal</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="ibrahin" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Ibrahin Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Ibrahin</p>
      </Link>
    </Row>

    <Row className = "horizontal-centered-children margin-bottom-1">
      <Link  to="youssef_1" spy={true} smooth={true}>
        <p className = {((currentFam === 'The Youssef Family')? 'red-text side-menu-family-mobile' : 'side-menu-family-mobile')}>Youssef</p>
      </Link>
    </Row>
  </Container>

:


<Container className = 'side-menu ' style = {{marginTop:'-23.5vh', marginLeft:'0vw'}}>
  <Row className = ' margin-bottom-5'>
    <Col className = 'col-20'>
      &nbsp;
    </Col>
    <Col>
      <img src={cancel} style = {{width:'1.1vw',position:'fixed',marginTop:'2.5vh'}} alt="#"  onClick ={closeMenu}/>
    </Col>
  </Row>


  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Hamdon" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Hamdon Family')? 'red-text side-menu-family' : 'side-menu-family')}>Hamdon</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Ali1" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Ali Family ')? 'red-text side-menu-family' : 'side-menu-family')}>Ali (Vancouver)</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Beydoun" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Beydoun /  Matar Family')? 'red-text side-menu-family' : 'side-menu-family')}>Beydoun / Matar</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Ahmed" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Ahmed Family')? 'red-text side-menu-family' : 'side-menu-family')}>Ahmed</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Badawi" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Badawi Family')? 'red-text side-menu-family' : 'side-menu-family')}>Badawi</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="ashraf" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Junaid / Ashraf Family')? 'red-text side-menu-family' : 'side-menu-family')}>Junaid / Ashraf</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Bejkosalaj" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Bejkosalaj-Kuki Family')? 'red-text side-menu-family' : 'side-menu-family')}>Bejkosalaj-Kuki</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Ali2" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Ali Family')? 'red-text side-menu-family' : 'side-menu-family')}>Ali (Ottawa)</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Habib" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Habib Family')? 'red-text side-menu-family' : 'side-menu-family')}>Habib</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Aldosky" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Aldosky / Albarzanjy Family')? 'red-text side-menu-family' : 'side-menu-family')}>Aldosky / Albarzanjy</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Hindy" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Hindy Family')? 'red-text side-menu-family' : 'side-menu-family')}>Hindy</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Faruqui" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Faruqui Family')? 'red-text side-menu-family' : 'side-menu-family')}>Faruqui</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Jahangeer" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Jahangeer Family')? 'red-text side-menu-family' : 'side-menu-family')}>Jahangeer</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Abdi" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Abdi / Elmi Family')? 'red-text side-menu-family' : 'side-menu-family')}>Abdi / Elmi</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Morsi" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Morsi Family')? 'red-text side-menu-family' : 'side-menu-family')}>Morsi</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Farooq" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Farooq Family')? 'red-text side-menu-family' : 'side-menu-family')}>Farooq</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Jaroudi" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Jaroudi Family')? 'red-text side-menu-family' : 'side-menu-family')}>Jaroudi</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Dada" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Dada Family')? 'red-text side-menu-family' : 'side-menu-family')}>Dada</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Gilani" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Gilani Family')? 'red-text side-menu-family' : 'side-menu-family')}>Gilani</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Hussain" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Hussain Family')? 'red-text side-menu-family' : 'side-menu-family')}>Hussain</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Khan" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Khan Family')? 'red-text side-menu-family' : 'side-menu-family')}>Khan</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Afsar" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Afsar Family')? 'red-text side-menu-family' : 'side-menu-family')}>Afsar</p>
    </Link>
  </Row>



  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="hadad" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Hadad /  Alzaabi Family')? 'red-text side-menu-family' : 'side-menu-family')}>Hadad / Alzaabi</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="Jamal" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Jamal Family')? 'red-text side-menu-family' : 'side-menu-family')}>Jamal</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="ibrahin" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Ibrahin Family')? 'red-text side-menu-family' : 'side-menu-family')}>Ibrahin</p>
    </Link>
  </Row>

  <Row className = "horizontal-centered-children margin-bottom-1">
    <Link  to="youssef_1" spy={true} smooth={true}>
      <p className = {((currentFam === 'The Youssef Family')? 'red-text side-menu-family' : 'side-menu-family')}>Youssef</p>
    </Link>
  </Row>
</Container>

}
</>
)};

export default SideMenu;
