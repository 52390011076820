// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import dada_1 from '../familyPictures/dada_1.jpg'
import dada_2 from '../familyPictures/dada_2.jpg'


const Dada = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Dada Family'
  img1 = {dada_1}
  caption1 = 'Left to Right: Fatema Dada, Zaibun Dada, Akila Dada, Sakina Dada'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = 'Zaibun and her husband moved to Kitchener in 1980 from Dar es Salaam, Tanzania. They decided to immigrate because, “we wanted a better life for our children. The situation in Tanzania was not good at the time. There was no adequate healthcare or education opportunities. After we got married, we went on a world tour for our honeymoon and when we got to Canada, we liked it and also my husband saw good business opportunities here. We also chose to stay in Canada because my brother lived here at the time.” She said the first few days were like vacation, as they arrived in the summer, they visited different places and family, and the people were very friendly. She told me, “eventually though, the vacation wore off, we missed our families. My husband worked every day. I had three children each a year apart - I felt like I was raising them all alone. Back home there are always so many people to help out. My third child Sukaina was premature. Taking two kids to the hospital every day by taxi so I could feed my third child was so hard. I would give them a pack of M&M’s and ask the nurse at the front desk to watch them while I went to go feed my third in the room. We struggled financially as well and my husband worked long hours while I watched three kids. Eventually I learned to drive which really helped.” She shared that as her children grew, she became really friendly and acquainted with her neighbours, who would help her out with babysitting, gave tips on the city, and became like a second family to them.'

  paragraph2 = 'The entire family spoke really highly of growing up in Kitchener. Fatema shared, “growing up we all wore hijab [and] not once did we ever feel left out. I went to highschool at Waterloo Collegiate Instiute, I ran for Student Council (and won!) I lead our schools’ multicultural program.” Zaibun added, “raising our family in Kitchener, we never felt out of place. A predominantly white neighbourhood, we were welcomed with open arms. On Christmas our children would be showered with gifts, on Eid we would send around Indian sweets and bring gifts for the children. It was a happy utopia where everyone genuinely cared for each other as individuals despite skin color or religion.” Eventually many of their extended family immigrated to Kitchener as well, often staying with them until they found work and a home. They watched as Kitchener’s Muslim community grew, eventually seeing mosques being built, and grocers with halal options popping up. A relief to them as for many years they had to drive all the way to Toronto to get halal or cultural food options.'

  paragraph3 = 'They remarked that they’re not sure that if they had immigrated these days if it would have been that same utopia they reminisced about. “I have to wonder if we had to do it again - would people welcome me and my husband with our broken English and accents? I hear Muslim kids being bullied in school, we hear rants of ‘go back to where you came from’ attempts by certain governments to ban the hijab and niqab [and] a general hatred for the ‘other’. It’s scary.” Fatema added, “I worry for my nieces and nephews. I grew up in a community that accepted me for who I was. I wore hijab in high school and not once was I bullied, called names, not once did I not feel welcome. I worry that we have lost that ‘Canadian charm’ and that younger generations will face adversities that shouldn’t exist - that divide rather than unite.”'

  paragraph4 = "When I asked the family if they had any interesting stories they wanted to share from their childhood or their early days in Kitchener, Zaibun shared, “my husband opened up a business in a small plaza in Kitchener and we made such good friends with the other business owners that it felt like our own little extended family.” Fatema added, “My dad owned a general dollar store at one point in his life. I remember for Eid - he would bring his van, load it up with toys and then we would all go to the mosque together. After the mosque he would open up the truck and all the kids would line up and we would help dad give out free gifts to everyone. There was never any gift left over for us. Looking back, I now know it was a crucial lesson on the importance of giving back to the community - a lesson that will stick with me for the rest of my life.”"

  paragraph5 = 'Surely it did stick with Fatema and her sisters as they all are extremely dedicated to the charity Sakina founded called SMILE Canada. Sakina shared, “They taught us the importance of giving. Because of those values SMILE started and continues to thrive. All three of us sisters are involved in the organization and put 110 percent to ensure that we can help kids with disabilities in the community. Akila added, “I am happy with the community outreach work that my family is a part of. Giving back to the community is something I hope our future generations will continue to do. SMILE Canada was started by Sakina and she looped both me and Fatema to help out. It’s often the only thing we talk about when we get together - but through everyone’s collective efforts and the efforts of so many volunteers the organization has grown to great lengths supporting more than 200 families with kids with disabilities.”'

  paragraph6 = 'When I asked their family what they are most proud of, Fatema shared how her mom and dad started from scratch, how they started from the bottom, but now have fruitful and fulfilling lives in every aspect. Zaibun shared with me, “we came here for a better life for our children, leaving behind our easy life with our family and friends. We struggled a lot, being alone, being tired, working hours endlessly, not having extra help, but we came out successful. My husband had no education from Tanzania, and I had not gone to university. It was very hard, and over the years we have had many ups and downs, but we were able to successfully run our business, put our three daughters through school, get them married and now we are getting to spend our time with them and their families. When we look back at our life here in Canada, we are happy that we came to this country and proud of the work we have done and that our daughters are doing.” She added she is also proud of how she “became more religious and spiritual living here in Canada.”'

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {dada_2}

  caption2 = 'Left to Right: Aayah Khakoo, Zainbun Dada'
  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Dada
