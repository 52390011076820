// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import beydoun_1_new from '../familyPictures/beydoun_1_new.jpg'
import beydoun_2 from '../familyPictures/beydoun_2.jpg'


const Beydoun = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Beydoun / Matar Family'
  img1 = {beydoun_1_new}
  caption1 = 'Left to Right: Ghada Matar, Fatima Beydoun'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = "“I think we need more solidarity between immigrants, specifically Muslim communities, and the Indigenous peoples of this land. I hope that the next generation can be that actualization of what meaningful reconciliation can be through recognizing the colonial past of this country and how our settlement here has been a part of ongoing colonization against Indigenous peoples, unintentional or not. I also hope that the next generation can achieve social reform that is inclusive of all.”'"

  quoteBy = "-Fatima"

  paragraph2 = 'Ghada and her family arrived in Ottawa in 1980 when she was 12 years old. She told me, “My brother came to Canada in 1979 as a student with hopes of bringing the rest of the family. We were in West Berlin as refugees at the time, having escaped a civil war that was devastating Lebanon, to find ourselves in the middle of more conflict. On December 20th, 1980, we arrived in Ottawa, where the rest of my family still reside. We chose Canada for several reasons: safety [and] a place where we could all be together. It was the first time that we weren’t wondering “where are we going next?” We were considered refugees in Lebanon, our own land, because of the displacement of the villages at the border of Lebanon/Palestine in 1948, and also refugees in Berlin. At 12, Canada would become my third home. They say good things come in threes.”'

  paragraph3 = 'When I asked her what she can recall from that time in her life she told me, “I still remember the experience of seeing a snowy Ottawa having come in the winter. We were happy because we were able to reunite with my brother after trying for so many years. I was a child, the youngest of 11 children. I do not remember much, but that I struggled to learn English and had to translate everything from German to English. The rest of the family struggled to find work, learn English, and to find halal food. Adapting is not easy but slowly we found more people like us - displaced but searching for a new life here.”'

  paragraph4 = "Soon after, their new life in Ottawa included opening up a restaurant in the city’s downtown called “Samira” that “served Lebanese and Italian cuisine, named after the famous Arabic singer Samira Tawfik. At the time there were not as many Lebanese restaurants and while it was only open for a few years, it was a space for cultural exchange through regular poetry, dance, and music.” It would have been one of the first Lebanese and Arab restaurants, in what is now called the “capital of Shawarma,” as Ghada remarked that “the community was very small, almost non-existent. There were small families and university students.” She continued to say, “Early on, our home became one of the main gathering spots for people to come together, socialize, and hold religious ceremonies. We would also hold weddings in our homes. Once the community got bigger as more Muslim families came later on during the civil war, we would start renting university halls to hold religious gatherings. Today, when we go back to Ottawa to visit our relatives, you can see that the community is so large, and there are several large Lebanese Muslim community centers.”"

  paragraph5 = 'In a sense, when Ghada moved to Halifax, with her husband and children, she would see that same community trajectory repeat itself in a city that was just beginning to see its own big rise of immigrants and refugees like what Ottawa had experienced a few decades earlier. She told me, “despite our direct family being alone here in Halifax, we have been able to integrate quite well and build up a sense of community to be able to call it home. My kids have been able to overcome not growing with many other Muslims and still remain proud of their culture and identity.” Fatima, Ghada’s daughter, added, “I think because I see more and more people who look like me nowadays, I feel there is a bigger sense of involvement. There are more opportunities for interactions and wider acceptance. As I’ve grown older, I’ve found my community of friends that are accepting of my differences that I was less confident about when I was younger and at a school where there were no other racialized Muslim girls.”'

  paragraph6 = 'When I asked Fatima what she’s most proud of in regards to her family, she told me, “One thing that the whole family is very proud of is the achievement of my mother. She was the only girl and the first person in her family to receive a university/college degree. I know that was something that my older cousins admired about my mom, the fact that she got her education before getting married, and started taking her lead. It makes me really proud as her daughter to hear them talking about how much they look up to my mom.” Ghada’s children have followed in her footsteps with her ambition and leadership. She said, “all that they do and stand for are my greatest achievements and my biggest pride. Each one demonstrates such ambition and humanity with all that they do. For Fatima, we are all very proud of the leadership positions she has taken up over the past few years and the advocacy work she does outside of her studies.”'

  showPart2Label = {false}

  paragraph7 = 'Fatima is certainly a prominent voice of our next generation. Last year, she spoke at the House of Commons in her participation in the Daughters of the Vote. When speaking to her about the labels people associate with immigrants, she told me, “I feel like oftentimes immigrants are expected to constantly have to work hard in order to prove that they are “worthy” of being here in Canada, which is a harmful trope that doesn’t apply to anyone else. I feel like myself and other people of a younger generation have grown to be more critical of existing policies and Canadian tropes that imply assimilation or tokenism. I think mainly the younger generations’ confidence to demand more and better from those around us. We recognize that we have a place here in our societies and do not have to tolerate and conform to aggression put against us because we have less fear of losing things as our older generation did.”'

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {beydoun_2}

  caption2 = ''
  extraParagraph = ''

  />
</>
)
}
export default Beydoun
