import './App.css';
import './index.css'
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./components/main"
import About from "./components/about"
import Contact from "./components/contact"
import Land from "./components/land"
import Hamdon from "./components/familyPages/hamdon"
import Ali from "./components/familyPages/ali"
import Beydoun from "./components/familyPages/beydoun"
import Ahmed from "./components/familyPages/ahmed"
import Badawi from "./components/familyPages/badawi"
import Ashraf from "./components/familyPages/ashraf"
import Bejkosalaj from "./components/familyPages/bejkosalaj"
import AhmedAli from "./components/familyPages/ahmed_ali"
import Habib from "./components/familyPages/habib"
import Aldosky from "./components/familyPages/aldosky"
import Hindy from "./components/familyPages/hindy"
import Faruqui from "./components/familyPages/faruqui"
import Jahangeer from "./components/familyPages/jahangeer"
import Abdi from "./components/familyPages/abdi"
import Morsi from "./components/familyPages/morsi"
import Farooq from "./components/familyPages/farooq"
import Jaroudi from "./components/familyPages/jaroudi"
import Dada from "./components/familyPages/dada"
import Gilani from "./components/familyPages/gilani"
import Hussain from "./components/familyPages/hussain"
import Khan from "./components/familyPages/khan"
import Afsar from "./components/familyPages/afsar"
import Sheikh from "./components/familyPages/sheikh"
import Hadad from "./components/familyPages/hadad"
import Jamal from "./components/familyPages/jamal"
import Ibrahin from "./components/familyPages/ibrahin"
import Youssef from "./components/familyPages/youssef"

import ScrollToTop from "./components/scrollToTop"

function App() {
  return (

      <Router>
        <div className = "app-div padding-bottom-small smooth-scroll-section">
          <Route exact path="/" component={Main} />
          <Route exact path="/generations" component={Main} />
            </div>
            <div className = "app-div padding-bottom-small">
          <ScrollToTop />
          <Route exact path="/about" component={About} />
          <Route exact path="/land" component={Land} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/hamdon" component={Hamdon} />
          <Route exact path="/abdi" component={Abdi} />
          <Route exact path="/ali" component={Ali} />
          <Route exact path="/beydoun" component={Beydoun} />
          <Route exact path="/ahmed" component={Ahmed} />
          <Route exact path="/badawi" component={Badawi} />
          <Route exact path="/ashraf" component={Ashraf} />
          <Route exact path="/bejkosalaj" component={Bejkosalaj} />
          <Route exact path="/ali2" component={AhmedAli} />
          <Route exact path="/habib" component={Habib} />
          <Route exact path="/aldosky" component={Aldosky} />
          <Route exact path="/hindy" component={Hindy} />
          <Route exact path="/faruqui" component={Faruqui} />
          <Route exact path="/jahangeer" component={Jahangeer} />
          <Route exact path="/morsi" component={Morsi} />
          <Route exact path="/farooq" component={Farooq} />
          <Route exact path="/jaroudi" component={Jaroudi} />
          <Route exact path="/dada" component={Dada} />
          <Route exact path="/gilani" component={Gilani} />
          <Route exact path="/hussain" component={Hussain} />
          <Route exact path="/khan" component={Khan} />
          <Route exact path="/afsar" component={Afsar} />
          <Route exact path="/hadad" component={Hadad} />
          <Route exact path="/jamal" component={Jamal} />
          <Route exact path="/ibrahin" component={Ibrahin} />
          <Route exact path="/youssef" component={Youssef} />
        </div>
      </Router>

  );
}

export default App;
