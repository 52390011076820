// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import ibrahin_1 from '../familyPictures/ibrahin_1.jpg'


const Ibrahin = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Ibrahin Fmaily'
  img1 = {ibrahin_1}
  caption1 = 'Left to Right: Qali Ibrahin, Sudi Mohamed'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“I’m proud to be a Canadian and although not everyone believes I belong here I know it is my home.”'

  quoteBy = '-Qali'

  paragraph2 = 'Sudi, her husband Ali, and 3 of their eldest children, including Qali, arrived in Halifax in 1997 from living in a Kenyan refugee camp for 7 years. They left Somalia once the civil war broke out, fleeing for their safety. Finally, after a complicated process, Canada accepted them and placed them in Halifax. Sudi shared, “Coming to Canada was life changing, the first few days were exciting trying to learn the culture and system around us. At the same time, there was some stress when adapting to the new culture.” They shared that the what they came to Halifax the Muslim community was strong and big. “They helped show us where the masjid was, and the Islamic schools were. They welcomed us with open arms. As more Muslims come to Halifax, our community continues to grow, still welcoming others.”'

  paragraph3 = 'Sudi and her husband embraced Halifax and both made it their mission to thrive and give back. Her husband, Ali, joined the Halifax Fire Department and has been a firefighter for the last 12 years. Sudi went through university and received a diploma in nursing, all while having five more children and raising her growing family. After seven years Sudi is now a practicing nurse in the Halifax area. She told me, “the challenges I faced as a student nurse was maintaining a balance between my studies and my personal life as a Mother. I was always worried about neglecting my personal life [or how my personal life] will have a negative impact on my studies.” I asked Sudi what made her want to pursue nursing. She told me, “My family has a broad history as healthcare professionals. My aunt was a Gynaecologist/Midwife [so] I remember as a child, listening to my aunt talking about how she dealt with a wide range of issues, including obstetrics, pregnancy and childbirth, menstruation and fertility issues, hormone disorders, and others. Since then, I’ve always loved helping and taking care of people, and making a difference in their lives. I always wanted to be a Gynaecologist like my aunt. Unfortunately, when the civil war happened in Somalia 1991 I thought I lost all my dreams, but coming to Canada gave me a hope and opportunity to go back to school and graduate as a Nurse. Hopefully someday I will continue to pursue my dreams to become a doctor Gynaecologist/Midwifery.” Sudi told me her proudest professional moments were “when I graduated from Nursing School [and when] I had the pleasure of caring for a hospice patient for a couple of months. When she passed away, the family thanked me for providing their loved one with outstanding care. I was incredibly touched that they appreciated all that I had done.” She told me her favourite thing about being a nurse is “the feeling that comes when I alleviate any type of suffering.”'

  paragraph4 = "Qali is now following in her family’s footsteps and showing an interest in health care. She told me, “I wanted to do something involved with healthcare due to the influence of my parents. Helping those who you can was always taught in our household so I hope to make that into a career. My hopes for the future maybe after I graduate is to work as a recreational therapist for a while and then do more school to further my knowledge on the topic...My mother’s journey has inspired me beyond belief. Seeing her reach for her dreams has only fuelled my desire to expand my knowledge and reach for my own dreams.”"

  paragraph5 = 'When she is not at school Qali loves to hang out with her friends, volunteer at the local masjid and Somali youth groups. When I asked Qali what an issue she would like to see change is, she told me, “being a black, visibly Muslim woman in today’s society definitely has its challenges so I would like to change the narrative on all three of those… [there are] many stereotypes and biases that need to be changed.” She shared that she hopes we can “broaden the idea of diversity in all places.” She also hopes that the next generation “will take more steps toward women empowerment and equality.”'

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''

  />
</>
)
}
export default Ibrahin
