// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import hindy_1 from '../familyPictures/hindy_1.jpg'
import hindy_2 from '../familyPictures/hindy_2.jpg'
import hindy_3 from '../familyPictures/hindy_3.jpg'


const Hindy = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Hindy Family'
  img1 = {hindy_1}
  caption1 = 'Left to Right: Imaan Muhammad, Rayanne Hindy, Duha Muhammad, Nora Hindy, Maryam Hindy, Salma Hindy, Elham Abdel-Hamid, Angie Hindy, Sarah Hassan, Ayah Hassan, Leena Hassan, Hana Hassan'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“My hopes are that the next generation feel more comfortable in their own skin. That their heritage and identity is embraced wholly in Canadian culture. That they don’t feel like they have to conform in any way. That they can explore different facets of their identity freely without feeling any internal guilt or external resistance.”'

  quoteBy = "-Salma"

  paragraph2 = "Elham joined her husband, Aly, who was pursuing his Masters and PhD in London, Ontario in 1976 from Cairo, Egypt. They chose Canada as a place to start over and to invest in their future. A future that encompasses them being a major pillar to the Muslim community in the Greater Toronto Area. When Elham first arrived in London, Ontario there wasn’t much of a community though. She told me, “There were only three Muslim families in London, Ontario at the time. Maybe four. There was a mosque, but it was used as a party room as well. They had upstairs designated as a prayer space and downstairs as a banquet hall. It was kind of like a multi-use building. We didn't have many resources.” As their family grew, and the Muslim community as well, they poured more and more of themselves into building and growing community. Elham said, “we didn’t take the degrees [and then go] home, we stayed in Canada, became citizens, and contributed to the benefit of the country. We started schools for the community. We helped create services for the community. And [now] our kids are following the same path.”"

  paragraph3 = "For the past 20 years, Elham has been a principal at an Islamic school in Mississauga. She told me, “It’s a big achievement… [that] outweighs most of the negatives in my life. I got involved in something that is good, that I enjoy, and God has blessed me in it.” Although it’s been fulfilling, her role hasn’t been easy. She told me, “running an Islamic School is one of the most difficult things. We don’t receive financial assistance from the government as private Catholic Schools do, for example. We rely on our community for funding to purchase buildings. We face A LOT of hurdles when it comes to permits and such. Families struggle to pay tuition let alone donate large sums.” But she has persevered regardless. Her children have been prospering in their communities and their different fields as well. Salma, Elham’s daughter, has a Master’s in Engineering, practices engineering, and is also a successful comedian who frequently tours North America and the globe, combating stereotypes and discussing identity by eliciting a room full of laughter. When I asked Salma what she’s proud of, she avoided mentioning her own accomplishments and shared her love for her family: “I am very proud of how established my family is in the Canadian community. My parents have established themselves as leaders in the Islamic community with their school and center. My brother is a wonderful Iman who is at the forefront of building bridges with the younger generation and their faith. My sister Angie has a company [Leena’s Hijabs] that designs hijab for younger girls that she started with her daughters. My sister Nora is a vice principal in the Peel Region. Best of all, they have great character and even better personalities and for that I am most proud.”"

  paragraph4 = "When I asked Elham what her experiences with belonging are from 1976 to now she shared, “I came in August [to a] brand new country and city. I was very excited to be in a new country. It was later when the depression set in…I [became] depressed and homesick. Now, the relationship has started to get better because of my involvement in the society, being able to practice my religion freely, being able to do work that helps my community. I might not [have even been] able to do that if I were in Egypt. I’ve lived so many years in Canada. More than my life in Egypt. I was 23 when I left Egypt…I feel like I belong here more than my birth country… I feel like I actually belong and am making a dent.”"

  paragraph5 = ''

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {hindy_2}

  caption2 = ''

  img3 = {hindy_3}
  extraParagraph = ''
  />
</>
)
}
export default Hindy
