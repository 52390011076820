// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import ashraf_1 from '../familyPictures/ashraf_1.jpg'

const Ashraf = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Junaid / Ashraf Family'
  img1 = {ashraf_1}
  caption1 = 'Left to Right: Shaheen Junaid Ashraf, Saadia Ashraf'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“The very basic aspect of mixing “culture” with “religion” is what I would wish to change. I think (and am hopeful too) that the younger generation will realize these differences and change that. The older generation is very stuck in their idea of “religion.”'

  quoteBy ="-Shaheen"

  paragraph2 = 'Shaheen, her husband, and four children, arrived in Montreal in 1976 from Karachi, Pakistan. They were sponsored by her sister-in-law and decided to settle in Montreal as it was the hub of the textile industry at the time, and they were planning to set up their own small textiles business. Shaheen told me, “our first few days were very exciting. New way of life to be discovered, new places to visit, adjustments to be made. Everything was fascinating. All the family was happy. The first month that we arrived here we took a furnished apartment in the heart of downtown and that was very exciting. To add to that there was the Shriner’s parade which was thoroughly enjoyable as my children (aged 10, 7, 6 & 1) were totally engrossed in the characters that they saw dancing on the street below our apartment.” She told me the reception she and her family received was very friendly and comfortable until they tried finding a permanent home. “As we had four young children, no one wanted to rent to us. They were afraid that four children would destroy their place. They would not believe us that our children were not destructive. So that was a harrowing experience. Finally, we got a place with great difficulty as they did not ask us about the children and we did not let them know.” Saadia, Shaheen’s daughter, remarked “I had no idea it was that hard to find a place! When we moved to the West Island, where I grew up, I remember the community to be very friendly. It was a great neighbourhood to grow up in. I had a lot of childhood friends.”'

  paragraph3 = 'If they had immigrated now, they may have not had as much difficulty securing a place, but Shaheen believes the overall reception of their family would be less friendly. She expanded saying, “a lot of misconceptions have been going around about immigrants and people who do not look like the mainstream, white Canadians. People have been misinformed about immigrants and an atmosphere of FEAR has been created around the word “immigrant.”’ Saadia added, “I absolutely think it would be different now. Not only because of the misinformation and misconceptions around Muslims and immigrants in general, but because as a society, we have become more disconnected with our community and neighbours. There is more judgment and more fear. People don’t say “hello” on the streets. My mom and I are “hello sayers” and it’s like we catch people off guard. It’s odd to me, the disconnect from humanity we as humans have become comfortable with.”'

  paragraph4 = "I asked Shaheen whether she felt a part of a community in those early days and she told me, “when you arrive as an immigrant, you are more focused at trying to adjust to your new environment [rather] than finding your own community. At least that was my concept, that the mainstream Canadians were “my community.” I did not see a difference... I had a very good relationship with my neighbours who were French, English, Jewish, and Black Canadians. I was very much at home here among my newly found community… their children used to be always at our house as they enjoyed playing with my children…my children went to Catholic School and I helped them in their Bible related homework. I was asked at their school if it was okay for them to study the Bible and I had said, “yes, it’s good for them to study religion as I believed ALL religions teach goodness.”"

  paragraph5 = "Now all Shaheen’s children have grown and she is proud of all that they have accomplished. “They work hard for an honest living. They excel in their own fields. I feel accomplished when I see them achieve their goals.” Saadia has achieved some pretty inspiring goals. She used to play on the Montreal's Senior women's tackle football team called “Montreal Blitz” as their quarterback. She also played quarterback for the Canadian National Women's Tackle Football team in 2010 and 2013. She has since been on the coaching staff of these two teams, is currently a flag football coach, and high school math teacher. Her hope is to inspire more young girls to play and stick with sports. She told me, “sports is such a great way to empower oneself, whether it be in individual sports or team sports. The young girls don't have to play to be professional, they can play just to play, learn, grow, and maybe compete.” Despite all her many achievements she told me her proudest accomplishment is “any time I have done anything that has made my parents proud. I don’t always succeed, but on a daily basis I do try to be a daughter they can be proud of.” When I asked her what she’s most proud of in regards to her family she told me, “I am incredibly proud of my parents for immigrating here. Leaving all they know behind [when] they came to Canada. I witnessed my father work unbelievably hard and battle prejudices to provide for his family while my mom worked just as hard at home to raise the kids. I don’t know how they did it. I always felt safe and secure because of them. My father finally opened his own company in which he was very successful. My mom eventually became the manager of this company. When they retired, my mom continued to volunteer in the community and has been a part of the Canadian Council of Muslim Women (CCMW) for several years now. She is active in the community as part of many organizations and I would consider her an activist.”"

  paragraph6 = 'Shaheen has made a huge impact through her work and through volunteering within her community. She and her husband established a successful service organization in the Marine Industry. I met her through her involvement with CCMW. She also spends a lot of her time volunteering for other organizations and recently the accumulation of her lifetime of efforts led her to receive a community award from the Government of Canada.'

  showPart2Label = {false}

  paragraph7 = 'Right now, activism is a main priority for her. With Quebec’s religious symbols law (formerly Bill 21) having passed, Shaheen was out on the streets protesting against it. She told me, “in Quebec lately (since 2013), I have had this feeling of ‘belonging’ being negated and my rights infringed upon when the Premier (at that time was pushing for a ‘Values Charter’) and the present Premier made Bill-21 where no public servant can wear a religious “symbol.” Where my right to dress was infringed upon. Where my wardrobe and the way I dress limited my choices of careers.” Saadia added to the discussion on belonging and both these laws, saying, “I feel at home in Montreal, in Canada. I felt at home in Quebec, until news of the Charte de Valeur that the PQ was planning back when Mme. Marois was the leader. M. Legault saw this Charter to its fruition in the CAQ’s own way in Loi 21. Since the Charte de Valeur, I felt like a stranger in my own town. For some time I felt like I didn’t belong. The Provincial Liberal party won the election that year, but the damage was done. Since then, I feel that immigrants are scrutinized and feared. The fight over language took a back burner. It was now a fight against immigrants.”'

  paragraph8 = 'I asked them both about how they think women’s voices play a part of moving towards a better future. Saadia told me, “When we focus on strong, positive women and their stories we give license to women to be empowered. Individuals need to hear how women are at the centre of so much - in both leading and supporting roles. By telling those stories and listening to them we give ourselves the airspace and value and recognition that we deserve to have. We give ourselves permission to be more than what oppressive and sexist policy and history has taught us.” Shaheen added, “this part of humanity has always been ignored by every culture for centuries. It is time to give them their God given rights.”'

  paragraph9 = 'Shaheen told me she hopes the next generation will “continue to be fair. My hope is that they will fight for their rights to be equal to all citizens.” Saadia added, “Though we have progressed over the years, I fear we have taken a few steps back in terms of inclusion of immigrants especially Muslims. I hope for the next generation that society will continue to be more open and inclusive.”'

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''
  
  />
</>
)
}
export default Ashraf
