// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import jahangeer_1 from '../familyPictures/jahangeer_1.jpg'
import jahangeer_2 from '../familyPictures/jahangeer_2.jpg'


const Jahangeer = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Jahangeer Family'
  img1 = {jahangeer_1}
  caption1 = 'Left to Right: Hafsa Jahangeer, Roshan Arah Jahangeer'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 ='"Women’s stories are frequently passed over in history books because they were not seen as public figures; we know that Canada did not even consider women “persons” until the Person’s Case of 1929. So, it’s important to highlight women’s stories to show that just as women themselves are intersectional, so are their histories. Therefore, you have to consider different factors such as race, class, sexuality, religion, etc., to really get at the diversity of their experiences."'

  quoteBy = "-Roshan"



  paragraph2 = "Hafsa, Ounesha and Roshan immigrated to Canada from Mauritius in 1987. Hafsa and her husband decided to move with their, then, two young girls in order to provide them better opportunities in work and education. When I asked them what their earliest memories were when they first arrived, Ounesha remarked that “it was hot…I didn’t expect the summer to be that hot.” She had convinced herself and her younger sister Roshan that it was cold in Canada all year round. Roshan added, “my sister had told me there was snow and native people, but I didn’t see any of that. I only saw other Mauritians (our relatives) for the first while, so I didn’t know what was going on.” One thing that surprised them about the Muslim community in those early days were how “centered around Indo-Pakistani culture and religious practices” it was, which was something that differed greatly from the multicultural and pluri-religious scene of Mauritius. Roshan said, “people celebrated each other’s holidays. For example, we celebrated Christmas and Chinese New Year and wished our Hindu friends happy Diwali back home.” When I asked Ounesha and Roshan what an interesting story from their early days is, both remarked on their impressions of school. Ounesha said: “It was clean, it was inside, it was well lit, it was bright and colourful, and you got to learn and play – even in grade 5! You had books and toys in the classroom. In Mauritius, we went to an old Catholic school where you had to buy everything yourself. The books were very plain and school was not meant to be fun. It only focused on academics.” Roshan added, “school was actually fun and my teacher was really nice. They didn’t hit you with a ruler when you did something wrong – I think that was what I liked the most about school in Canada.”"

  paragraph3 = 'Those impressions of school may have influenced Roshan’s lifelong love of learning. As a PhD candidate, Roshan is leading important scholarly research on veiling bans in France and Quebec and on related issues of secularism, feminism, and Islamophobia. Hafsa, Ounesha and Roshan have all worked or still do work for the Ontario Provincial Government. Hafsa said, “We feel like we were in touch with the problems of the less fortunate people of this country, and it made us feel like we achieved something to help humanity.” All their family are deeply interconnected with their communities, whether it be Muslim communities, or Toronto and Ontario communities. Roshan said, “I think that although we came as immigrants, we brought something of ourselves to this country that contributed to it in a positive way – that is, our values, our experiences, our knowledge, our culture, our religion, etc. We didn’t come to just fulfil some labour quota. We changed and grew while we interacted with this environment, but we also gave back and contributed in significant ways and that caused the people of this country to grow and learn about themselves through us as well.”'

  paragraph4 = 'This positivity and give and take relationship is not always fluid. Since Roshan studies the impacts of Islamophobia in her research, I asked what her relationship with belonging is and if it’s changed. She told me, “Before I left Toronto for university in a different Canadian city, I didn’t question my belonging or the fact that I was Canadian. After leaving Toronto and experiencing life outside of that bubble, I realized that other Canadians probably don’t see me as Canadian, so that changed my conception of ‘belonging’. But I also think that there is no one way of being Canadian either, so those who have some fixed idea of that identity are the ones that are going to have to change in the long run... I also think that there are moments where both the State and the media make you question your belonging to this country by making you feel like an outsider: post 9/11, the Quebec mosque shootings, the various proposals to ban “religious signs”. All of these things make you question what other Canadians think when they look at you: do they consider you Canadian? And what does that mean for your own sense of belonging? So those kinds of things make me question whether my place here is as secure as I would like to believe.”'

  showPart2Label = {false}

  paragraph5 = 'Islamophobia impacts personal belonging as well as how communities deal with non-Muslims and each other. Roshan said a big issue that needs to be addressed within Muslim communities is how Islamophobia “creates a siege mentality within Muslim communities, which constantly forces us to try to defend our communities against hate and racism, while not being able to work to dismantle patriarchal structures within. And those patriarchal structures remain in place while masquerading as religious authority.”'
  paragraph6 = ''
    paragraph7 = ''
  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {jahangeer_2}

  caption2 = 'Left to Right: Hafsa Jahangeer, Ounesha Jahangeer'
  extraParagraph = ''

  />
</>
)
}
export default Jahangeer
