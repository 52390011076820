// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import khan_1 from '../familyPictures/khan_1.jpg'
import khan_2 from '../familyPictures/khan_2.jpg'


const Khan = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Khan Family'
  img1 = {khan_1}
  caption1 = 'Fehmida Khan'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = "“I see my parents as pioneers, in their business community in Montreal, as drivers of social change, as establishers of a network for women, for Muslims. They were driven to activism, they were driven to establish themselves in every way possible to build for everyone around them, and especially for those that arrived after them.”"

  quoteBy = '-Azra'

  paragraph2 = 'Fehmida and her husband, Aftab, immigrated to Montreal in the late 1960’s with their 4-month-old son and their 5-year-old daughter. Azra, who is pictured, was born later in Montreal. Aftab went to Montreal first in 1967 and Fehmida joined him not long after. Their immigration story is deeply connected to Expo 67, which was the most successful World Fair of the 20th century with 62 national pavilions and a record breaking 569,500 visitor attendance in its first couple days. Aftab, who came to Montreal to go into the textiles business, immediately participated in the Expo within the Pakistan Pavilion. After the Expo officially ended, a collection of international pavilions became permanent under the title “Man and His World.” After joining her husband who had already established a business and a home, Fehmida told me the first few days were “spent in the family reuniting and getting to know our business partners and work environment.” She was put in charge of the Pakistan Pavilion within “Man and His World”. This experience led her to curate exhibitions for the city of Montreal for eight years afterwards, which in turn put her on the committees and boards of many cultural and arts organizations such as the Musee de Beaux Arts. The Expo also gave her husband access to opportunities which greatly impacted his career. After the Mayor of Montreal met him at the Pakistan pavilion, he invited Aftab to open a restaurant within the city. Aftab took the mayor up on his offer and opened the second South Asian restaurant in the city which remained open for 20 years.'

  paragraph3 = 'When I asked Fehmida what the reception of her family was like in those early days she told me, “In the late 50’s, Canada’s immigration laws opened up to immigrants internationally. Montreal was the largest city and point of entry. The city was booming economically. Both Canada and Montreal were being exposed to various cultures apart from Anglophone and Francophone. At that time, it seemed that Canadians were discovering the world and the immigrants were quelling their curiosity…One of the most frustrating comments [I received] would be that people were surprised we could speak English “so well.” We were educated under British colonial rule, of course!” She continued to tell me, “When I went to register Azra at the local public school, I went as usual, in my sari. I was told I could not register her, that her father would have to come during school hours. The practice to undermine minority women was at full force in those days.”'

  paragraph4 = "In spite of the undermining of minority women, Fehmida was determined to help build community for Muslims and South Asians from the get go. She told me, “There were not many Muslim families we knew at the time. My first community connection was with YWCA, but visible minority women didn’t have much of a voice then. My community work was forged with the South Asian Women’s Community Center, and the only Mosque at that time, Islamic Community of Quebec in Ville St Laurent.” That mosque they were originally a part of was a very small warehouse where the city’s entire Muslim community would join. Fehmida started volunteering with other women at the mosque with a telephone helpline. This volunteer work led her to get involved in the Canadian Council of Muslim Women (CCMW) when it was founded where she became the Montreal chapter’s president and then continued to be a member after her terms concluded. A moment that stands out from her presidency is when she spoke out against a 1998 controversy where she won the right for a young Muslim girl to wear the hijab at school, when a Montreal French school wanted to ban her from wearing it."

  paragraph5 = 'Fehmida has also been actively involved in the South Asian community as a founding member of the South Asian Women’s Community Center (SAWCC.) Their mission being to work with and empower immigrant women to reach their goals whether through her personal connections or connecting them with other communities, and to empower them to raise their voices to be heard. In both her work in the South Asian and Muslim community she has always been focused on defending the rights of women and their families. Azra commented that her parent’s deep involvement in different communities led them to be always connected with other Indian and Pakistani Muslim families. “We always had big Eid celebrations, and during Ramadan it was my job to have the chaat prepared! We also had cultural music evenings in people’s houses – this was an amazing part of our lives, poets musicians etc used to perform, and we kids used to listen a bit, and then run around to play.”'

  paragraph6 = 'When I asked Fehmida what she is most proud of, she told me, “My husband and I value the cohesive environment created within our family, with our core values of integrity, honesty and fair play intact.” Azra added her perspective, saying, “Our education and careers were always encouraged and supported by our parents. My sister, brother and I pursued our interests without limitations. We chose our education, our careers, and where we wanted to live. Our parents worked hard, whether in their own businesses, or for social justice for our communities and minority communities in Quebec. They modelled dedication, commitment, and unwavering support.” Now, Azra is a big advocate for the role of education in society. She is a teacher, researcher, and program officer in educational institutions and international organizations. Her interests lie in the intersection of education and social justice within cities.'

  showPart2Label = {false}

  paragraph7 = 'When I asked Fehmida about how she would feel being included in a Canadian history book she told me, “When one considers inclusion in Canadian history books – the picture of the ‘other’ (meaning other than white British descent, or French descent) was degrading, when my children were in school. I suppose, my family could make it into Canadian history as Muslim Community Builders.” Having grown up seeing those degrading representations her mother speaks of, I asked Azra what her relationship with belonging is. She told me, ““I belong, but I had to find that place – we were certainly different in the early years, in our neighbourhood! I know being different is good now, it was soul food actually, but it wasn’t easy sometimes! The best was that Mum insisted we go to every Canada Day parade and celebration, and we had to have that giant cake too! She was ensuring we were present and belonged!”'

  paragraph8 = 'When I asked them what the biggest issue within their communities that they wish they could change was, Fehmida told me, “Within the culture/religion/community, we could be working towards acceptance of inter-generational views. We absolutely need to make changes and work towards ending systemic racism.” Azra added, “We are now even more accepting that there are various ways to do life. I feel like we could continue in that trend, but mum is right, anti-black racism is something we all have to work to eradicate.”'

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {khan_2}

  caption2 = 'Left to Right: Azra Khan, Fehmida Khan'
  extraParagraph = ''

  />
</>
)
}
export default Khan
