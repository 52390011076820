// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import hamdon_1 from '../familyPictures/hamdon_1.jpg'
import hamdon_2 from '../familyPictures/hamdon_2.jpg'


const Hamdon = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Hamdon Family'
  img1 = {hamdon_1}
  caption1 = 'Left to Right: Evelyn Hamdon, Lorrine Hamdon, Faye Hamdon, Susan Carlson'

  subCaption1 = 'Pictured in Frame: Hilwie Hamdon'

  showPart1Label = {true}

  paragraph1 = '“[In the 1930’s] the few Muslim families that were in Edmonton gathered together often. They decided they wanted a place to congregate so they were going to build a community hall, but then my mother-in-law, Hilwie Hamdon said, ‘if we can build a community hall, we can build a mosque.’ ”'

  paragraph2 = 'If someone else had said it, that statement may have not led to the first mosque in Canada being built in 1938. But Hilwie Hamdon was someone you listened to, after all, couples from across the community used to come to her throughout her life for mediation and conflict resolution. Her name carried influence far and wide, just like how her joyful laughter traveled from one room to another, just like how her unconditional love for her children stayed with them wherever they were, and especially how her dedication to her faith has and will affect generations to come.'

  paragraph3 = 'Not long after that statement was spoken, Hilwie Hamdon and a delegation of others went to Edmonton’s mayor, John Fry, to ask for a plot of land to build on. He agreed on the condition that they would need to raise the minimum amount of money needed to build on the land. The group hired a Ukrainian church builder for the task and then set out to raise the funds. Hilwie, her husband, and a couple other individuals fit into a car and went all over Alberta and Saskatchewan raising money. Faye (Hilwie’s daughter-in-law) notes “local merchants were especially helpful and they were people of all different religions.”'

  paragraph4 = "In 1938, the Al-Rashid Mosque opened its doors. This opening sent waves throughout the Canadian provinces and attracted many Muslim families to Edmonton. Faye’s family, the Shaben's, were one of them. Her family was one of the other first Muslim pioneering families on the prairies. Like the Hamdon’s, her grandfather, Salim Shaben, or “Big Sam” Shaben, came to Canada because of the promise of free land and economic prosperity. Like two million others between 1896 and 1914, Salim was a beneficiary of the Canadian government’s settling the West project, and found himself in Endiang, Alberta. He was very entrepreneurial, setting up a pool hall and a grocery store to begin with. To this date, commemorative plaques are on all the buildings he was involved in. His son and Faye’s father, Albert Shaben, along with his wife Lila Kazel, also became influential members of the Endiang community due to their deep involvement in community and in politics."

  paragraph5 = 'Faye remarked that, “I always felt completely where I belonged. Maybe because we started in a small town at first [where] you’re one big community.” She continued to share this story, “My mom told me, according to Scotland legend, if a dark-haired man is the first person to cross your threshold at midnight of the new year, you’ll have good luck. My dad had to go to every Scotch house, and go house to house, so he’d be the first dark haired man to go to their house and they’d have good luck.” Even when the Shaben family moved to Edmonton in the 40’s, Faye felt completely comfortable within the school system and community in general. The Muslim community was also around 100 people strong at that time, so she noted that they did everything together. Faye said, “we were here at a good time, a really good time.”'

  paragraph6 = 'When I asked Evelyn, Faye’s daughter, on what she believes the main changes are between each generation she told me, “Because [the first generation] were a part of that settlement project, they were automatically granted special status, in many cases at the expense of Indigenous people. The difference between that first wave of immigrants and subsequent waves and generations, is that international political events engendered increased hostility towards Middle Easterners. For example, the situation [The Israel-Palestine conflict] in the Middle East had accelerated and Arabs were viewed as a problem. Sadly, international affairs has affected how Arabs and Muslims are viewed in the west. Muslims in particular are viewed as being impossible to assimilate, because we have been produced as essentially alien to western civilization.”'

  showPart2Label = {true}

  paragraph7 = 'Evelyn, Susan, and Lorrine all grew up in Edmonton where they all still live. Upon reflecting on their upbringing, they all noted they had different experiences in regards to their identity. Susan noted that she never felt she was treated differently, but that she recalls “at the beginning of school in elementary school, they would start out by calling your name and your religion. Nobody had ever heard the word Muslim, so it was almost like you were an alien at that point.”'

  paragraph8 = 'Evelyn remarked that she did feel different due to an experience of racism. She brought up one of her earliest memories which was in grade one. “One day after school I came out of class, and all the kids in my class were waiting for me. Then they surrounded me and started chanting the N-word, which I had never heard but I had a sense they used it to hurt me … certainly from an early age [I became] conscious that I was not white, and there was something about me that marked me as “other”. It set me up to be conscious of something.” As a now academic who completed her PhD dissertation on Muslim representation she remarks, “if you read people like W. E. B. Du Bois or [James] Baldwin, people who talk about race consciousness, that is becoming conscious that you are marked as different. It’s a young age to have that perspective, but I think on the other hand it led me to the work I do now.”'

  paragraph9 = 'When Evelyn was older, she found herself questioning whether or not she did belong in Canada. She told me, “The reason I went to Lebanon in ’76, was because I felt I didn’t belong in Canada. It was the time of the Roots phenomenon, so I went there to find my roots, except everybody there constantly asked me “where are you from?” and I remember asking myself “why don’t I fit in anywhere!” Eventually I had to come to terms with the fact that, “yeah I really am Canadian.” But I also struggle with the fact that we’re settlers/I’m a settler…we were welcomed here initially as part of the settlement project which was a very political and oppressive project, to displace and colonize indigenous people. So maybe a person should never be too comfortable with who they are. I’m okay being uncomfortable.”'

  paragraph10 = 'In thinking back to her childhood as well, Lorrine added, “in spite of the challenges of being different, I do remember being part of our very tight-knit community with such fondness. I remember the little mosque with such fondness, Sunday prayers with such fondness, Eid prayers with so much love, sitting next to siti (grandmother) Hilwie at the mosque, being part of that community, and I have always felt really sad that our children were not really part of a community like that…the way Arab and Muslims were seen in the world made it harder on those kids to want to have that kind of connection, cause when we were growing up nobody thought we were terrorists when we were having Eid dinner. I think it was a lot harder for that next generation… my hope for the next generation is that they find their way back in some way being part of that identity and community.”'

  showPart3Label = {true}

  paragraph11 = 'Just like how it was a woman’s voice who instigated the building of the Al-Rashid mosque, it was women’s voices that demanded it be preserved, when the community outgrew it after 44 years. After the building of the much bigger mosque in 1982, the original was planned to be demolished. When the Edmonton chapter of the Canadian Council of Muslim Women (CCMW) was formed around the same time, they decided that saving the mosque from demolition and preserving it as a historical building would be their first project. Evelyn who was a part of CCMW and was instrumental in the project said, “first, we had to lobby city council to permit the moving of the mosque to Fort Edmonton Park which received a lot of resistance. Then there was a multiyear fundraising effort. It was kind of like a parallel to how they built the mosque in stages. You do a bit of fundraising, you do a bit of building, but in our case it was, you do a bit of fundraising... you do a bit of demolition, you raise… you move it, you raise… you build it.”'


  img2 = {hamdon_2}

  caption2 = 'Pictured in Frame: Hilwie Hamdon'

  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Hamdon
