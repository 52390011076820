// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import habib_1 from '../familyPictures/habib_1.jpg'
import habib_2 from '../familyPictures/habib_2.jpg'


const Habib = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Habib Family'
  img1 = {habib_1}
  caption1 = ''

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“It is my duty to give back to Canada by contributing my time and skills to help build my community and respect its values… [but] there are people who still question my loyalties based on my appearance.”'

  paragraph2 = "Mahlaqa Naushaba and her husband Naiyer immigrated to Saskatoon in 1973 from India. They had a long journey of deciding where to settle before arriving in Canada. Naushaba shared the story of how choosing a place to live was complex due to her being Pakistani and her husband being Indian. “We were married in 1966. It was right after the war between India and Pakistan in 1965. The relationship between the two countries was not good at that time. It is now worse. Our marriage was arranged in childhood. The first objective was to get married and the question of settling was to be decided. I could not go to India being Pakistani and my husband could not settle in Pakistan being Indian. These were complex decisions to be made.” Naushaba had just completed her Master of Arts degree in Political Science while her husband had completed the degree of Doctorate of Medicine in India. They decided to first go to the United States so that Naiyer could complete further specialization, ultimately becoming a cardiologist. Their first son was born during that time in Durham, Carolina. They decided to move back to Pakistan so that they could be with their extended family, Naiyer had to change his citizenship in order to do so. When they got back, Naushaba told me they realized, “we could not accept the public life in Pakistan which was not different from India. Unfortunately, we could not settle in Pakistan as we could not compromise on matters of principles. We migrated to Canada for these reasonings. It was not a monetary reason for us to move to Canada, which we chose to be a better country for social and political life. It was the time of Pierre Trudeau. It was a golden period for immigrants. Canada embraced us as a new motherland as its son and daughter. We served our country as its loyal citizens.”"

  paragraph3 = "When they arrived in Saskatoon, Naushaba shared, “we felt relief to achieve our goal to settle at a place which was desirable for us. However, we missed our family but anticipated that we will bring our parents to Canada in due course. Everyone was loving, respectful, and helping here. We did not find any bias against us.” She commented that she’s noticed things have changed significantly towards newer immigrants. “We are here for the last 47 years. There is significant bias against the Muslims and Islam. So is the bias against immigrants by a section of the minority of white population. It seems to be an ongoing trend. Some politicians are responsible for the spread of Islamophobia. We believe that each nation, each religious group or community and each race has two kinds of people. A group full of biases, hate and jealousy against those who are not their own people. On the other hand, another class or group who are tolerant, respectful, just and try to eliminate these kinds of biases. We do not see politicians, but a few walking on those paths.”"

  paragraph4 = "They moved from Saskatoon to Regina when Naiyer was appointed to initiate and establish the cardiology program for Southern Saskatchewan at the newly established teaching hospital. He remained head of cardiology from 1976 to 2004. Naiyer established the program from scratch, while also teaching, and building an academic career. Naushaba supported her husband every step of the way, managing his medical office, academic career, and standing by him during all the career’s ups and downs. Naushaba and Naiyer were quick to engage in community building once they settled down. They shared that at the beginning they were one of few Muslim families in the area. They shared that they helped grow the “Muslim community in Regina by establishing an Islamic school, participating in community programs, and establishing an Islamic Center.” They shared, “we provided leadership in the Muslim Community starting with eleven families, leading on to a well-established Islamic center and mosque on Montague Avenue in Regina. From holding our gathering in our houses on rotation to the established Islamic Centre, we provided political, social and religious leadership.” They cared deeply about passing on religious and cultural values to the next generation of children. Naushaba started an Islamic school in her own home that began with 9 students and eventually grew to 30 students before being moved to the mosque. “We initiated teaching Islam to the children. We could not find Islamic literature to teach our children here, then. We involved all children in community activities being important factors in raising them.”"

  paragraph5 = "As the community grew and the political climate changed, so did Naushaba and Naiyer’s focus with their community involvement. In 1981, Naushaba became the founding president of the Regina chapter of the Canadian Council of Muslim Women (CCMW), which also initially started in her own home. She represented both CCMW and Muslim women at various women’s organizations across the city promoting interfaith conversations, spearheaded support for Bosnian women during the Bosnian massacre, and initiated a scholarship fund for Muslim girls at the University of Regina. After 9/11, they decided to establish another organization called, Muslims for Peace and Justice, which aimed to inform media about Islamic perspectives on recent issues, create interfaith understanding and relations, provide lawful help for Muslims who are falsely accused by government agencies, bridge understanding regarding Muslim world affairs, and reinforce how Muslims are a part of the Canadian mosaic."

  paragraph6 = "After retirement, Naushaba and her husband decided to move to Abbotsford, BC to be closer with their son. In 2015, they wrote a book titled, “History of the Muslims of Regina, Saskatchewan, and Their Organizations.” The 600+ page book goes into large detail chronicling their history and contributions in Regina, while also chronicling the collective history of the communities they were a part and surrounded by. This short description of their journey in Canada does not scratch the surface of what these two individuals have done in the almost 50 years they have been in Canada. When I asked Naushaba what she is most proud of she mentions her and her husband’s community work, how they feel pride being instrumental in the development of Saskatchewan’s Muslim community, but also, how they raised two well-educated young men. Naushaba shared, “what we are thankful to God for is that both of our sons are successful in their careers, where one is a lawyer in British Columbia and our youngest one is a kidney specialist in the United State. Most importantly, they are devoted family men. We did not lose sight of this from their childhood.” Naushaba and her husband are also grandparents now and when asked what her hope for the next generation is, she told me, “I hope that the next generation does not have to face discrimination or be questioned about their loyalties to the country. That they are able to achieve their dreams and continue to contribute towards the better future of the country while maintaining our culture and religion. [That they] integrate with the society at large [and] they pass this on to their descendants.”"

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {habib_2}

  caption2 = ''

  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Habib
