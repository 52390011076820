// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import youssef_1 from '../familyPictures/youssef_1.jpg'
import youssef_2 from '../familyPictures/youssef_2.jpg'
import youssef_3 from '../familyPictures/youssef_3.jpg'


const Youssef = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Youssef Family'
  img1 = {youssef_3}
  caption1 = 'Left to Right: Allison Barton Youssef, Rayhanah Youssef, Asalah Youssef'

  subCaption1 = ''

  showPart1Label = {true}
  quoteBy = ''

  paragraph1 = 'My mother Allison, and father, Ashraf, immigrated to Vancouver in 2004 from Cairo, Egypt with my sisters and I: I was eight years old, my sister Rayhanah was three, and my youngest sister Asalah was one. Even though Rayhanah and I were born in England, all three of us had spent our lives in Cairo before our move. My mother, Allison, met my Dad on a short vacation to Cairo from her hometown of Essex, England to visit her best friend in 1988, and as the story goes, it was love at first sight. She moved to Cairo where my Mom and Dad began a few businesses together, eventually finding success with a party and event decoration company and a store which was the first to import balloons and party supplies to Egypt. It is still open today 26 years later. I remember not long before we moved, my parents sat me on my bed and asked me what my thoughts on moving to Canada were. I don’t remember thinking much of it, but being happy to go to this place I didn’t know much of. I didn’t realize then, or until much later, that I was already Canadian.'

  paragraph2 = 'My maternal grandparents arrived in Ontario by ship in 1951 from London, England. My mom told me, “I believe there was a large marketing effort from Canada to get UK residents to immigrate offering free or low-cost tickets on the ship. I suspect they also wanted to start anew after the war had ended and things were bleak there.” They only stayed in Canada for six years but during that time lived in Port Colborne and Toronto and my Grandfather obtained his Canadian citizenship. He worked for some time at Eaton’s, which around the time was Canada’s largest department store, and at some point, both my grandparents were live-in workers for a wealthy family in Toronto. With my uncle falling ill they returned to England to have a support system and stayed in England for the rest of their lives. My Mom, their last child, was born ten years after they moved back from Canada. Hearing stories of Canada throughout her childhood and youth, her curiosity and desire to go was ignited.'

  paragraph3 = 'After twelve years living in Egypt, my Mom decided to consider this dream of hers to go to Canada. She told me, “even though my parents and their Canadian dream was short-lived it led to our dream becoming reality… I am forever indebted to my parents for making the bold move to immigrate in the 50’s. It is because of my Father working hard and receiving Canadian citizenship that we have the life we live today. We owe everything to him.” She told me how much she also appreciates how my Dad supported “his wife in every crazy suggestion she threw at him even when it meant leaving family, friends, and a business.” Asalah, my youngest sister, told me how much she appreciates my Mom for the, “bold move to come to an unknown place because of the felt connection. We moved to Canada because my Mother had a feeling in her heart of how much she knew she would love it. We came, knew no one, went through difficulty and challenges, but eventually found our place. I cannot speak on the experience of immigrating as I was too young but I can only imagine how difficult that was for my Mother… moving across the world with three young daughters.”'

  paragraph4 = "I asked my Mom what her memory of our first few days was, she told me, “my first few days in Canada were dreamlike. I moved here from Cairo, Egypt. A massive, over populated, polluted city and landed in North Vancouver, British Columbia. My first full day in Canada was actually July 1st - Canada Day. My family and I ate a pancake breakfast and went to a parade. Everybody was in celebratory spirits and smiling at us. Everywhere was beautiful. I felt excited and happy but looking back I am sure those feelings were masking some fear and trepidation.” I followed up by asking her if an interesting memory or story of that time stood out. She shared with me a story I remember clearly as well: “On our first day in Canada it was Canada Day. We walked miles across North Vancouver following the crowds that were making their way to Canada Place. We crossed the Burrard Inlet on the Seabus and continued following the crowds up onto the deck of Canada Place where there were activities taking place. There were so many people there, thousands of children and parents having fun. My youngest daughter who was only 16 months old spotted a little boy in the crowds and ran up to him. Strangely enough he held his arms out for her and they embraced, not wanting to let go of each other. They stayed like that for what seemed like ages as we all smiled and laughed. They were an Asian family. Reflecting back on that encounter I see how symbolic it was for us in this country of inclusion and multiculturalism that that would happen on our first day here.”"

  paragraph5 = 'I remember that Canada Day clearly as well, soaking up all this new city had to offer us. I remember aching feet from so much walking, but also a real sense of positivity and optimism. A couple months later I began Grade 4. My first memory of going to school was how everyone asked me if I had lived in the pyramids or in a desert hut when finding out that I moved from Egypt. I remember being asked so many times, my answers became robotic. I remember being really surprised by this general misconception that all my classmates had. I don’t really remember that much else from my elementary school days, but I do remember how the monthly “pizza day” became another question period with my classmates where I had to explain why I couldn’t eat pepperoni for religious reasons. Looking back, I wonder if these instances happened as much as I remember, or if a few instances really stood out to me for how different they made me feel in those pre-teen years. I don’t remember it making me feel like I didn’t belong, it just made me hyper aware of how my classmates lack of knowledge and understanding about aspects of my identity made me feel “othered”. I know now that my parents weren’t fully committed to staying when they moved (there were many difficult times they were tempted to return to Egypt.) I remember being fully committed to staying. I remember the joy of my first Halloween, the first snowfall of the winter season being on my birthday, the gingerbread men from the local bakery at Christmas time, and the quick obsession I had with Archie comics. I was so quick to embrace living in Canada, to the point that I remember telling myself that it was okay to forget Arabic because I wouldn’t need it anymore (and unfortunately, I really did end up forgetting it.) I remember telling my parents I was happy to stay when they asked if I was really sure I was happy here.”'

  paragraph6 = 'When I asked my mom what she’s most proud of in regards to our family she told me, “I am proud of myself for taking bold decisions about moving my family here from Egypt. I was fearless and so strong. I spent a lot of the early years here parenting alone and being lonely but I remained resilient. I am proud of my children for the incredible things they have achieved and how they conduct their lives as Canadian citizens and the way they give back to their communities. I am proud of my eldest daughter for being so brave when we first arrived and taking everything moving to a new country, knowing no one and starting a new school throws at you. She not only survived it, she thrived at it.”'

  extraParagraph = 'Reading my mother words, I feel I don’t deserve any of those labels of being brave or surviving anything. I feel incredibly privileged for how I grew up, and I was only able to take everything in strides because my parents safeguarded me from feeling the extent of their struggles and supporting every move I made. I’m extremely proud of them for making the bold move to leave so much behind: a huge support system of extended family, a newly built apartment, a successful business. They left it behind so my sisters and I could thrive as women in a society where we had equal access to education, where we could pursue whatever dream we had, where our chances of facing misogyny in our day to day lives were less. They moved for a better life with gender equality in mind. I am extremely proud of my mother who did have to take on the gendered double burden of caretaking, managing our lives, dealing with the struggles of settling in a new country, as well as co-managing my parent’s business from afar, when my dad (bless him) had to go back and forth between Egypt and Canada to run their business. This double burden which she still takes on now, as my father still spends half the year in Egypt. I’m proud of my mother for raising us to feel we have no limitations, even when she faced obstacles on a daily basis. I’m proud of her for raising us to feel the support of a village, when truly we had no one. I’m proud of her choosing to also focus on her own growth and for instilling a mentality of abundance within us. I’m endlessly proud of both my parents, I don’t know if I have half the bravery they have, and because of them, I know I don’t need to be as brave either.'

  showPart2Label = {true}

  paragraph7 = 'While speaking with my Mom about our relationships with belonging she told me, “my relationship with belonging has changed over time because it is time that has brought me to this comfortable place I now inhabit. Time has grown familiarity of land, of people, of buildings, of environment. Each one of those now holds the word, ‘home’.” My Mom’s relationship to belonging was also impacted by when, three years ago, we took a road trip to Port Colborne to attempt to learn more about my grandparent’s lives there in the 1950s. When we went to Port Colborne and visited the local archives, we found their names in a directory. My Mom shared, “seeing my parent’s names in archived books in Ontario was an incredibly moving experience. I had heard the stories and seen the photographs but holding the book in my hand that included their names and addresses solidified their existence in that community and gave me a sense of belonging that I had not previously known.”'

  paragraph8 = 'I asked my Mom what she thought of the phrase bad immigrant/good immigrant. She told me, “[it] brings up the issue of race for me. It immediately makes me think of the division between immigrants originating from different countries with different skin colours, different language abilities and the privileges or lack thereof that arises from that.” The experience at the archives really hit home on our own family’s privilege. Even though I witnessed first-hand the difficulty my parents endured immigrating here without a support system and under financial stress, the years my Dad had to westernize his name for job interviews, my error messages while checking in at the airport, and my own experiences with belonging which have been impacted by anti-Muslim sentiment and xenophobia, that our family’s experience is extremely privileged. Since my maternal line is white and British, my sisters and I have benefited from the privilege that it imparts. The privilege that I got to partake in the joy of seeing my ancestors names within an archive, the privilege that the immigration policies of the mid 20th century which favoured British immigrants allowed my family 50 years later to consider moving here with less barriers, the privilege that within my bloodline there is the “true Canadian subject” that the settler-colonial state so desired before being branded as a multicultural nation.'

  paragraph9 = 'I asked both my Mom and sister what their hopes for the next generation are. My mom shared, “that they continue to be caring, engaged citizens. That they use their privilege as Canadians to stand up for those less fortunate in this country and the world. That they always remain politically active and in their own way fight to see women rise.” Asalah continued, “that they question the norm, acknowledge and learn from Canada’s history and act according to their values. I hope they continue to make Canada an inclusive, diverse place full of opportunity for everyone, to be intersectional environmentalists and activists and push to make political places one of equality and balance.”'

  paragraph10 = 'When I asked my mom what she thinks the biggest difference is between our generations she told me, “I think education and opportunity has changed the most between the generation of my mother and my daughters. My mother and I have paved the way for my daughters to take the stage and lead with knowledge that is readily available and with heart that has been nurtured and cultivated.”'

  showPart3Label = {false}

  paragraph11 = 'My mother answered these questions for a project I created to fulfill my master’s degree which, maybe unintentionally, substantiates her thoughts. I always felt that I could pursue any education path I wanted to with little limitation without feeling the pressure of choosing a traditionally high paying or safe career. Since I was 14, my mother has garnered my passion for photography, and now I have the great privilege to be able to go through life thinking about and creating art. Despite whatever challenges I may face on this path, it is a path of freedom and choice which was paved because of the sacrifices and whole-hearted encouragement of my family.'


  img2 = {youssef_2}

  caption2 = 'Left to Right: Rayhanah Youssef, Asalah Youssef'

  img3 = {youssef_1}

  caption3 = 'Left to Right: Allison Barton Youssef, Alia Youssef (Me!)'
  />
</>
)
}
export default Youssef
