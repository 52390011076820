// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import hussain_1 from '../familyPictures/hussain_1.jpg'



const Hussain = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Hussain Family'
  img1 = {hussain_1}
  caption1 = 'Left to Right: Nahla Allawi, Jazmina, Berak Hussain'

  subCaption1 = ''

  showPart1Label = {true}

  paragraph1 = '“I remember vividly in La Ville de Québec… as a 9-10-year-old going to the mall one day, people were looking at us because we outwardly looked like minorities, especially with my mother’s hijab. I remember that young saying, “Oh! We’re the freakshow!” It was interesting to see how we were looked upon and viewed. And even that young, I was aware of us being looked at differently.”'

  paragraph2 = 'Berak, her mother Nahla, and her two siblings and father arrived in Quebec City, Canada in 1987 for her father’s research position at a local university. Her family is originally from Iraq, but since Berak’s birth in the USA, they have traveled and lived around in the UK and Europe, Algeria, Libya, before finally settling in Canada. In the early years they moved around Quebec and Ontario before settling in Ottawa, where they live now. Even though Berak remembers times where they felt different, she recalls a fond memory of her Quebec City neighbours who helped her family feel right at home. “I remember them being very friendly to us and [showing] us the different customs, like Halloween for example, where they took us out with their daughter trick or treating…I remember specifically that the family showed us the ropes with everything, like … maple syrup, skating, very Canadian things… My mother would share our customs with them, during Ramadan and other commemorations throughout the year.”'

  paragraph3 = 'Nahla is referred to as Um Berak, which translates to “mother of Berak,” as is the respectful custom to address someone who is a parent as per Middle Eastern tradition most popular in Iraq, Lebanon, and the Gulf regions when addressing others rather than their given names.Um Berak built an extremely supportive environment while her children grew up. Fostering their faith while they faced external adversity and most of all, doing everything she could to encourage their success in education. Berak is a psychotherapist and her siblings are in the fields of engineering, optometry, and international affairs. Berak mentioned that Um Berak “has these honorary degrees so to speak.” It reminded Berak of a story from her years studying. “I’ll never forget the days and nights where I came home exhausted from labs, assignments, and exams, and I would walk in very tired and my mother would come and feed me at the door.” All her mother’s support, and her hard work, paid off. She is now extremely active bringing mental health awareness to Muslim communities across the world, in person, on social media, and in videos and documentaries she’s participated in. Her passion for changing the silence and status quo around mental health in the Muslim community is making an impact. She said, “We need to continue this open narrative of compassion and love rather than judgement and playing the haram police so to speak.”'

  paragraph4 = "Something Berak is really proud of is the profound and loving support that is given between the women in her family. “We worked through different struggles and experiences with life, but [I’ll always remember] how loving we are and supportive we are of one another. Whether it’s my daughter supporting me with my work, my mother supporting my work, or how I support and help my mother now as she is getting to be an elderly woman and a grandmother, also how I support my daughter and her challenges and her experiences.” I asked how Berak’s 9-year-old daughter, Jazmina, was experiencing school. She told me, “she goes to a full Islamic school so she does not have to experience what we did going to public schools and being discriminated against and experiencing those challenges being visible minorities.” She hopes Jazmina continues to not experience the challenges she did. “I did experience a lot of discrimination and Islamophobic rhetoric towards me growing up, but not as much as other people. I was very blessed in that growth. [I hope] for her not to experience that hate, and to grow in love with [her hijab] and to be able to have the self-confidence to wear it publicly and to follow her dreams with what she wants to do.”"

  paragraph5 = 'Berak is very proud of her daughter for beginning to wear the hijab and for embracing modesty in public. “She really embraces the hijab, having a mother and a grandmother who wear it publicly. [I wear] it in [all] my work [from] my mental health awareness, to the TV and documentary work that I do, and I show that to her. I say, “Here you can wear the hijab, you can be a proud Muslim, and be included in Canadian society.”'

  paragraph6 = ''

  showPart2Label = {true}

  paragraph7 = 'There is no better example to Berak regarding the importance of making space for women’s stories, than the story of the Battle of Karbala which took place over 1300 years ago. Berak told me, “if it wasn’t for the women, Lady Zainab (the granddaughter of the Prophet Muhammed PBUH) the children, and the orphans that were captured after the battle, had it not been for them safeguarding the story of what happened there, we would not know what the image of a Muslim woman in hijab would be today, we would not know the Islam we know today, nor the prayers, the Quran, or anything. It would have been wiped out at the time.” She continued to tell me, “A woman and her power of storytelling, in this case, can preserve a faith, to protect it for us today. Especially as women who wear the hijab. The stories of our mothers, grandmothers, and great grandmothers, whether it’s cultural, religious, spiritual, or just as women protecting, lifting, and supporting each other in times of difficulty in our lives. Whether it’s going through school, whether it’s marriage, whether it’s childbirth, whether it’s raising children, and the list goes on and on. The power of that is incredibly beautiful to be passed down from one generation to the next.”'

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Hussain
