// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import jamal_1 from '../familyPictures/jamal_1.jpg'
import jamal_2 from '../familyPictures/jamal_2.jpg'


const Jamal = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Jamal Family'
  img1 = {jamal_1}
  caption1 = 'Left to Right: Nazira Sunderji, Tazeem Jamal'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“People just simply need to look at humans as humans. Judge them for WHO they are, not what faith or community they come from.”'

  quouteBy = "-Tazeem"

  paragraph2 = 'Nazira, her husband, and her children, including Tazeem, moved to Toronto in 1982 from London, England. Their move was motivated by the fact that many of their extended family had immigrated to Canada, and they were the only ones left in England. They shared that they were lucky to have a huge extended family to welcome them, and even gave them a chance to reconnect with many family members they hadn’t seen in a long time. A month after they moved to Toronto, they decided to move further west to Vancouver in search for milder weather.'

  paragraph3 = 'Their family is very entrepreneurial in spirit and began businesses as soon as they moved to Vancouver. They opened an old fashioned Tobacconist and fine men’s gift shop on Vancouver’s main shopping strip, Robson street. Once Tazeem was done highschool and completed her training as a master esthetician, she continued the entrepreneurial tradition of her family. She shared, “[my parents] always supported my BIG dreams and they co-signed a business loan for me to open my own spa at the age of 21! I could not have done that without them.” She has grown this business for 30 years now, and expanded it by also being a spa business coach, blogger and author, imparting her entrepreneurial and industry knowledge onto others. She’s extremely well-known in her industry, making many media appearances, selling her award winning book, and speaking at conferences. Tazeem shared she is very proud of “the risks we have taken to build our businesses and the challenges we have come through!”'

  paragraph4 = "Nazira has made it a priority to volunteer and give back. She dedicates a lot of her time working with the Aga Khan Foundation, supports people that are in hospice care, and volunteers with an organization that supports children and families with mental health issues and addictions. Tazeem spoke with pride regarding how “in addition to being great entrepreneurs, my parents are very friendly and generous people. They taught us to always be kind and share. They lead by example and donate a lot of their time to volunteering; giving of their time, supporting those that needed it and never liked to be in the spotlight for it. Their humbleness has taught me a lot. I am grateful for their guidance and support.”"

  paragraph5 = 'When I asked Tazeem what she believes are the greatest differences between each generation she told me, “as each generation passes, I think it gets harder for that generation to not take on the identity of the country they are living in. The narrative of society shifts and can become harder to maintain our family and community values. I think we, as the “older” generation need to REALLY impress on our children, the importance of OUR values and keep them close.” Tazeem shared her wish for daughters, Sophia and Mishaal, “is to gain a well-rounded education and great life skills, to be able to give back and pay it forward. We are so blessed to be Canadian and our opportunities for independent lives, to make our own choices is never taken for granted!”'

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {jamal_2}

  caption2 = 'Left to Right: Sophia Pasha-Neesa Jamal, Nazira Sunderji, Mishaal Farzin Jamal'
  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Jamal
