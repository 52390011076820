// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import ahmed_1 from '../familyPictures/ahmed_1.jpg'

const Ahmed = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Ahmed Family'
  img1 = {ahmed_1}
  caption1 = 'Left to Right: Rahma Mohamed, Ilhan Ahmed, Ihsan Ahmed'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“We have endured and we solidified our roots here. Each generation is pouring into the future of the next. Each sacrifice is seen as a building block for the next generation to go further and reach higher.”'

  paragraph2 = "Rahma, her two siblings, and parents arrived in Montreal, Quebec in 1989 when she was a child. As Rahma says, they arrived with “two suitcases and a boatload of dreams.” When they first arrived, they felt “lost and alone. We couldn’t speak the language, we felt the loss of community and mostly our identity…Canada did not have mass immigration like today. There was very few awareness and services. My parents recall all four of us sleeping on a single mattress with one set of bed sheets for the first week. We had a single dish set and pot. We got a few things from social services a few weeks later.” They found themselves in Montreal as that was the nearest border from their previous destination in the US. When I asked Rahma if her family sought out a Muslim community or became a part of one in Montreal, she told me, “the mosque was our first place of refuge. But Quebec has a long history of hostility when it comes to outsiders and mostly Muslims. It doesn't have many mosques like other major cities in Canada. I am saddened to see Bill 21 passing there and knowing that things didn’t get much better but rather worse.” I asked Rahma if thinking back to those early days reminded her of any interesting stories. The one that came to mind is “this funny story my mother likes to tell. During our long flights from Africa to Canada I was fascinated to see white people who I had never seen before. She said I would pull different ladies blond hairs wondering if it was real. She kept having to apologize but I just kept doing it.”"

  paragraph3 = "When I asked Rahma what she’s most proud of she told me, “I was the first to graduate from university from my family. I also mastered both French and English, graduating with honor with a bilingual degree. Subsequently all my siblings attended and graduated from college. We have made reality the dream of our parents to see us succeed. We work in fields my parents never imagined: human resources, accounting, provincial and federal government, physical therapy, and a soon to be nursing. Now my children attend a private Islamic school which wasn't an option for us.” She is delighted when she thinks of how far her family has come in just one generation. She told me, “my parents didn’t speak the language, we have mastered both official languages. They didn't attend school, we have graduated from top universities. My nieces and nephews are now the third generation born here.” Years of perseverance, resilience, and lessons in humility occurred for this family to see their wildest dreams come true. Rahma told me now is a time of peaceful contentment for her parents as they get to see their children and grandchildren access opportunities they never imagined. Now what makes Rahma feel the most valued is being able to support her own parents. She told me she loves “seeing them enjoy experiences they never had time for before. I love to take them on trips and see the glee in their eyes.”"

  paragraph4 = "While discussing her relationship with Canada, Rahma told me, “I love Canada because it welcomed us when we had nowhere to go. It is my home away from my land. But being Canadian has had its challenges, some people will never see me as a full Canadian and I hate to think it will never change.” When I asked Rahma what issues facing her community she wishes she could change she told me, “in recent years there has been a wave of violence that has plagued young boys in my community, the perception is that all people from our country are violent and drug dealers. Our community has issues like many others, our youth make mistakes like any others and this reputation is ill founded and misplaced… there needs to be an awareness of the broad issues that plague the community. There is a lack of knowledge as to why these boys are in the predicament they are. There is also a lack of funding for underprivileged and immigrant communities as well as the systemic racism and the prison pipeline that Black men face in this country.”"

  paragraph5 = "Rahma told me her hopes for her young daughters are for them to “achieve a level of success we can only dream of right now. I want to see them excel because the generation before them no longer needs them. I hope money doesn't hold them back from knocking on certain doors or attending certain schools. I hope they grow up in a society that sees and values them.”"

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''

  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Ahmed
