import { Row} from 'react-bootstrap';
import FamilyCol from "./familyCol.js"

const FamilyRow = ({testProp, familyRowStyle, columns, isSingleImg,isMobile, setCurrentFamily, rowRef})=>{

return(

  <Row style = {familyRowStyle} id = {rowRef}>
  {columns.map((currentCol, i)=>{
        return (<FamilyCol
        colStyle= {currentCol.colStyle}
        img = {currentCol.img}
        imgStyle = {currentCol.imgStyle}
        title = {currentCol.title}
        titleColSize = {currentCol.titleColSize}
        blurb = {currentCol.blurb}
        city = {currentCol.city}
        cityColSize = {currentCol.cityColSize}
        isSingleImg = {isSingleImg}
        linkAddress = {currentCol.linkAddress}
        titleStyle = {currentCol.titleStyle}
        cityStyle = {currentCol.cityStyle}
        blurbStyle = {currentCol.blurbStyle}
        blurbSpacerStyle = {currentCol.blurbSpacerStyle}
        isMobile = {isMobile}
        mobileTitle = {currentCol.mobileTitle}
        mobileCity = {currentCol.mobileCity}
        mobileBlurb = {currentCol.mobileBlurb}
        setCurrentFamily = {setCurrentFamily}
        key={currentCol.name}
        />)})}
  </Row>
)
}
export default FamilyRow
