import { Row, Col,Container} from 'react-bootstrap';

import cancel from "./icons/cancel.png"

import { Link } from 'react-router-dom';
const SidePageMenu = ({ closeMenu, currentFam, isMobile})=>{

return(

<>

  <Container className = 'side-menu-mobile' style = {{marginTop:'-20vh',height:'130%'}}>
    <Row className = 'margin-top-5 margin-bottom-5'>
      <Col className = 'col-30'>
        &nbsp;
      </Col>
      <Col onClick ={closeMenu}>
        <img src={cancel} style = {{width:'4vw',position:'fixed', marginLeft:'30vw'}} alt="#"  />
      </Col>
    </Row>

    <Row className = 'margin-top-5  horizontal-centered-children'>
        <Link to="/">Home</Link>
    </Row>

    <hr className = "menu-underline_mobile" style = {{marginBottom:'2vh',marginLeft:'2vw'}}/>

    <Row className = ' margin-bottom-2 horizontal-centered-children'>
        <Link to="/about">About</Link>
    </Row>
    <Row className = ' horizontal-centered-children'>
        <Link to="/land">Land Acknowledgement</Link>
    </Row>




  </Container>


</>
)};

export default SidePageMenu;
