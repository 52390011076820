import { Row, Col} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ScrollTrigger from 'react-scroll-trigger';
import {useState} from "react";

const FamilyCol = ({colStyle, img,imgStyle,title,blurb,city, isSingleImg,linkAddress,
                   titleStyle, cityStyle, blurbStyle, blurbSpacerStyle,
                   isMobile,mobileTitle,mobileCity, mobileBlurb,
                   titleColSize, cityColSize,
                   setCurrentFamily
                 })=>{

const [showPicture, toggleShowPicture] = useState(false);
const [showTitle, toggleShowTitle] = useState(false);
const [showCity, toggleShowCity] = useState(false);
const [showBlurb, toggleShowBlurb] = useState(false);

var showLink = true;
var showLinkOnMobile = true;

if (blurb.length===0) {
  showLink = false;
}
if (mobileBlurb.length===0) {
  showLinkOnMobile = false;
}

function onEnterViewport() {

    toggleShowPicture(true);
    setCurrentFamily({title})
  };

function onEnterTitle() {
  toggleShowTitle(true);
  };

function onEnterCity() {
  toggleShowCity(true);
  };

function onEnterBlurb() {
  toggleShowBlurb(true);
  };

  function onExitViewport() {

      toggleShowPicture(false);
    };

  function onExitTitle() {
    toggleShowTitle(false);
    };

  function onExitCity() {
    toggleShowCity(false);
    };

  function onExitBlurb() {
    toggleShowBlurb(false);
    };

return(
<>
  {isMobile ?
    <Col style = {{maxWidth:'98vw',minWidth:'98vw',marginTop:'none'}}>

      <Row className = 'row-extra-small '>
        <div className = 'justified-text-right col-100 margin-right-medium'>
          <ScrollTrigger onEnter={onEnterTitle} onExit={onExitTitle}>
            <p className = {(showTitle? 'appear' : 'fade-in')}>{mobileTitle}</p>
          </ScrollTrigger>
        </div>
      </Row>
      <Row >
        <div className = 'justified-text-right col-100 margin-right-medium'>
        <ScrollTrigger onEnter={onEnterCity} onExit={onExitCity}>
          <p className = {(showCity? 'appear' : 'fade-in')}>{mobileCity}</p>
          </ScrollTrigger>
        </div>
      </Row>
      <Row className = 'horizontal-centered-children '>
        <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
          <Link to={linkAddress} >
           <img src={img} alt="Logo"  style = {{maxWidth:'95vw',minWidth:'95vw',marginLeft:'2.5vw'}} className = {(showPicture? 'appear' : 'fade-in')}/>
          </Link>
         </ScrollTrigger>
      </Row>
      <Row >

      <Col className ='col-10'>
        &nbsp;
      </Col>
        <Col className = ' blurb-col-wide-mobile'>
          <ScrollTrigger onEnter={onEnterBlurb} onExit={onExitBlurb}>
            <p className = {"blurb-mobile "+(showBlurb? 'appear' : 'fade-in')}>{mobileBlurb}
            {showLinkOnMobile ?
              <Link to={linkAddress} style={{color: "red" ,textDecoration: 'none'}}> Read More</Link>
            : null}
            </p>
          </ScrollTrigger>


        </Col>

      </Row>
    </Col>
  :
  <>
  {isSingleImg ?

    <Col style = {colStyle}>

    <Link to={linkAddress} style = {{textDecoration: 'none'}} className = 'red-text-hover'>
      <Row className = 'row-extra-small '>
        <div className = 'justified-text-right col-100 margin-right-medium'>
          <ScrollTrigger onEnter={onEnterTitle} onExit={onExitTitle}>
              <p className = {(showTitle? 'appear' : 'fade-in')}>{title}</p>
          </ScrollTrigger>
        </div>
      </Row>
      <Row >
        <div className = 'justified-text-right col-100 margin-right-medium'>
        <ScrollTrigger onEnter={onEnterCity} onExit={onExitCity}>
            <p className = {(showCity? 'appear' : 'fade-in')}>{city}</p>
          </ScrollTrigger>
        </div>
      </Row>
      </Link>


      <Row className = 'horizontal-centered-children '>
        <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
          <Link to={linkAddress} >
           <img src={img} alt="Logo" style = {imgStyle} className = {(showPicture? 'appear' : 'fade-in')}/>
          </Link>
         </ScrollTrigger>
      </Row>
      <Row >
        <Col className = 'col-30'>
        </Col>
        <Col className = ' blurb-col-wide'>
          <ScrollTrigger onEnter={onEnterBlurb} onExit={onExitBlurb}>
            <p className = {"blurb "+(showBlurb? 'appear' : 'fade-in')}>{blurb}
            {showLink ?
              <Link to={linkAddress} style={{color: "red",textDecoration: 'none'}}> Read More</Link>
            : null}
            </p>
          </ScrollTrigger>


        </Col>

      </Row>
    </Col>


    :

    <Col style = {colStyle} >

    <Link to={linkAddress} style = {{textDecoration: 'none'}} className = 'red-text-hover'>
        <Row className = 'row-extra-small ' >
          <ScrollTrigger onEnter={onEnterTitle} onExit={onExitTitle}>

              <Col className = {'justified-text-right '+titleColSize} >
                <p className = {(showTitle? 'appear ' : 'fade-in')}>{title}</p>
              </Col>

          </ScrollTrigger>
        </Row>

        <Row >
          <ScrollTrigger onEnter={onEnterCity} onExit={onExitCity}>
            <Col className = {'justified-text-right  '+titleColSize} >

                <p className = {(showCity? 'appear' : 'fade-in')}>{city}</p>

          </Col>
          </ScrollTrigger>
        </Row>
      </Link>

      <Row className = 'horizontal-centered-children'>
        <ScrollTrigger onEnter={onEnterViewport} onExit={onExitViewport}>
          <Link to={linkAddress} >
           <img src={img} alt="Logo" style = {imgStyle} className = {(showPicture? 'appear' : 'fade-in')}/>
           </Link>
         </ScrollTrigger>
      </Row>
      <Row >

        <Col style = {blurbSpacerStyle}>
        &nbsp;
        </Col>
        <Col style = {blurbStyle}>
          <p className = "blurb">{blurb}
          {showLink ?
            <Link to={linkAddress} style={{color: "red" ,textDecoration: 'none'}}> Read More</Link>
          : null}

          </p>


        </Col>

      </Row>
    </Col>




  }
  </>

}



</>
)
}
export default FamilyCol
