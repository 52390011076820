import { Row, Col,Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from "./logos/logo.png"
import { useState,useEffect } from 'react';
import alia_youssef from "./familyPictures/alia_youssef.jpg"
import table_icon from "./icons/table_icon.png"
import SidePageMenu from "./sidePageMenu.js";

const About = ()=>{
  useEffect(() => {
      toggleDevice();
    });

  window.addEventListener('resize', toggleDevice);

  const [isMobile, toggleMobile] = useState(false);

  function toggleDevice(){
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    console.log(windowWidth);
    console.log(windowHeight);
    if (windowHeight>windowWidth) {
      toggleMobile(true)
    }
    else if (windowWidth>=windowHeight) {
      toggleMobile(false)
    }
  }

  const [showMenu, toggleMenu] = useState(false);

  function closeMenu(){
    toggleMenu(false);
  }

return(
<>
{showMenu?

  <SidePageMenu
  closeMenu = {closeMenu}
  />
:

null}


<Container className = "full-screen-container">


{isMobile ?
  <Row className = 'header 'style = {{marginTop:'-17.5vh',zIndex:3}}>

  <Col className = 'col-20  horizontal-centered-children' onClick = {toggleMenu}>
    <img src={table_icon} style = {{width:'7.5vw',marginLeft:'10vw'}} alt="Logo" onClick = {toggleMenu}/>
  </Col>


    <Col className = 'col-80  horizontal-centered-children'>
    <Link to='/'>
      <img src={logo} style = {{width:'50vw',marginRight:'15vw'}} alt="Logo" />
      </Link>
    </Col>




  </Row>
  :
  <>
  <Row className = 'header 'style = {{marginTop:'-22.5vh',zIndex:3}}>

    <Col className = 'col-5  horizontal-centered-children'>
    &nbsp;
    </Col>

    <Col className = 'col-10  horizontal-centered-children'>
      <Link style = {{textDecoration: 'none',color:"black"}}className = "link" to="/">
        <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>   Home </p>
      </Link>
    </Col>

    <Col className = 'col-20 '>
     &nbsp;
    </Col>
    <Col className = 'col-5 '>
     &nbsp;
    </Col>

    <Col className = 'col-20  horizontal-centered-children'>
    <Link to='/'>
      <img src={logo} style = {{width:'20vw'}} alt="Logo" />
      </Link>
    </Col>

    <Col className = 'col-10 '>
     &nbsp;
    </Col>

    <Col className = 'col-10  horizontal-centered-children'>
      <Link style = {{color:'red',textDecoration: 'none'}} className = "link" to="/about">
        <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>About</p>
      </Link>
    </Col>

    <Col className = 'col-20  horizontal-centered-children'>
      <Link style = {{textDecoration: 'none', color:'black'}} className = "link"  to="/land">
        < p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>Land Acknowledgement</p>
      </Link>
    </Col>
  </Row>
  <Row style = {{marginTop:'20vh',height:'5vh'}}>
    &nbsp;
  </Row>
  </>
}





  <Row className = {(isMobile? 'mobile-about-top-margin ' : '')}>
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>“Art reminds us that we belong here.”</p>
  </Row>
  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>-Toni Morrison, The Source of Self Regard, 2019</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>A long history of orientalist tropes and media stereotypes has dominated mainstream representations of Muslim people in the West. Although the recorded history of Muslim-identified communities in Canada begins in 1854 with the story of James and Agnes Love, a young couple from Scotland, our collective histories and experiences have been overshadowed by stereotypical depictions in Canadian media and collective memory.</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>This continual, ongoing misrepresentation has left our identities constantly contested, whether through Islamophobic violence or divisive political rhetoric regarding the hijab or niqab. From personal experience, I know these actions and words have left many of us questioning our own sense of belonging and identity.</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>
    Generations aims to complicate the generally simplistic depiction of Muslim communities by asserting our diverse narratives —past and present — through multigenerational portraits and shared stories, particularly in the context of Canada’s collective consciousness. My goal for this work is to make space for women’s stories, which are so often sidelined in both marginal and mainstream narratives. These photographs of family units, in which a matriarchal figure is usually portrayed as a carrier of wisdom and keeper of cultural and family narratives, are intended to draw attention to both generational similarities and differences.</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>Authors and historians have begun the process of documenting Muslim Canadian histories, and as a portrait photographer I wanted to expand upon their work. My intention is to build an intergenerational record of our gendered experiences, one that accounts for how we would like to be visually represented. This series of multigenerational group portraits features women of families that are first through to fourth generation Canadians. They immigrated as early as 1901 and as recently as 2008, and come from a multitude of places and cultures, from Fiji to Lebanon to Somalia to Albania. Additionally, they speak a variety of languages, have wide-ranging careers, passions, and goals, and belong to different Islamic or ethnic communities all across Canada.</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>This project is an invitation to you--community members and viewers--to relate and connect, to share and celebrate, to photograph and keep written records of yourself and your chosen families, and to claim space for your stories. As Thy Phu and Elspeth Brown assert, “family photographs…are technologies of enacting a future, right now, in the present, in order to create a future that hasn’t happened yet, but must.”</p>
  </Row>

  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>-Alia Youssef</p>
  </Row>

  <Row >
  <a href= 'https://www.aliayoussef.com'style = {{textDecoration:'none'}} target= 'none'>
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile red-text ' : 'bio-p page_text red-text ')} >www.aliayoussef.com</p>
</a>
  </Row>


{isMobile?
  <>
    <Row>
    <Col className = 'col-100 horizontal-centered-children'>

        <img src={alia_youssef} style = {{maxWidth:'95vw',maxHeight:'60vh',marginTop:'3vh',marginBottom:'5vh'}} alt="Logo" />

    </Col>
    <Col className = 'col-90 margin-left-small'>
      <Container>
        <Row className = 'horizontal-centered-children'  >
          <p className = {(isMobile? 'bio-p-mobile page-text-mobile bold-text' : 'bio-p page_text bold-text')}>
            FAQ:
          </p>
        </Row>
        <Row className = 'horizontal-centered-children'>
          <p className = "bold-text justified-text">How did you choose to include these families? Can I participate?</p>
        </Row>
        <Row >
          <p className = "full-justified-text">The 30 families involved either responded to my social media call outs or were recommended to me by word of mouth. Unfortunately, I am not including additional families in the project at this time.</p>
        </Row>
        <Row className = 'horizontal-centered-children' >
          <p className = "bold-text justified-text">How were the bio’s formulated?</p>
        </Row>
        <Row >
          <p className = "justified-text">An identical questionnaire was sent to all the project participants after the photoshoots. I aimed to build a narrative from their responses, while weaving through as many of their answers as possible. Due to this process, every bio has varying lengths and draws attention to the stories each family wanted shared.</p>
        </Row>
      </Container>

    </Col>
    </Row>

    <Row >

      <Col className = 'col-90 margin-left-small horizontal-centered-children'>
      <Container>
        <Row className = 'horizontal-centered-children'>
          <p className = "bold-text justified-text">What camera did you use?</p>
        </Row>
        <Row >
          <p className = "justified-text">I photographed each shoot with a film camera in order to reference the popular medium of 20th century family photography which can be seen in archives today. I used a medium format film camera, a Pentax 67, for this project.</p>
        </Row>

        <Row className = 'horizontal-centered-children'>
          <p className = "bold-text justified-text">What was the timeline of the project?</p>
        </Row>

        <Row >
          <p className = "justified-text">I produced this project within Ryerson University’s MFA Documentary Media program between September 2018 - June 2020. The photoshoots took place between June 2019 and February 2020.</p>
        </Row>

        <Row className = 'horizontal-centered-children'>
          <p className = "bold-text justified-text">How can we reach you for questions, media inquiries, or speaking opportunities?</p>
        </Row>
        <Row >
          <p className = "">I am reachable at aliayoussefphotography@gmail.com</p>
        </Row>
        <Row className = 'horizontal-centered-children'>
          <p className = "bold-text justified-text">Acknowledgements:</p>
        </Row>

        <Row >
          <p className = "justified-text">This project has benefited greatly from the mentorship, guidance, and support of Brett Story and Don Snyder, both of whom I am extremely grateful for. I would also like to thank Katy McCormick, Vid Ingelevics, and Sara Angelucci for their insight at different stages of this work.</p>
        </Row>

        <Row >
          <p className = "justified-text">I cannot thank my family enough for not only their support with this project, but with all my creative endeavors. Thank you to my mother, Allison Barton Youssef, who has fostered my creativity and love of photography since I was 14. Everything I make is dedicated to her, for I wouldn’t be the photographer or person I am without her unconditional love and support. Thank you to my husband, Shawn Hymers, who encourages me, motivates me, bounces ideas with me, is my second pair of eyes, is the first pass editor to everything I write, and builds me up everytime I struggle and fall down. He has supported me every step of every project, including this one, which he even coded and developed this very website for.</p>
        </Row>

        <Row >
          <p className = "justified-text">This project was funded with thanks to several scholarships and awards: Silk Road Institute Creative Arts Grant, Mort Greenberg Award granted by the Documentary Media MFA Program, and the SSHRC Canada Graduate Scholarship-Master’s Award.</p>
        </Row>


      </Container>
      </Col>
    </Row>
    </>
    :
  <>
    <Row>
    <Col className = 'col-30'>
      <Row>
        <img src={alia_youssef} style = {{width:'25vw', marginLeft:'20vw',marginTop:'8vh'}} alt="Logo" />
      </Row>
    </Col>
    <Col className = 'col-30' style = {{marginLeft:'20vw'}}>
      <Container>
        <Row className = 'horizontal-centered-children'>
          <p className = {(isMobile? 'bio-p-mobile page-text-mobile bold-text' : 'bio-p page_text bold-text')}>FAQ:</p>
        </Row>
        <Row c>
          <p className = "bold-text">How did you choose to include these families? Can I participate?</p>
        </Row>
        <Row >
          <p className = "full-justified-text">The 30 families involved either responded to my social media call outs or were recommended to me by word of mouth. Unfortunately, I am not including additional families in the project at this time.</p>
        </Row>
        <Row >
          <p className = "bold-text">How were the bio’s formulated?</p>
        </Row>
        <Row >
          <p className = "full-justified-text">An identical questionnaire was sent to all the project participants after the photoshoots. I aimed to build a narrative from their responses, while weaving through as many of their answers as possible. Due to this process, every bio has varying lengths and draws attention to the stories each family wanted shared.</p>
        </Row>
      </Container>

    </Col>
    </Row>

    <Row style = {{marginTop:'5vh'}}>
      <Col className = 'col-20'>
        &nbsp;
      </Col>
      <Col className = 'col-60'>

      <Row >
        <p className = "bold-text">What camera did you use?</p>
      </Row>
      <Row >
        <p className = "full-justified-text">I photographed each shoot with a film camera in order to reference the popular medium of 20th century family photography which can be seen in archives today. I used a medium format film camera, a Pentax 67, for this project.</p>
      </Row>

      <Row >
        <p className = "bold-text">What was the timeline of the project?</p>
      </Row>

      <Row >
        <p className = "full-justified-text">I produced this project within Ryerson University’s MFA Documentary Media program between September 2018 - June 2020. The photoshoots took place between June 2019 and February 2020.</p>
      </Row>

      <Row >
        <p className = "bold-text">How can we reach you for questions, media inquiries, or speaking opportunities?</p>
      </Row>
      <Row >
        <p className = "full-justified-text">I am reachable at aliayoussefphotography@gmail.com</p>
      </Row>
      <Row className = 'horizontal-centered-children' style = {{marginTop:'8vh'}}>
        <p className = "bold-text">Acknowledgements:</p>
      </Row>

      <Row >
        <p className = "full-justified-text">This project has benefited greatly from the mentorship, guidance, and support of Brett Story and Don Snyder, both of whom I am extremely grateful for. I would also like to thank Katy McCormick, Vid Ingelevics, and Sara Angelucci for their insight at different stages of this work.</p>
      </Row>

      <Row >
        <p className = "full-justified-text">I cannot thank my family enough for not only their support with this project, but with all my creative endeavors. Thank you to my mother, Allison Barton Youssef, who has fostered my creativity and love of photography since I was 14. Everything I make is dedicated to her, for I wouldn’t be the photographer or person I am without her unconditional love and support. Thank you to my husband, Shawn Hymers, who encourages me, motivates me, bounces ideas with me, is my second pair of eyes, is the first pass editor to everything I write, and builds me up everytime I struggle and fall down. He has supported me every step of every project, including this one, which he even coded and developed this very website for.</p>
      </Row>

      <Row >
        <p className = "full-justified-text">This project was funded with thanks to several scholarships and awards: Silk Road Institute Creative Arts Grant, Mort Greenberg Award granted by the Documentary Media MFA Program, and the SSHRC Canada Graduate Scholarship-Master’s Award.</p>
      </Row>

      </Col>
      <Col className = 'col-20'>
        &nbsp;
      </Col>
    </Row>

    <Row style = {{height:'8vh'}}>
      &nbsp;
    </Row>
    </>}

  </Container>
  </>



)
}
export default About
