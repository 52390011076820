// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import ali_1 from '../familyPictures/ali_1.jpg'


const Ali = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Ali Family'
  img1 = {ali_1}
  caption1 = 'Left to Right: Tamara Ali, Tazul Nisha Ali, Yasifa Ali'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“Being a Muslim, there is subtle discrimination that is often present. Islamophobia is everywhere and prevalent here… I would like to emphasize on bringing awareness of the issues and providing venues for more open dialogue for our communities to voice themselves.”'

  paragraph2 = "Tazul, her two young children, and husband arrived in Vancouver, BC from the Fiji Islands in the early 1970s. They had a warm and welcoming arrival since Tazul’s brother already lived in Vancouver and was the one to sponsor them. Tazul describes the mixed feelings she encountered: happiness seeing her brother and his family who she hadn’t seen for many years, and sadness as she missed the family who they left behind. A story that stood out to her from those early days was how they ended up buying their first house. She told me, “we were renting a basement suite in Vancouver after moving out of my brother’s house. To access this suite, we had to use the door at the back of the house. One day, my son who was about 4 years old asked us, “why can’t we use the front door to go inside. I want to go through the front door.” This broke our hearts. We decided to buy a house for ourselves and found one in Richmond, which was like across the big bridge. We put all the money we had to secure this house and now we were broke and had to wait for our paycheck at the end of the month. We felt so insecure but seeing the happiness and security in our son entering the house from the front door was worth all the debt that we had to accumulate for this house.”"

  paragraph3 = 'Tazul and her husband are very involved individuals. In their early years they got to know almost every Muslim family in the city. She told me, “In 1970’s, there were not many Muslim families so we could know almost all the people. There was only one mosque to congregate. Now the Muslim population is quite large and diverse. There are so many Muslim organizations to cater for different nationals. Almost every city in BC has a mosque or a center for Muslims to congregate.” Tazul played her part in growing this Vancouver community, telling me, “my husband and I were very involved with the BC Muslim Association (BCMA) which was founded in 1966. We were also fortunate to be part of the BCMA first mosque, Jamea Mosque in Richmond, BC. It was actually a great sense of pride to work toward the first Muslim School in BC. This school has accommodated many children with Islamic environment and knowledge. We have grown with this association in all the ways and am blessed to be part of it still. In 1982, I was selected to represent BC at the inauguration of Canadian Council of Muslim Women (CCMW) in Winnipeg. I became the first secretary and was privileged to work with late Lila Fahlman, the founder of CCMW. I have learned so much from the women of so many different nationalities. After 35 years, I am still part of the CCMW Vancouver Chapter.” When I asked Tazul how her deep involvement in the community has affected her relationship with where she lives, she told me, “when I migrated to Canada, I felt blessed to have come to this beautiful country. I worked hard and have a wonderful family. I integrated into the larger community and am doing my best now to contribute my time for the betterment of this community.”'

  paragraph4 = "Despite all her accomplishments in the community, when I asked Tazul what she sees as her family’s proudest achievement, she told me, “I am very proud of my two children who have worked very hard and still are working hard to give the best to their children. They are living close to us and that gives us the comfort and security of our golden age...I feel most valued when my children give [us] respect and love.”"

  paragraph5 = 'Yasifa, Tazul’s daughter-in-law, was born and raised in Edmonton. Her family had immigrated there, after also leaving the Fiji Islands in the early 1970s. She told me, “I belonged to the Muslim Community in Edmonton Alberta 20 years ago and since I have been living in BC the community in Edmonton has grown so much. It’s impressive to see how they have progressed from the time that I was living there.” Yasifa describes herself as a public servant and feels most valued when she knows her family is taken care of and she is able “to provide and guide them through any challenges they face.” When I asked her what her hopes for her daughter Tamara are, she told me, “that she grows up to value and respect her elders and to be able to accomplish anything she puts her mind to.”'

  paragraph6 = ''

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default Ali
