import  { React, Component } from "react";
import { Link } from 'react-router-dom';
import { Row, Col,Container} from 'react-bootstrap';
import FamilyRow from "./familyRow.js";
import SideMenu from "./sideMenu.js";


import hamdon_1 from "./familyPictures/hamdon_1.jpg"
import hamdon_2 from "./familyPictures/hamdon_2.jpg"

import ali_1 from "./familyPictures/ali_1.jpg"

import beydoun_1_new from "./familyPictures/beydoun_1_new.jpg"
import beydoun_2 from "./familyPictures/beydoun_2.jpg"

import ahmed_1 from "./familyPictures/ahmed_1.jpg"

import badawi_1 from "./familyPictures/badawi_1.jpg"
import badawi_2 from "./familyPictures/badawi_2.jpg"

import ashraf_1 from "./familyPictures/ashraf_1.jpg"

import bejkosalaj_1 from "./familyPictures/bejkosalaj_1.jpg"
import bejkosalaj_2 from "./familyPictures/bejkosalaj_2.jpg"

import ahmed_ali_1 from "./familyPictures/ahmed_ali_1.jpg"

import habib_1 from "./familyPictures/habib_1.jpg"
import habib_2 from "./familyPictures/habib_2.jpg"

import aldosky_1 from "./familyPictures/aldosky_1.jpg"
// import aldosky_2 from "./familyPictures/aldosky_2.jpg"

import hindy_1 from "./familyPictures/hindy_1.jpg"
import hindy_2 from "./familyPictures/hindy_2.jpg"
// import hindy_3 from "./familyPictures/hindy_3.jpg"

import faruqui_1 from "./familyPictures/faruqui_1.jpg"

import jahangeer_1 from "./familyPictures/jahangeer_1.jpg"
import jahangeer_2 from "./familyPictures/jahangeer_2.jpg"

import abdi_1 from "./familyPictures/abdi_1.jpg"
// import abdi_2 from "./familyPictures/abdi_2.jpg"

import morsi_1 from "./familyPictures/morsi_1.jpg"
import morsi_2 from "./familyPictures/morsi_2.jpg"

import farooq_1 from "./familyPictures/farooq_1.jpg"

import jaroudi_1 from "./familyPictures/jaroudi_1.jpg"
import jaroudi_2 from "./familyPictures/jaroudi_2.jpg"

import dada_1 from "./familyPictures/dada_1.jpg"
// import dada_2 from "./familyPictures/dada_2.jpg"

import gilani_1 from "./familyPictures/gilani_1.jpg"
import gilani_2 from "./familyPictures/gilani_2.jpg"

import hussain_1 from "./familyPictures/hussain_1.jpg"

import khan_1 from "./familyPictures/khan_1.jpg"
import khan_2 from "./familyPictures/khan_2.jpg"

import afsar_1 from "./familyPictures/afsar_1.jpg"
// import afsar_2 from "./familyPictures/afsar_2.jpg"

import sheikh_1 from "./familyPictures/sheikh_1.jpg"
import sheikh_2 from "./familyPictures/sheikh_2.jpg"

import hadad_1 from "./familyPictures/hadad_1.jpg"
// import hadad_2 from "./familyPictures/hadad_2.jpg"

import jamal_1 from "./familyPictures/jamal_1.jpg"
import jamal_2 from "./familyPictures/jamal_2.jpg"

import ibrahin_1 from "./familyPictures/ibrahin_1.jpg"

import youssef_1 from "./familyPictures/youssef_1.jpg"
import youssef_2 from "./familyPictures/youssef_2.jpg"
import youssef_3 from "./familyPictures/youssef_3.jpg"

import table_icon from "./icons/table_icon.png"
import logo from "./logos/logo.png"

class Generations extends Component {
  // Setting up initial state
  constructor(props) {
    super(props);
    this.state = {
      families:[
        {name:'Hamdon',
         testProp:'test1',
         familyRowStyle:{marginBottom:'7.5vh'},
         isSingleImg:false,

         columns:[{name:'fam1col1',
                   title : "",
                   city: "",
                   mobileTitle : "The Hamdon Family",
                   mobileCity: "Edmonton, AB",
                   blurb:"“[In the 1930’s] the few Muslim families that were in Edmonton gathered together often. They decided they wanted a place to congregate so they were going to build a community hall, but then my mother-in-law, Hilwie Hamdon said, ‘if we can build a community hall, we can build a mosque.'”",
                   mobileBlurb:'',
                   blurbSpacerStyle:{minWidth:'21vw', maxWidth:'21vw'},
                   blurbStyle:{minWidth:'28vw', maxWidth:'28vw'},
                   linkAddress:'/hamdon',
                   img:hamdon_2,
                   colStyle:{minWidth:'50vw',maxWidth:'50vw'},
                   imgStyle : {minWidth:'47vw',maxWidth:'47vw',marginLeft:'1.5vw'}
                   },

                   {name:'fam1col2',
                   title : "The Hamdon Family",
                   titleColSize:'col-48',
                   titleStyle: {paddingLeft:'37vw'},
                   city: "Edmonton, AB",
                   cityColSize:'col-48',
                   cityStyle: {paddingLeft:'37vw'},
                   blurb:"",
                   mobileBlurb:"“[In the 1930’s] the few Muslim families that were in Edmonton gathered together often. They decided they wanted a place to congregate so they were going to build a community hall, but then my mother-in-law, Hilwie Hamdon said, ‘if we can build a community hall, we can build a mosque.'”",

                   linkAddress:'/hamdon',
                   img:hamdon_1,
                   colStyle:{minWidth:'50vw',maxWidth:'50vw',marginTop:'20vh'},
                   imgStyle : {minWidth:'47vw',maxWidth:'47vw',marginRight:'1.5vw'}}]},

        {name:'Ali1',
         testProp:'test1',
         familyRowStyle:{marginBottom:'7.5vh'},
         isSingleImg:true,

         columns:[{name:'fam1col1',
                   title : "The Ali Family ",
                   city: "Burnaby. BC",
                   mobileTitle : "The Ali Family",
                   mobileCity: "Burnaby. BC",
                   blurb:"“Being a Muslim, there is subtle discrimination that is often present. Islamophobia is everywhere and prevalent here… I would like to emphasize on bringing awareness of the issues and providing venues for more open dialogue for our communities to voice themselves.”",
                   mobileBlurb:"“Being a Muslim, there is subtle discrimination that is often present. Islamophobia is everywhere and prevalent here… I would like to emphasize on bringing awareness of the issues and providing venues for more open dialogue for our communities to voice themselves.”",

                   linkAddress:'/ali',
                   img:ali_1,
                   colStyle:{width:'100vw'},
                   imgStyle : {width:'65vw'}}]},

        {name:'Beydoun',
         testProp:'test1',
         familyRowStyle:{marginBottom:'7.5vh'},
         isSingleImg:false,

         columns:[{name:'fam1col1',
                   title : "",
                   city: "",
                   mobileTitle : "The Beydoun /  Matar Family",
                   mobileCity: "Halifax, NS",
                   blurb:'',
                   mobileBlurb:'',
                   linkAddress:'/beydoun',
                   img:beydoun_2,
                   colStyle:{marginTop:'35vh',minWidth:'40vw',maxWidth:'40vw'},
                   imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginLeft:'1.5vw'}},

                   {name:'fam1col2',
                   title : "The Beydoun /  Matar Family",
                   titleStyle: {paddingLeft:'42vw'},
                   titleColSize:'col-59',
                   city: "Halifax, NS",
                   cityStyle: {paddingLeft:'42vw'},
                   blurb:"“I hope that the next generation can be that actualization of what meaningful reconciliation can be through recognizing the colonial past of this country and how our settlement here has been a part of ongoing colonization against Indigenous peoples, unintentional or not.”",
                   mobileBlurb:"“I hope that the next generation can be that actualization of what meaningful reconciliation can be through recognizing the colonial past of this country and how our settlement here has been a part of ongoing colonization against Indigenous peoples, unintentional or not.”",

                   blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
                   blurbStyle:{minWidth:'38vw', maxWidth:'41vw'},
                   linkAddress:'/beydoun',
                   img:beydoun_1_new,
                   colStyle:{minWidth:'60vw',maxWidth:'60vw'},
                   imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginRight:'1.5vw'}}]},


            {name:'Ahmed',
             testProp:'test1',
             familyRowStyle:{marginBottom:'7.5vh'},
             isSingleImg:true,

             columns:[{name:'fam1col1',
                       title : "The Ahmed Family",
                       city: "Edmonton, AB",
                       mobileTitle : "The Ahmed Family",
                       mobileCity: "Edmonton, AB",
                       blurb:"“We have endured and we solidified our roots here. Each generation is pouring into the future of the next. Each sacrifice is seen as a building block for the next generation to go further and reach higher.”",
                       mobileBlurb:"“We have endured and we solidified our roots here. Each generation is pouring into the future of the next. Each sacrifice is seen as a building block for the next generation to go further and reach higher.”",

                       linkAddress:'/ahmed',
                       img:ahmed_1,
                       colStyle:{width:'100vw'},
                       imgStyle : {width:'65vw'}}]},

{name:'Badawi',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

 columns:[{name:'fam1col1',
           title : "",
           city: "",
           mobileTitle : "The Badawi Family",
           mobileCity: "Halifax, NS",
           mobileBlurb:'',
           blurb:"“The value the Badawi family brought to the community doesn’t lie in a single story or event. The entire family has been a pillar of the community in so many ways; from major achievements such as founding schools and community centres to hosting events and bringing people together.”",
           blurbSpacerStyle:{minWidth:'20vw', maxWidth:'20vw'},
           blurbStyle:{minWidth:'35vw', maxWidth:'35vw'},
           linkAddress:'/badawi',
           img:badawi_1,
           colStyle:{minWidth:'55vw',maxWidth:'55vw'},
           imgStyle : {minWidth:'52vw',maxWidth:'52vw',marginLeft:'1.5vw'}},

           {name:'fam1col2',
           title : "The Badawi Family",
           titleStyle: {paddingLeft:'32vw'},
           titleColSize:'col-44',
           city: "Halifax, NS",
           cityStyle: {paddingLeft:'32vw'},
           blurb:"",
           mobileBlurb:"“The value the Badawi family brought to the community doesn’t lie in a single story or event. The entire family has been a pillar of the community in so many ways; from major achievements such as founding schools and community centres to hosting events and bringing people together.”",

           linkAddress:'/badawi',
           img:badawi_2,
           colStyle:{minWidth:'45vw',maxWidth:'45vw',marginTop:'20vh'},
           imgStyle : {minWidth:'42vw',maxWidth:'42vw',marginRight:'1.5vw'}}]},

{name:'ashraf',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Junaid / Ashraf Family",
           city: "Pointe-Claire, QC",
           mobileTitle : "The Junaid / Ashraf Family",
           mobileCity: "Pointe-Claire, QC",
           blurb:"“The very basic aspect of mixing “culture” with “religion” is what I would wish to change. I think (and am hopeful too) that the younger generation will realize these differences and change that.  The older generation is very stuck in their idea of “religion.””",
           mobileBlurb:"“The very basic aspect of mixing “culture” with “religion” is what I would wish to change. I think (and am hopeful too) that the younger generation will realize these differences and change that.  The older generation is very stuck in their idea of “religion.””",

           linkAddress:'/ashraf',
           img:ashraf_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'Bejkosalaj',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Bejkosalaj-Kuki Family",
          mobileCity: "Ottawa, ON",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/bejkosalaj',
          img:bejkosalaj_2,
          colStyle:{minWidth:'55vw',maxWidth:'55vw'},
          imgStyle : {minWidth:'52vw',maxWidth:'52vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Bejkosalaj-Kuki Family",
          titleStyle: {paddingLeft:'27vw'},
          titleColSize:'col-44',
          city: "Ottawa, ON",
          cityStyle: {paddingLeft:'27vw'},
          blurb:"“My parents always believed that your life should serve a purpose. Their marriage and partnership symbolized the union of two individuals that cared deeply for their country of origin, their religion, but also their Canadian community at large.”",
          mobileBlurb:"“My parents always believed that your life should serve a purpose. Their marriage and partnership symbolized the union of two individuals that cared deeply for their country of origin, their religion, but also their Canadian community at large.”",

          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'32vw', maxWidth:'32vw'},
          linkAddress:'/bejkosalaj',
          img:bejkosalaj_1,
          colStyle:{minWidth:'45vw',maxWidth:'45vw', marginTop:'4vh'},
          imgStyle : {minWidth:'42vw',maxWidth:'42vw',marginRight:'1.5vw'}}]},

{name:'Ali2',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Ali Family",
           city: "Ottawa, ON",
           mobileTitle : "The Ali Family",
           mobileCity: "Ottawa, ON",
           blurb:"“I remind myself it is Indigenous lands and no one but an Indigenous person can tell me I don’t belong here. Anytime I was told I don’t belong here or had racial slurs sent my way, they were by a non-Indigenous person so it never truly affected me.”",
           mobileBlurb:"“I remind myself it is Indigenous lands and no one but an Indigenous person can tell me I don’t belong here. Anytime I was told I don’t belong here or had racial slurs sent my way, they were by a non-Indigenous person so it never truly affected me.”",

           linkAddress:'/ali2',
           img:ahmed_ali_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'Habib',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Habib Family",
          mobileCity: "Abbotsford, BC",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/habib',
          img:habib_2,
          colStyle:{minWidth:'50vw',maxWidth:'50vw',marginTop:'25vh'},
          imgStyle : {minWidth:'47vw',maxWidth:'47vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Habib Family",
          titleStyle: {paddingLeft:'38vw'},
          titleColSize:'col-49',
          city: "Abbotsford, BC",
          cityStyle: {paddingLeft:'38vw'},
          blurb:"“It is my duty to give back to Canada by contributing my time and skills to help build my community and respect its values… [but] there are people who still question my loyalties based on my appearance.”",
          mobileBlurb:"“It is my duty to give back to Canada by contributing my time and skills to help build my community and respect its values… [but] there are people who still question my loyalties based on my appearance.”",

          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'35vw', maxWidth:'35vw'},
          linkAddress:'/habib',
          img:habib_1,
          colStyle:{minWidth:'50vw',maxWidth:'50vw'},
          imgStyle : {minWidth:'47vw',maxWidth:'47vw',marginRight:'1.5vw'}}]},

{name:'Aldosky',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Aldosky / Albarzanjy Family",
           city: "Vancouver, BC",
           mobileTitle : "The Aldosky Family",
           mobileCity: "Vancouver, BC",
           blurb:"“I don’t know if I’ll live to see the day that Kurds are included or highlighted in a database, anywhere. We are born with someone attempting to erase us.”",
           mobileBlurb:"“I don’t know if I’ll live to see the day that Kurds are included or highlighted in a database, anywhere. We are born with someone attempting to erase us.”",

           linkAddress:'/aldosky',
           img:aldosky_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},

{name:'Hindy',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Hindy Family",
          mobileCity: "Mississauga, ON",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/hindy',
          img:hindy_1,
          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Hindy Family",
          titleStyle: {paddingLeft:'28vw'},
          titleColSize:'col-38',
          city: "Mississauga, ON",
          cityStyle: {paddingLeft:'28vw'},
          blurb:"“My hopes are that the next generation feel more comfortable in their own skin. That their heritage and identity is embraced wholly in Canadian culture. That they don’t feel like they have to conform in any way. That they can explore different facets of their identity freely without feeling any internal guilt or external resistance.”",
          mobileBlurb:"“My hopes are that the next generation feel more comfortable in their own skin. That their heritage and identity is embraced wholly in Canadian culture. That they don’t feel like they have to conform in any way. That they can explore different facets of their identity freely without feeling any internal guilt or external resistance.”",

          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'26vw', maxWidth:'26vw'},
          linkAddress:'/hindy',
          img:hindy_2,
          colStyle:{minWidth:'40vw',maxWidth:'40vw',marginTop:'10vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginRight:'1.5vw'}}]},

{name:'Faruqui',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Faruqui Family",
           city: "Ottawa, ON",
           mobileTitle : "The Faruqui Family",
           mobileCity: "Ottawa, ON",
           blurb:"“Our children are not Pakistani, they’re Canadian, but they still have ties to their heritage. I think as they grow up, they’re less interested in assimilation and more in being their own hybrid selves, painful though it is, to be not wholly one or the other.”",
           mobileBlurb:"“Our children are not Pakistani, they’re Canadian, but they still have ties to their heritage. I think as they grow up, they’re less interested in assimilation and more in being their own hybrid selves, painful though it is, to be not wholly one or the other.”",

           linkAddress:'/faruqui',
           img:faruqui_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},

{name:'Jahangeer',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Jahangeer Family",
          mobileCity: "Toronto, ON",
          blurb:"“Women’s history is usually passed over in favour of men’s history because they were not seen as public figures, and as we know Canada did not consider women as “persons” until the Person’s Case of 1929... While this was the history of Western women, it wasn’t necessarily the case for all women.”",
          mobileBlurb:'',
          blurbSpacerStyle:{minWidth:'22vw', maxWidth:'22vw'},
          blurbStyle:{minWidth:'23vw', maxWidth:'23vw'},
          linkAddress:'/jahangeer',
          img:jahangeer_2,
          colStyle:{minWidth:'45vw',maxWidth:'45vw',marginTop:'10vh'},
          imgStyle : {minWidth:'42vw',maxWidth:'42vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Jahangeer Family",
          titleStyle: {paddingLeft:'40vw'},
          titleColSize:'col-54',
          city: "Toronto, ON",
          cityStyle: {paddingLeft:'40vw'},
          blurb:"",
          mobileBlurb:"“Women’s history is usually passed over in favour of men’s history because they were not seen as public figures, and as we know Canada did not consider women as “persons” until the Person’s Case of 1929... While this was the history of Western women, it wasn’t necessarily the case for all women.”",

          linkAddress:'/jahangeer',
          img:jahangeer_1,
          colStyle:{minWidth:'55vw',maxWidth:'55vw'},
          imgStyle : {minWidth:'52vw',maxWidth:'52vw',marginRight:'1.5vw'}}]},


{name:'Abdi',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Abdi / Elmi Family",
           city: "Toronto, ON",
           mobileTitle : "The Abdi / Elmi Family",
           mobileCity: "Toronto, ON",
           blurb:"“We would love to wake up to a world that celebrates and promotes Black joy, Black Art, Black knowledge, Black wisdom and Black brilliance.”",
           mobileBlurb:"“We would love to wake up to a world that celebrates and promotes Black joy, Black Art, Black knowledge, Black wisdom and Black brilliance.”",
           linkAddress:'/abdi',
           img:abdi_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},

{name:'Morsi',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Morsi Family",
          mobileCity: "Halifax, NS",
          mobileBlurb:'',
          blurb:"“As immigrants I believe being given better opportunities is a driving force to making something of yourself... My brothers and I always feel that we have to thank our parents with our achievements. Sometimes it places invisible pressure because my parent’s sacrifice [was so great].”",
          blurbSpacerStyle:{minWidth:'30vw', maxWidth:'30vw'},
          blurbStyle:{minWidth:'30vw', maxWidth:'30vw'},
          linkAddress:'/morsi',
          img:morsi_1,
          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Morsi Family",
          titleStyle: {paddingLeft:'28vw'},
          titleColSize:'col-39',
          city: "Halifax, NS",
          cityStyle: {paddingLeft:'28vw'},
          blurb:"",
          mobileBlurb:"“As immigrants I believe being given better opportunities is a driving force to making something of yourself... My brothers and I always feel that we have to thank our parents with our achievements. Sometimes it places invisible pressure because my parent’s sacrifice [was so great].”",

          linkAddress:'/morsi',
          img:morsi_2,
          colStyle:{minWidth:'40vw',maxWidth:'40vw',marginTop:'20vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginRight:'1.5vw'}}]},

{name:'Farooq',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Farooq Family",
           city: "Edmonton, AB",
           mobileTitle : "The Farooq Family",
           mobileCity: "Edmonton, AB",
           blurb:"“When my mother passed away, I knelt down and touched the soil, and realized that the foundation of my family was now mixed into this very land. Nothing will ever change that, no matter how many political crises, terrorist threats, racist attacks, or white supremacists try to make me doubt that.”",
           mobileBlurb:"“When my mother passed away, I knelt down and touched the soil, and realized that the foundation of my family was now mixed into this very land. Nothing will ever change that, no matter how many political crises, terrorist threats, racist attacks, or white supremacists try to make me doubt that.”",
           linkAddress:'/farooq',
           img:farooq_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'Jaroudi',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Jaroudi Family",
          mobileCity: "Langley, BC",
          blurb:"“As a photographer myself, I am always documenting our life through photographs. Growing up, we didn’t have many photos outside the popular family trips and birthdays. I want my kids to have a little sense of their own history and their upbringing…I want my grandkids and my great great grandkids and so on to know who we were and what we did to get here.”",
          mobileBlurb:'',
          blurbSpacerStyle:{minWidth:'16vw', maxWidth:'16vw'},
          blurbStyle:{minWidth:'24vw', maxWidth:'24vw'},
          linkAddress:'/jaroudi',
          img:jaroudi_2,
          colStyle:{minWidth:'40vw',maxWidth:'40vw',marginTop:'12.3vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Jaroudi Family",
          titleStyle: {paddingLeft:'47vw'},
          titleColSize:'col-59',
          city: "Langley, BC",
          cityStyle: {paddingLeft:'47vw'},
          blurb:"",
          mobileBlurb:"“As a photographer myself, I am always documenting our life through photographs. Growing up, we didn’t have many photos outside the popular family trips and birthdays. I want my kids to have a little sense of their own history and their upbringing…I want my grandkids and my great great grandkids and so on to know who we were and what we did to get here.”",

          linkAddress:'/jaroudi',
          img:jaroudi_1,
          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginRight:'1.5vw'}}]},

{name:'Dada',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Dada Family",
           city: "Toronto, ON",
           mobileTitle : "The Dada Family",
           mobileCity: "Toronto, ON",
           blurb:"“I remember days when we would be looking for pennies in the sofa so we could go buy milk.  Yet somehow, they taught us the importance of charity, the importance of respect, the importance of treating everyone equally.”",
           mobileBlurb:"“I remember days when we would be looking for pennies in the sofa so we could go buy milk.  Yet somehow, they taught us the importance of charity, the importance of respect, the importance of treating everyone equally.”",

           linkAddress:'/dada',
           img:dada_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'Gilani',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Gilani Family",
          mobileCity: "Guelph, ON",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/gilani',
          img:gilani_1,
          colStyle:{minWidth:'40vw',maxWidth:'40vw',marginTop:'25vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Gilani Family",
          titleStyle: {paddingLeft:'48vw'},
          titleColSize:'col-59',
          city: "Guelph, ON",
          cityStyle: {paddingLeft:'48vw'},
          blurb:"“Me and my sisters have always found it so hard when people ask us “where we are from” because we have lived our whole lives in Canada, regardless of our Pakistani roots.”",
          mobileBlurb:"“I remember days when we would be looking for pennies in the sofa so we could go buy milk.  Yet somehow, they taught us the importance of charity, the importance of respect, the importance of treating everyone equally.”",
          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'35vw', maxWidth:'35vw'},
          linkAddress:'/gilani',
          img:gilani_2,
          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginRight:'1.5vw'}}]},

{name:'Hussain',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Hussain Family",
           city: "Ottawa, ON",
           mobileTitle : "The Hussain Family",
           mobileCity: "Ottawa, ON",
           blurb:"“I remember vividly in La Ville de Québec… as a 9-10-year-old going to the mall one day, people were looking at us because we outwardly looked like minorities, especially with my mother’s hijab. I remember that young saying, “Oh! We’re the freakshow!” It was interesting to see how we were looked upon and viewed. And even that young, I was aware of us being looked at differently.”",
           mobileBlurb:"“I remember vividly in La Ville de Québec… as a 9-10-year-old going to the mall one day, people were looking at us because we outwardly looked like minorities, especially with my mother’s hijab. I remember that young saying, “Oh! We’re the freakshow!” It was interesting to see how we were looked upon and viewed. And even that young, I was aware of us being looked at differently.”",

           linkAddress:'/hussain',
           img:hussain_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},

{name:'Khan',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Khan Family",
          mobileCity: "Guelph, ON",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/khan',
          img:khan_2,

          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Khan Family",
          titleStyle: {paddingLeft:'28vw'},
          titleColSize:'col-39',
          city: "Montreal, QC",
          cityStyle: {paddingLeft:'28vw'},
          blurb:"“I see my parents as pioneers, in their business community in Montreal, as drivers of social change, as establishers of a network for women, for Muslims. They were driven to activism, they were driven to establish themselves in every way possible to build for everyone around them, and especially for those that arrived after them.”",
          mobileBlurb:"“I see my parents as pioneers, in their business community in Montreal, as drivers of social change, as establishers of a network for women, for Muslims. They were driven to activism, they were driven to establish themselves in every way possible to build for everyone around them, and especially for those that arrived after them.”",
          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'27vw', maxWidth:'27vw'},
          linkAddress:'/khan',
          img:khan_1,
          colStyle:{minWidth:'40vw',maxWidth:'40vw', marginTop:'15vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginRight:'1.5vw'}}]},

{name:'Afsar',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Afsar Family",
           city: "Edmonton, AB",
           mobileTitle : "The Afsar Family",
           mobileCity: "Edmonton, AB",
           blurb:"“We spent over a decade in the prairies in Saskatchewan. I got to see my parents work with other Muslims to build the mosque and community [there]. This was formative to my values of community work and giving back in a positive manner.”",
           mobileBlurb:"“We spent over a decade in the prairies in Saskatchewan. I got to see my parents work with other Muslims to build the mosque and community [there]. This was formative to my values of community work and giving back in a positive manner.”",
           linkAddress:'/afsar',
           img:afsar_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},





{name:'hadad',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Hadad /  Alzaabi Family",
           city: "Halifax, NS",
           mobileTitle : "The Hadad /  Alzaabi Family",
           mobileCity: "Halifax, NS",
           blurb:"“Our family has gone through hard times [so] I know how it feels when you are in need. I try to help people relieve that feeling and make them smile, or I try to do so.”",
           mobileBlurb:"“Our family has gone through hard times [so] I know how it feels when you are in need. I try to help people relieve that feeling and make them smile, or I try to do so.”",
           linkAddress:'/hadad',
           img:hadad_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'Jamal',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Jamal Family",
          mobileCity: "Vancouver, BC",
          blurb:"“People just simply need to look at humans as humans. Judge them for WHO they are, not what faith or community they come from.”",
          mobileBlurb:'',
          blurbSpacerStyle:{minWidth:'35vw', maxWidth:'35vw'},
          blurbStyle:{minWidth:'25vw', maxWidth:'25vw'},
          linkAddress:'/jamal',
          img:jamal_2,
          colStyle:{minWidth:'60vw',maxWidth:'60vw'},
          imgStyle : {minWidth:'57vw',maxWidth:'57vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Jamal Family",
          titleStyle: {paddingLeft:'28vw'},
          titleColSize:'col-39',
          city: "Toronto, ON",
          cityStyle: {paddingLeft:'28vw'},
          blurb:"",
          mobileBlurb:"“People just simply need to look at humans as humans. Judge them for WHO they are, not what faith or community they come from.”",
          linkAddress:'/jamal',
          img:jamal_1,
          colStyle:{minWidth:'40vw',maxWidth:'40vw',marginTop:'25vh'},
          imgStyle : {minWidth:'37vw',maxWidth:'37vw',marginRight:'1.5vw'}}]},

{name:'ibrahin',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "The Ibrahin Family",
           city: "Halifax, NS",
           mobileTitle : "The Ibrahin Family",
           mobileCity: "Halifax, NS",
           blurb:"“I’m proud to be a Canadian and although not everyone believes I belong here I know it is my home.”",
           mobileBlurb:"“I’m proud to be a Canadian and although not everyone believes I belong here I know it is my home.”",
           linkAddress:'/ibrahin',
           img:ibrahin_1,
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},


{name:'youssef_1',
 rowRef:'test',
 familyRowStyle:{marginBottom:'0vh'},
 isSingleImg:false,

columns:[{name:'fam1col1',
          title : "",
          city: "",
          mobileTitle : "The Youssef Family",
          mobileCity: "Vancouver, BC",
          blurb:'',
          mobileBlurb:'',
          linkAddress:'/youssef',
          img:youssef_2,
          colStyle:{minWidth:'45vw',maxWidth:'45vw',marginTop:'24vh'},
          imgStyle : {minWidth:'42vw',maxWidth:'42vw',marginLeft:'1.5vw'}},

          {name:'fam1col2',
          title : "The Youssef Family",
          titleStyle: {paddingLeft:'42vw'},
          titleColSize:'col-54',
          city: "Vancouver, BC",
          cityStyle: {paddingLeft:'42vw'},
          blurb:"“Time has grown familiarity of land, of people, of buildings, of environment. Each one of those now holds the word, ‘home’”",
          mobileBlurb:'',
          linkAddress:'/youssef',
          blurbSpacerStyle:{minWidth:'1vw', maxWidth:'1vw'},
          blurbStyle:{minWidth:'30vw', maxWidth:'30vw'},
          img:youssef_3,
          colStyle:{minWidth:'55vw',maxWidth:'55vw'},
          imgStyle : {minWidth:'52vw',maxWidth:'52vw',marginRight:'1.5vw'}}]},

{name:'youssef_2',
 testProp:'test1',
 familyRowStyle:{marginBottom:'7.5vh'},
 isSingleImg:true,

 columns:[{name:'fam1col1',
           title : "",
           city: "",
           blurb:"",
           mobileBlurb:"“Time has grown familiarity of land, of people, of buildings, of environment. Each one of those now holds the word, ‘home’”",

           img:youssef_1,
           linkAddress:'/youssef',
           colStyle:{width:'100vw'},
           imgStyle : {width:'65vw'}}]},
         ],
      windowWidth: 0,
      windowHeight: 0,
      isMediumLandscape:true,
      isMobile:false,
      showMenu:false,
      currentFam :'The Hamdon Family'
    };
     this.updateDimensions = this.updateDimensions.bind(this);
  };

  updateDimensions() {
    console.log('updating dimensions')
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    if (windowWidth>= windowHeight) {
      this.setState({isMobile:false,isMediumLandscape:true});
    }
    else {
      this.setState({isMobile:true,isMediumLandscape:false});
    }
    this.setState({ windowWidth, windowHeight },()=>{console.log('resized:'+windowWidth+ windowHeight)});
  }
  componentDidMount(){
    this.updateDimensions();
    window.addEventListener('resize',this.updateDimensions);
    this.setState({currentFam:'The Hamdon Family'})
  }

  showMenuClick = e => {
    this.setState({showMenu:true})
  };
  closeMenu = e => {
    this.setState({showMenu:false})
  };
  setCurrentFamily = e =>{


    if (e.title.length >0) {
      console.log('--------------');
      console.log('setting family');
      console.log(e);
      console.log('--------------');
      this.setState({currentFam:e.title})
    }

  }

  render() {


  return (


    <Container className = 'full-screen-container smooth-scroll-section'>

{this.state.isMobile ?

  <Row className = 'header 'style = {{marginTop:'-15vh',zIndex:3}}>

  <Col className = 'col-20  horizontal-centered-children' onClick = {this.showMenuClick}>
    <img src={table_icon} style = {{width:'7.5vw',marginLeft:'2.5vw',marginTop:'5vh'}} alt="Logo" onClick = {this.showMenuClick}/>
  </Col>


    <Col className = 'col-80  horizontal-centered-children'>
    <Link to='/'>
      <img src={logo} style = {{width:'50vw',marginRight:'15vw',marginTop:'5vh'}} alt="Logo" />
      </Link>    </Col>


    <Col className = 'col-30 '>
     &nbsp;
    </Col>


  </Row>



  :



  <Row className = 'header 'style = {{marginTop:'-15vh',zIndex:3}}>

    <Col className = 'col-5  horizontal-centered-children' >
      <img src={table_icon} style = {{width:'2vw',marginLeft:'2.5vw'}} alt="Logo" onClick={this.showMenuClick} />
    </Col>

    <Col className = 'col-10  horizontal-centered-children'>
    <p style = {{textDecoration: 'none',fontSize:'1.4vw',marginTop:'1vw',color:'black'}}>   Families </p>
    </Col>

    <Col className = 'col-25 '>
     &nbsp;
    </Col>


    <Col className = 'col-20  horizontal-centered-children'>
      <img src={logo} style = {{width:'20vw'}} alt="Logo" />
    </Col>

    <Col className = 'col-15 '>
     &nbsp;
    </Col>

    <Col className = 'col-5  horizontal-centered-children'>
      <Link to="/about" style = {{textDecoration: 'none',fontSize:'1.4vw',marginTop:'1vw',color:'black'}}className = "link">About</Link>
    </Col>

    <Col className = 'col-20  horizontal-centered-children'>
      <Link to="/land" style = {{textDecoration: 'none',fontSize:'1.4vw',marginTop:'1vw',color:'black'}}className = "link">Land Acknowledgement</Link>
    </Col>
  </Row>

}



<Row style = {{marginTop:'15vh'}}>
  &nbsp;
</Row>

<Row style = {{height:'5vh'}}>
  &nbsp;
</Row>

{this.state.showMenu ?
  <SideMenu
    closeMenu = {this.closeMenu}
    currentFam = {this.state.currentFam}
    isMobile = {this.state.isMobile}
  />:
  null}

{this.state.families.map((currentFam, i)=>{
      return (<FamilyRow
        familyRowStyle= {currentFam.familyRowStyle}
        columns = {currentFam.columns}
        testProp= {currentFam.testProp}
        isSingleImg = {currentFam.isSingleImg}
        isMobile = {this.state.isMobile}
        setCurrentFamily = {this.setCurrentFamily}
        rowRef = {currentFam.name}
        key={currentFam.name}
      />)})}

</Container>


  )}}


export default Generations
