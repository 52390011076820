// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import hadad_1 from '../familyPictures/hadad_1.jpg'
import hadad_2 from '../familyPictures/hadad_2.jpg'


const Hadad = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Hadad / Alzaabi Family'
  img1 = {hadad_1}
  caption1 = 'Left to Right: Sura Hadad, Faezah Hadad'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“Our family has gone through hard times [so] I know how it feels when you are in need. I try to help people relieve that feeling and make them smile, or I try to do so.”'

  quoteBy = '-Sura'

  paragraph2 = 'Faezah, her husband, her eldest children Sura, Sama and Hassan immigrated to Halifax in 1991. They are originally from Iraq, but were forcibly displaced due to the Iran-Iraq war. They immigrated to Halifax from Greece where they lived for six years, which was one country of many that the family travelled between in search for a better life. Faezah and her husband settled in Canada because they wanted their children to have access to better education. Although the government placed them in Halifax without their choice, they’ve asserted their roots into the city and have become an integral part of the greater Halifax community which they love. When I asked them how the first few months were in Halifax they told me, “It was scary and depressing as we didn’t know the language and we didn’t know anyone…my kids used to go to school and cry every day. It was a big change. And then the mess of the snow. We used to take the bus and when we got off the bus it would be all snow so we stepped in piles of snow. Very cold and wet.”'

  paragraph3 = 'Faezah was a school teacher back in Iraq, and intended to renew her education credentials and go back to school once in Halifax. Her youngest son, Hamzeh, arrived two years later though so she put her plans on hold to raise her children and support them as they all went on to pursue different fields and careers. Faezah became interested in supporting the wider Iraqi-Canadian community as well, and has remained active in the community throughout the almost 20 years she has lived in the city. Her husband Ala became a cab driver after immigrating and learnt the ins and outs of a city that once was strange to him. Now he knows the city like the back of his hand. Sura, Faezah’s daughter, told me, “I am most proud of the self-sacrifice my mother made for my siblings and I. Thanks to her love and commitment we were able to get through the hardships of growing up in a new country alongside the regular challenges that come with.'

  paragraph4 = "Sura’s early experiences with school did not deter her, as she began avidly embracing school and education. She went on to study at the Dalhousie Dental School and gained a Master of Education at Acadia University. She told me her proudest moment is “the day I walked on that stage to get my dental degree. My family was so happy [to see] the first doctor of the family. And I walked on stage with my daughter whom I had in 3rd year of my dental school and it was even harder studying with a baby. She was a year and a half then. It was an unforgettable moment.” She is still actively involved with Dalhousie University, where she teaches part-time at the Dental School, she is a member of the admittance committee, and she recently won a Dalhousie University Alumni Aurum Award for her incredible community service to Halifax."

  paragraph5 = 'Inspired by the difficulties her family faced during their years of displacement and early years in Canada, Sura was motivated to give and be of service at the wake of the Syrian Refugee Crisis. Although she has engaged in philanthropy and community service for many years, the way she stepped up during this crisis shone a light on her. During the crisis she realized many of the refugees were in dire need of dental work and for an entire year she opened up her dental practice to give dental work for free to refugees. She was able to partner with labs and other members of the dental profession to help her in her mission. In October 2019, inspired by what she did during the Syrian Refugee crisis, she decided to give dentistry services to those in need abroad. She went to Tanzania with Canada-Africa Community Health Alliance (CACHA). She told me about the experience: “It was my first time doing dentistry outside Halifax. I had to perform just extractions on patients that had pain and swelling and it was a very different experience. The patients were sitting on a regular chair and I was standing and all my tools were in a box so I took the box from one village to another to help. It was very sad to see how many people are suffering and can’t get help [but it’s] rewarding to help them out and feel useful.” The Nova Scotia Dental Association awarded Sura the Community Service Award for her dedication to helping others.'

  paragraph6 = 'Sura likes to involve her family as much as possible in community service as well. She and her four children cook and help serve food for people in need on a regular basis in Halifax and the surrounding cities of Dartmouth and Bedford with several organizations. During the month of Ramadan, she and her family also collect and distribute groceries to families in need. This has made an impact on her children as they grow up.'

  showPart2Label = {false}

  paragraph7 = 'Her daughter, Fatima, is following in Sura’s footsteps with her love of education and giving back. Fatima entered her first year of university at 14 years old, the age most teenagers are entering grade 9. She’s now almost completed her Bachelor of Science degree at Dalhousie University. While excelling in school ahead of schedule, she has also found causes she is passionate about and gives back to. In the summer of 2018, both Fatima and Sura went to Kenya to help build a college for girls with the One Woman group. Fatima continues to advocate for girls in Kenya, by working on a project which raises money to better the water situation there. Sura shared, “I’m proud of her all the time as she works hard at her schooling and also at helping others. She is amazing and I can’t say enough.” When I asked Sura what she hopes for in regards to the younger generations she told me, “to be good role models and caring people to younger people. And do their best in everything.”'

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {hadad_2}

  caption2 = 'Left to Right: Sura Hadad, Faezah Hadad, Fatima Alzaabi'

  extraParagraph = ''

  />
</>
)
}
export default Hadad
