// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import faruqui_1 from '../familyPictures/faruqui_1.jpg'

const Faruqui = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Faruqui Family'
  img1 = {faruqui_1}
  caption1 = 'Left to Right: Ferrukh Faruqui, Urusa Faruqui, Nishat Faruqui Khan'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = "“Our children are not Pakistani, they’re Canadian, but they still have ties to their heritage. I think as they grow up, they’re less interested in assimilation and more in being their own hybrid selves, painful though it is, to be not wholly one or the other.”"

  quoteBy = "-Ferrukh"

  paragraph2 = "Ferrukh, her mother Urusa, her father Irshad, and her siblings, arrived in Winnipeg in 1968 from Karachi, Pakistan. Urusa and Irshad had wanted to immigrate elsewhere for some time, their top choice being Iraq. Their first choice changed when Irshad, who was an architect, designed the Canadian embassy in Islamabad, and an embassy official asked him if he was interested in immigrating to Canada. They were met with opportunity from the outset of their arrival. Ferrukh shared, “they really had no difficulties. My dad had three job offers in three days and started work straight away.” Although Ferrukh was only 4 when they arrived, she told me she remembered the journey and the first couple days in Winnipeg. She said, “I remember flying over the Atlantic Ocean in the darkness. It was fascinating. I also remember arriving at the Winnipeg airport and meeting my father after 6 months. He immigrated here 6 months before we did. I remember arriving at the Lyndhurst Apartment building at 181 Balmoral Street in downtown Winnipeg. We went up to the top floor to suite #11. I remember eating the scrambled eggs my dad made for us. The apartment itself was interesting with plank hardwood floors, a huge claw footed bathtub that I was terrified of and the beautiful bay window in the living room. We arrived in March. I remember my dad teaching us some simple English words, for example, “pillow”.” When I asked Ferrukh if she can remember the political climate which her family arrived in, she told me, “It was 1968. We were more of an exotic curiosity than a threat. I don’t remember any problems but I was just a child…No one knew about Muslims then. No one knew about Pakistan either. They thought we were from India. We weren’t suspect as Muslims and immigrants are now. The economy was booming and Canada wanted immigrants.”"

  paragraph3 = "Urusa and Irshad were very involved in both the Pakistani community and the Muslim community from the outset of their arrival, so much so, they joined each communities’ associations and Irshad designed the first mosque in Manitoba in 1976. Ferrukh remembers fond memories of growing up amongst these tight knit communities: “I remember one of the first Ramadans in Winnipeg. It was January and the fasting day was very short. I remember celebrating Eid with a small group of my parent’s friends and their children. I also remember the year our mosque was completed, 1976. We were so proud that our hard work had paid off. I also remember attending Islamic camp for the first time at age 14 and being relieved to find other kids my age who had the same experiences I did. For the first time, I realized I had a community.”"

  paragraph4 = "The Hazelwood Mosque that Irshad designed was declared a heritage site in 2016 and renamed the Pioneer Mosque. Ferrukh shared, “sadly, my dad was suffering from dementia by then so he could not celebrate [it being declared a heritage site] but I’m very proud of the mosque he designed which was accessible for women and girls and children… which was women friendly without any barriers, whether structural or ideological… So much has changed. The change began in the early 80’s. Conservative newcomers began to exert their influence. One day we arrived in the mosque to see that a barrier between men and women had been put up. And things gradually deteriorated from there.” She recalls growing up belonging to a Quran Tafseer group. She elaborated, “where we discussed surahs and analyzed them. This is where we learned the value of debate, discussion and intellectual rigour. Sadly, this tradition seems to be declining. Very few religious institutions encourage this type of open thinking [now.]”"

  paragraph5 = "Even though Ferrukh came to Canada at 4-years-old and her daughter Nishat was born here, they both have had an evolution of thoughts towards how they experience belonging within their communities and in Canada in general. Ferrukh shared, “I speak my native tongue, Urdu, poorly, but I feel that I’m not fully Canadian nor fully Pakistani. I’m a melding of two cultures. I try to take the best of each. I don’t know how well I’m succeeding.” She told me that overtime these feelings have “been exacerbated by having children and wondering how much they will stray from their background and faith. This is a source of worry for me as it is for many parents in my situation. I’ve become much more interested in my Pakistani culture especially since I got married to a man who grew up there and who’s also become more interested in his own culture.” In discussions regarding how Ferrukh feels perceived within some communities, she shared, “I’m a bad Muslim according to many people because I don’t wear hijab and I speak out about things that I see are wrong. I’m a bad immigrant because I’m not ashamed of my background or faith, and I refuse to change who I am to fit in. We should never go along to get along.”"

  paragraph6 = "Nishat shared her own struggles, and how she’s come to terms with them. She shared as she grew up, “it was difficult at times to feel as though I “belonged” in Canada, despite being born and raised here, due to my childhood and my white-dominated schools and neighborhood. However, as I’ve grown up and met more people in my situation (3rd and 2nd generation Pakistani immigrants) I’ve definitely come to feel a stronger sense of community and a more grounded identity as a Canadian minority group… I think having a close group of Canadian-born-Pakistani-raised girlfriends just like myself to grow up with through the years has definitely strengthened my sense of belonging. We are all Canadian and yet we all live in Canada from a minority perspective. Having people around who understand and validate your own experiences makes a world of difference.”"

  showPart2Label = {false}

  paragraph7 = "When I asked Ferrukh and Nishat what they are most proud of they both shined a light on the generation before them. Ferrukh shared, “I’m so proud of my mother, Urusa who went back to school in her mid-forties to become a library technician. She got a job at the Legislative Library and met many MLA’s including the premier. We always knew the latest political news! I’m also proud that my parents succeeded in raising three children, including myself and modeling what moderate, faithful Islam is really like. They prized education and made sure each one of us achieved academic and professional success. They encouraged us and made us feel we could be anything we wanted to be.” Nishat added, “I personally am proud of how my family in my parent’s generation rose up from the ground and became successful through their own means, without the help of their parents. They got amazing qualifications that landed them great jobs and allowed them to raise their own children with so much privilege. The 2nd generation in my family bore most of the brunt of the clash of cultures and yet have truly stepped up to the plate and achieved their goals.”"

  paragraph8 = "Ferrukh said her hope for the next generations is for them “to be proud of their background and faith, to integrate into North American life knowing that their cultural and faith background are a solid foundation to build happiness and success. There is so much good in western culture. If they can create a North American Islam with the best attributes of both western and eastern culture, that would be a wonderful achievement.”"

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''
  extraParagraph = ''

  />
</>
)
}
export default Faruqui
