// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import ahmed_ali_1 from '../familyPictures/ahmed_ali_1.jpg'
// import ali_2 from '../familyPictures/ali_2.jpg'


const AhmedAli= ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Ali Family'
  img1 = {ahmed_ali_1}
  caption1 = 'Left to Right: Aisha Ali, Istaahil Ali, Habiba Ali'

  subCaption1 = ''

  showPart1Label = {true}

  paragraph1 = "“I belong to Canada because I make myself believe this is my home. I know that because I choose to be a contributing member to the Canadian society that I live in and choose to raise my family here, I also choose to feel like I belong…I remind myself it is Indigenous lands and no one but an Indigenous person can tell me I don’t belong here. Anytime I was told I don’t belong here or had racial slurs sent my way, they were by a non-Indigenous person so it never truly affected me. I don't know another home…. I choose to focus on the positive and it's something I almost have to do on a daily basis and remind myself that this is what I'm choosing. So that means I have to block out a lot of the unnecessary narratives that are being depicted about me. I also choose to not focus and dwell on what others believe about my belonging in Canada.”"

  paragraph2 = "Habiba (who was 5 at the time), her mother Amina, and the rest of her family arrived in Canada in May, 1990. They arrived in Toronto claiming refugee status as their country, Somalia, had just broken out in a civil war which created uncertain times and an imminent threat to their family’s safety. Habiba recalls the first year after their move fondly telling me, “it was May when we first got here and then a few months later there was snow. I ran right into it [because] I didn't know snow was cold. When I found out it was so cold it was already too late… I was shivering but I was happy! I'll never forget how happy I was that everything was white outside and it was like a blanket. On top of that it was so soft.” When I asked her if she thought her experience would have been different now, she added, “systemic barriers exist for Blacks here in Canada and I wasn't aware of these systemic issues when I first got here as a young child. These issues are really coming to the forefront of society and a lot that hasn't been said in the past about anti-black racism [which] is now being addressed.”"

  paragraph3 = "After a year in Toronto, the Ali’s moved to Ottawa where they still reside. They immediately found both a Somali and non-Somali community that was very welcoming and supportive. Habiba told me, “Where I lived there was an Islamic school that dealt with the Muslim association of Canada so we had annual Muslim festivals. We would attend a lot of events, there was fundraisers for different Muslim associations, barbeques, attending Friday prayers, halaqas. My parents have really influenced me in the early days to not limit ourselves to be part of one mosque. They encouraged us to go and find out what's happening in the city. We used to have camps where sisters who were older than us were the Muslim camp counsellors. They would take us to events and rent out campgrounds so we can learn what it means to be a Muslimah and be proud of our faith so I really loved my Islamic upbringing.”"

  paragraph4 = "Habiba really credits her parents for her positivity and her outlook. “My parents instilled civic engagement and advocacy in us at an early age... standing up against injustices, defending people who are vulnerable, defending the less privileged, standing up for worthy causes… so that's what I'm most proud of. I feel my family has contributed to not only where we lived but they've taught us to contribute to any community Muslim or non-Muslim. Their famous proverb is “Meel ama ka muuqo ama ka maqno” which is Somali and translates to “Be a contributing member in your surroundings or you will be marked absent.” It loses in translation -- essentially it means ``leave a legacy.”"

  paragraph5 = ''

  paragraph6 = ''

  showPart2Label = {true}

  paragraph7 = "“I'm from the nation of poets… that's why I love poetry, that's why I love spoken word, that's why I'm a creative… I'm not a pirate, I'm not a terrorist, I’m a talented artistic Black Muslim Woman.”"

  paragraph8 = "Habiba is leaving a legacy through her art. “I decided to become a playwright because I was sick of not getting the roles in my schools, and when I saw the people that were casted for those parts, I understood very quickly why. I want systemic barriers that affect Muslim Black women in this country to be re-written. I want trail blazers to come forth and be unapologetic about their craft. I want more people to understand that it's not easy being Black, Somali, Muslim, and an artist who is a woman.” The impacts and experiences of her gendered and racial identity are what fuels her. She told me, “As a Muslim woman you hear a lot about what the men have done in our religion, what the men do in our culture, and I find it to be very patriarchal. What about women in Islam and in art? What about the matriarchy? I know in my home my mom was the backbone of my house. She is why I am who I am…Women’s stories are what I focus on in my line of work with my art. I was always surrounded by sisters supporting sisters. It was my biological sisters that supported me first, then it was my Muslim sisters that supported me second, and then now it's all of the girls in the community. I want their stories shared and I want everyone to know what women stand for and what we bring to the table. People say we need a seat at the table, well I believe there is no seat for us at this so-called table. I am willing to help create a new and bigger table for women by women, and we can do that as a team of women.”"

  paragraph9 = "She has large hopes for her daughters, Ishtaahil and Aisha, who are pictured. “I want my daughters who also write and love acting to have role models in the industry who look like them so they can envision that they too can succeed…my hopes for [my daughters] is that they are a lot braver than I am. Because even to this day, even though I know why I started to be a playwright, even though I know why my art is important, I still struggle with self-doubt. I'm really affected by the systemic barriers that I faced early on in my life, so I want my daughters not to be afraid of the so-called glass ceiling. I want them to shatter everything. I want them to know and say, “my mom did this and then my grandma did this before her and with limited resources as an immigrant with no English proficiency.” I want them to not be afraid.”"

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = ''

  caption2 = ''

  extraParagraph = ''
  quoteBy = ''
  />
</>
)
}
export default AhmedAli
