import { Row, Col,Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import table_icon from "./icons/table_icon.png";
import logo from "./logos/logo.png";
import { useState,useEffect } from 'react';
import SidePageMenu from "./sidePageMenu.js";

const FamilyPage = ()=>{
  useEffect(() => {
      toggleDevice();

    });

  window.addEventListener('resize', toggleDevice);

  const [isMobile, toggleMobile] = useState(false);

  function toggleDevice(){
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
    console.log(windowWidth);
    console.log(windowHeight);
    if (windowHeight>windowWidth) {
      toggleMobile(true)
    }
    else if (windowWidth>=windowHeight) {
      toggleMobile(false)
    }
  }

  const [showMenu, toggleMenu] = useState(false);

  function closeMenu(){
    toggleMenu(false);
  }

return(
<>
{showMenu?

  <SidePageMenu
  closeMenu = {closeMenu}
  />
:

null}
<Container className = "full-screen-container">


{isMobile ?
  <Row className = 'header 'style = {{marginTop:'-17.5vh',zIndex:3}}>

  <Col className = 'col-20  horizontal-centered-children' onClick = {toggleMenu}>
    <img src={table_icon} style = {{width:'7.5vw',marginLeft:'10vw'}} alt="Logo" onClick = {toggleMenu}/>
  </Col>


    <Col className = 'col-80  horizontal-centered-children'>
    <Link to='/'>
      <img src={logo} style = {{width:'50vw',marginRight:'15vw'}} alt="Logo" />
      </Link>
          </Col>




  </Row>
  :
  <Row className = 'header 'style = {{marginTop:'-17.5vh',zIndex:3}}>

    <Col className = 'col-5  horizontal-centered-children'>
    &nbsp;
    </Col>

    <Col className = 'col-10  horizontal-centered-children'>
      <Link style = {{textDecoration: 'none',color:'black'}} className = "link" to="/">
        <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>   Home </p>
      </Link>
    </Col>

    <Col className = 'col-20 '>
     &nbsp;
    </Col>
    <Col className = 'col-5 '>
     &nbsp;
    </Col>

    <Col className = 'col-20  horizontal-centered-children'>
    <Link to='/'>
      <img src={logo} style = {{width:'20vw'}} alt="Logo" />
      </Link>
    </Col>

    <Col className = 'col-10 '>
     &nbsp;
    </Col>

    <Col className = 'col-10  horizontal-centered-children'>
      <Link style ={{textDecoration: 'none',color:'black'}} className = "link" to="/about">
        <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>About</p>
      </Link>
    </Col>

    <Col className = 'col-20  horizontal-centered-children'>
      <Link style = {{color:'red',textDecoration: 'none'}} className = "link" to="/land">
      < p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>Land Acknowledgement</p>
      </Link>
    </Col>
  </Row>
}
  <Row style = {{marginTop:'15vh'}}>
    &nbsp;
  </Row>
  <Row style = {{marginTop:'5vh'}}>
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>I would like to acknowledge that I live and work on the traditional territories of many Indigenous nations across Turtle Island. In the creation of this project, I photographed in the Greater Vancouver Area on the unceded Traditional Coast Salish Lands, in Edmonton on Treaty 6 territory, in Ottawa on the traditional unceded territories of the Algonquin Nation, in the Greater Toronto Area on the Dish with One Spoon territory, in Montreal on the traditional unceded territory of the Kanien’kehá:ka Nation, and in Halifax on the ancestral and unceded territory of the Mi’kmaq. These sacred territories have been gathering places of diverse Indigenous peoples and sites of human activity for over 15,000 years, and are still home to many Indigenous peoples and nations who have resisted settler-colonialism for the last few hundred years.</p>
  </Row>
  <Row >
    <p className = {(isMobile? 'bio-p-mobile page-text-mobile ' : 'bio-p page_text')}>I recognize my privilege in being a settler on these colonized lands and the benefits in which it affords me, benefits which have come at the expense of Indigenous peoples and nations. In creating a project on Muslim people’s relationship with this settler-colonial state which was formed in 1867 after European colonization, I wrestle with how our sense of belonging and citizenship can be rectified, when the Indian Act has yet to be dismantled and the effects of cultural genocide still profoundly impact the very people this land traditionally belongs to. Though I do not have an answer, I see it as my responsibility to work towards decolonization at the individual level and to carry out respect towards the traditional custodians of the land in all my work. I want to contribute to a future where violence, dehumanization, discrimination, and alienation no longer take place against any communities.</p>
  </Row>


</Container>
</>
)
}
export default FamilyPage
