// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import morsi_1 from '../familyPictures/morsi_1.jpg'
import morsi_2 from '../familyPictures/morsi_2.jpg'


const Morsi = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Morsi Family'
  img1 = {morsi_1}
  caption1 = 'Left to Right: Ragaa Morsi, Mariam Fahmy'

  subCaption1 = ''
  showPart1Label = {false}
  quoteBy = ''

  paragraph1 = '“As immigrants I believe being given better opportunities is a driving force to making something of yourself. We’re being given some of the best resources in the world; it’s a privilege to have free access to education till high school. My brothers and I always feel that we have to thank our parents with our achievements. Sometimes it places invisible pressure because my parent’s sacrifice consisted of living a long-distance relationship for 10 years, my father going through losing his parents alone, my mom trying to learn a new language while getting accustomed to life here while caring for the new relationships we’re making. I think it’s the least I could do for them.”'


  qouteBy = '-Mariam'

  paragraph2 = "Monat came to Halifax with her sons, Mohamed and Omar, and daughter, Mariam, in August of 2008 from Kuwait. Monat made the difficult decision of leaving Kuwait, which had been her home for 17 years, after she got married and left Cairo, Egypt. Moving to Halifax meant striving for a better education and quality of life for her children, but being physically a part from her husband, who needed to stay in Kuwait for his job and to take care of his parents. His mother was bedridden with Alzheimers and his father was too old to live alone. Their family knew Canada and the US well, having visited many times. Mariam remembers visiting Niagra Falls for the first time at 2 years old, and visiting several US states at 4. She told me, “I think it’s been my dad’s goal for a long time to see us live in this part of the world.”"

  paragraph3 = "Mariam was 13 at the time they immigrated. She shared that she didn’t realize they were moving until very soon before they left, “I was distraught. I remember being in the Lord Nelson Hotel and informing my friends I am moving over MSN. I didn’t get a chance to say goodbye. It was a very sad dark time for me. I felt very out of place [and] disoriented. Everything was strange and nothing was familiar. The people, the culture, the streets, the way of life were all different. I was so lost. My parents were so strong though. They were figuring out schools, apartments and getting their drivers licenses. My parents are heroes. At the time I thought this was the meanest thing they’ve done to me. I was too invested into my friends and school life like any teenager that I did not see the sacrifice my parents were making. Mom barely spoke any English and she would be solely responsible of our transition and us three.” Monat shared her perspective, “I was excited about moving to Canada. I wanted this for my kids, but I was also scared they’d lose their culture. I was scared to raise them alone for most of the time since their father would not be present. But he made sure to be there every step of the way. The first few days I was nervous but we were busy. We were trying to find an apartment. We figured a house is not ideal since we didn’t know how to recycle or what the laws were. I was mainly worried about the children adapting to a new culture, a more open one. But I believed they were well rooted in their upbringing.”"

  paragraph4 = "It was very important to Monat to instill a sense of home and tie to culture, even when she herself was figuring out the ropes of being in a new country where she couldn’t speak the language. Mariam shared about her mother, “mom started attending Immigration Services Association of Canada in 2008. She wanted to improve her language and at the time, the car we had broke down a lot so she would take the bus in the snow to get around and get us groceries. My mom never used public transportation. She walked us to school or drove us. Picked us up, made our lunches. She tried so hard to make us feel at home because we felt so unfamiliar. So, she’d drive around and find certain ingredients to make us food from Arabic supermarkets. She installed Arabic TV so we would hear the Athan. She faced some unfriendliness (rarely but it happened) because she wore the hijab so she must have felt scared at times. She made sure we called our dad at least 3 times a day. There’s also the technicalities that she handled so bravely with 0 language skills: insurance papers, school forms, rent forms etc. She was never shy to ask for help or state when she didn’t understand something. This is always an intimidating experience.”"

  paragraph5 = "When I asked Monat what she’s most proud of she told me, “I am very proud of my children for realizing the opportunity and sacrifice we made for them. The move and becoming immigrants and facing a new life was all worth it because they have a better life: better education, better healthcare, better quality of life. I am also proud of myself for raising them to remember that they belong to Egypt as much as they do Canada. They embraced their religion and culture and never forgot where they came from. As a parent this is very important to me. Canada doesn’t change us, it embraces us. I was worried about my children losing their Arabic language, their will to pray, and follow Islamic laws. In the end I trusted them and they learnt to make their own choices.” Mariam added that, “surprisingly, becoming Canadian made me more connected to my roots and culture. I embraced my Egyptian identity because I learned that I am both.”"

  showPart2Label = {false}

  paragraph6 = "When I asked Monat and Mariam what their hopes are for the next generation, Monat told me, “I hope that they live in an even more diverse Canada than the one we arrived at. I hope their kids feel normal more so than different.” Mariam added, “I hope my kids live in a more diverse and accepting Canada. One their faces are familiar in. It’s already a lot different than when we moved here. Multiculturalism and diversity strengthen communities and allows people to become more accepting and open to new beliefs, ideas, traditions and ways of life. That’s the kind of Canada I want my kids to grow up in.”"
  paragraph7 = ''
  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {morsi_2}

  caption2 = 'Left to Right: Monat-Allah Mahmoud, Ragaa Morsi'
  extraParagraph = ''
  />
</>
)
}
export default Morsi
