import { Row, Col,Container} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import table_icon from "./icons/table_icon.png"
import logo from "./logos/logo.png"
import { useState,useEffect } from 'react';
import ScrollTrigger from 'react-scroll-trigger';
import SidePageMenu from "./sidePageMenu.js";

const FamilyPage = ({familyName,img1, caption1, subCaption1,
                    showPart1Label, quoteBy,
                     paragraph1, paragraph2,
                     paragraph3, paragraph4,
                     paragraph5, paragraph6,
                     showPart2Label, extraParagraph,
                     paragraph7,paragraph8,
                     paragraph9,paragraph10,
                     showPart3Label,
                     paragraph11,
                     img2, caption2,
                     img3,caption3
                   })=>{

useEffect(() => {
    toggleDevice();
  });

window.addEventListener('resize', toggleDevice);
const [isMobile, toggleMobile] = useState(false);
function toggleDevice(){
  let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
  let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;
  if (windowHeight>windowWidth) {
    toggleMobile(true)
  }
  else if (windowWidth>=windowHeight) {
    toggleMobile(false)
  }
}


const [showP1, toggleShowP1] = useState(false);
function onEnterP1() {
  toggleShowP1(true);
  };
const [showP2, toggleShowP2] = useState(false);
function onEnterP2() {
  toggleShowP2(true);
  };
const [showP3, toggleShowP3] = useState(false);
function onEnterP3() {
  toggleShowP3(true);
  };
const [showP4, toggleShowP4] = useState(false);
function onEnterP4() {
  toggleShowP4(true);
  };
const [showP5, toggleShowP5] = useState(false);
function onEnterP5() {
  toggleShowP5(true);
  };
const [showP6, toggleShowP6] = useState(false);
function onEnterP6() {
  toggleShowP6(true);
  };
const [showP7, toggleShowP7] = useState(false);
function onEnterP7() {
  toggleShowP7(true);
  };
const [showP8, toggleShowP8] = useState(false);
function onEnterP8() {
  toggleShowP8(true);
  };
const [showP9, toggleShowP9] = useState(false);
function onEnterP9() {
  toggleShowP9(true);
  };
const [showP10, toggleShowP10] = useState(false);
function onEnterP10() {
  toggleShowP10(true);
  };
const [showP11, toggleShowP11] = useState(false);
function onEnterP11() {
  toggleShowP11(true);
  };
const [showExtraP, toggleShowExtraP] = useState(false);
function onEnterExtraP() {
  toggleShowExtraP(true);
  };
const [showImg1, toggleShowImg1] = useState(false);
function onEnterImg1() {
  toggleShowImg1(true);
  };
const [showImg2, toggleShowImg2] = useState(false);
function onEnterImg2() {
  toggleShowImg2(true);
  };
const [showImg3, toggleShowImg3] = useState(false);
function onEnterImg3() {
  toggleShowImg3(true);
  };
const [showCaption1, toggleShowCaption1] = useState(false);
function onEnterCaption1() {
  toggleShowCaption1(true);
  };
const [showCaption2, toggleShowCaption2] = useState(false);
function onEnterCaption2() {
  toggleShowCaption2(true);
  };

const [showQouteBy, toggleShowQuoteBy] = useState(false);
function onEnterQuoteBy() {
  toggleShowQuoteBy(true);
  };
const [showSubCaption, toggleShowSubCaption] = useState(false);
function onEnterSubCaption() {
  toggleShowSubCaption(true);
  };
const [showFamilyName, toggleShowFamilyName] = useState(false);
function onEnterFamilyName() {
  toggleShowFamilyName(true);
  };
const [showPart1, toggleShowPart1Label] = useState(false);
function onEnterPart1Label() {
  toggleShowPart1Label(true);
  };
const [showPart2, toggleShowPart2Label] = useState(false);
function onEnterPart2Label() {
  toggleShowPart2Label(true);
  };
const [showPart3, toggleShowPart3Label] = useState(false);
function onEnterPart3Label() {
  toggleShowPart3Label(true);
  };

  const [showMenu, toggleMenu] = useState(false);

  function closeMenu(){
    toggleMenu(false);
  }

let hasP1 = false;
let hasP2 = false;
let hasP3 = false;
let hasP4 = false;
let hasP5 = false;
let hasP6 = false;
let hasP7 = false;
let hasP8 = false;
let hasP9 = false;
let hasP10 = false;
let hasP11 = false;
let hasQuoteBy = false;
let hasExtraP = false;

if (paragraph1.length >0) {
  hasP1 = true;
}
if (paragraph2.length >0) {
  hasP2 = true;
}
if (paragraph3.length >0) {
  hasP3 = true;
}
if (paragraph4.length >0) {
  hasP4 = true;
}
if (paragraph5.length >0) {
  hasP5 = true;
}
if (paragraph6.length >0) {
  hasP6 = true;
}
if (paragraph7.length >0) {
  hasP7 = true;
}
if (paragraph8.length >0) {
  hasP8 = true;
}
if (paragraph9.length >0) {
  hasP9 = true;
}
if (paragraph10.length >0) {
  hasP10 = true;
}
if (paragraph11.length >0) {
  hasP11 = true;
}
if (extraParagraph.length>0) {
  hasExtraP = true;
}
if (quoteBy.length>0) {
  hasQuoteBy = true;
}

return(
<>
{isMobile?

  <>
  {showMenu?

    <SidePageMenu
    closeMenu = {closeMenu}
    />
  :

  null}
  <Container className = "full-screen-container">
    <Row className = 'header 'style = {{marginTop:'-17.5vh',zIndex:3}}>



    <Col className = 'col-20  horizontal-centered-children' onClick = {toggleMenu}>
      <img src={table_icon} style = {{width:'7.5vw',marginLeft:'10vw'}} alt="Logo" onClick = {toggleMenu}/>
    </Col>


      <Col className = 'col-80  horizontal-centered-children'>
      <Link to='/'>
        <img src={logo} style = {{width:'50vw',marginRight:'15vw'}} alt="Logo" />
        </Link>      </Col>




    </Row>


    <Row className = 'horizontal-centered-children'style = {{marginTop:'15vh'}}>
      <ScrollTrigger onEnter={onEnterImg1} >
        <img src={img1} alt="Logo" style = {{width:'85vw'}} className = {(showImg1? 'appear' : 'fade-in')} />
      </ScrollTrigger>
    </Row>


    <Row className = "horizontal-centered-children" style = {{height:'6vh'}}>
      <ScrollTrigger onEnter={onEnterCaption1} >
        <p className = {'caption-mobile '+(showCaption1? 'appear' : 'fade-in')}>
          {caption1}
        </p>
      </ScrollTrigger>
    </Row>
    <Row className = "horizontal-centered-children">
      <ScrollTrigger onEnter={onEnterSubCaption} >
        <p className = {'caption-mobile '+(showSubCaption? 'appear' : 'fade-in')}>
          {subCaption1}
        </p>
      </ScrollTrigger>
    </Row>

    <ScrollTrigger onEnter={onEnterFamilyName} >
      <Row className = 'horizontal-centered-children 'style = {{height:'3vh', marginTop:'5vh'}} >
        <p  className = {'name-title bio_p '+(showFamilyName? 'appear' : 'fade-in')}>
          {familyName}
        </p>
      </Row>
      <Row className = {'horizontal-centered-children '+(showFamilyName? 'appear' : 'fade-in')}>
        <hr className = "underline_mobile"/>
      </Row>
    </ScrollTrigger>

    <ScrollTrigger onEnter={onEnterPart1Label} >
      {showPart1Label ?
        <Row className = {'horizontal-centered-children '+(showPart1? 'appear' : 'fade-in')}>
          <p className = " name-title bio_p" style = {{color:'red'}} >
            Part I
          </p>
        </Row>
      :null}
    </ScrollTrigger>



    <Row>
      <ScrollTrigger onEnter={onEnterP1} >
        <p className = {'bio-p-mobile page-text-mobile  '+(showP1? 'appear' : 'fade-in')}>{paragraph1}</p>
      </ScrollTrigger>
    </Row>
    <Row className = 'horizontal-centered-children'>
      <ScrollTrigger onEnter={onEnterQuoteBy} >
        <p className = {'bio-p-mobile page-text-mobile '+(showQouteBy? 'appear' : 'fade-in')}>{quoteBy}</p>
      </ScrollTrigger>
    </Row>
    <Row>
      <ScrollTrigger onEnter={onEnterP2} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP2? 'appear' : 'fade-in')}>{paragraph2}</p>
      </ScrollTrigger>
    </Row>
    <Row>
      <ScrollTrigger onEnter={onEnterP3} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP3? 'appear' : 'fade-in')}>{paragraph3}</p>
      </ScrollTrigger>
    </Row>
    <Row>
      <ScrollTrigger onEnter={onEnterP4} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP4? 'appear' : 'fade-in')}>{paragraph4}</p>
      </ScrollTrigger>
    </Row>
    <Row>
      <ScrollTrigger onEnter={onEnterP5} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP5? 'appear' : 'fade-in')}>{paragraph5}</p>
      </ScrollTrigger>
    </Row>
    <Row>
      <ScrollTrigger onEnter={onEnterP6} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP6? 'appear' : 'fade-in')}>{paragraph6}</p>
      </ScrollTrigger>
    </Row>

    <Row>
      <ScrollTrigger onEnter={onEnterExtraP} >
        <p className = {'bio-p-mobile page-text-mobile '+(showExtraP? 'appear' : 'fade-in')}>{extraParagraph}</p>
      </ScrollTrigger>
    </Row>

    <ScrollTrigger onEnter={onEnterPart2Label} >
      {showPart2Label ?
        <Row className = {'horizontal-centered-children '+(showPart2? 'appear' : 'fade-in')}>
          <p className = "name-title bio_p" style = {{color:'red'}} >
            Part II
          </p>
        </Row>
      :null}
    </ScrollTrigger>

    <Row>
      <ScrollTrigger onEnter={onEnterP7} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP7? 'appear' : 'fade-in')}>{paragraph7}</p>
      </ScrollTrigger>
    </Row>

    <Row>
      <ScrollTrigger onEnter={onEnterP8} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP8? 'appear' : 'fade-in')}>{paragraph8}</p>
      </ScrollTrigger>
    </Row>

    <Row>
      <ScrollTrigger onEnter={onEnterP9} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP9? 'appear' : 'fade-in')}>{paragraph9}</p>
      </ScrollTrigger>
    </Row>

    <Row>
      <ScrollTrigger onEnter={onEnterP10} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP10? 'appear' : 'fade-in')}>{paragraph10}</p>
      </ScrollTrigger>
    </Row>

    <ScrollTrigger onEnter={onEnterPart3Label} >
      {showPart3Label ?
        <Row className = {'horizontal-centered-children '+(showPart3? 'appear' : 'fade-in')}>
          <p className = " name-title bio_p" style = {{color:'red'}} >
            Part III
          </p>
        </Row>
      :null}
    </ScrollTrigger>

    <Row>
      <ScrollTrigger onEnter={onEnterP11} >
        <p className = {'bio-p-mobile page-text-mobile '+(showP11? 'appear' : 'fade-in')}>{paragraph11}</p>
      </ScrollTrigger>
    </Row>

    <Row className = 'horizontal-centered-children'>
      <ScrollTrigger onEnter={onEnterImg2} >
        <img src={img2} alt ='' style = {{width:'85vw'}} className = {(showImg2? 'appear' : 'fade-in')}/>
      </ScrollTrigger>
    </Row>

    <Row className = "horizontal-centered-children">
      <ScrollTrigger onEnter={onEnterCaption2} >
        <p className = {'caption-mobile '+(showCaption2? 'appear' : 'fade-in')}>
          {caption2}
        </p>
      </ScrollTrigger>
    </Row>

    <Row className = 'horizontal-centered-children'>
      <ScrollTrigger onEnter={onEnterImg3} >
        <img src={img3} alt = '' style = {{width:'85vw'}} className = {(showImg3? 'appear' : 'fade-in')} />
      </ScrollTrigger>
    </Row>
  </Container>
  </>

  :



  <>
  <Container className = "full-screen-container">
    <Row className = 'header 'style = {{marginTop:'-23vh',zIndex:5}}>
      <Col className = 'col-15  horizontal-centered-children'>
        <Link style = {{textDecoration: 'none'}} className = "link"to="/">
          <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw',marginLeft:'-2vw'}}>   Home </p>
        </Link>
      </Col>

      <Col className = 'col-20 '>
       &nbsp;
      </Col>
      <Col className = 'col-5 '>
       &nbsp;
      </Col>

      <Col className = 'col-20  horizontal-centered-children'>
      <Link to="/">
        <img src={logo} style = {{width:'20vw'}} alt="Logo" />
        </Link>
      </Col>

      <Col className = 'col-10 '>
       &nbsp;
      </Col>

      <Col className = 'col-10  horizontal-centered-children'>
        <Link style = {{textDecoration: 'none'}} className = "link" to="/about">
          <p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>About</p>
        </Link>
      </Col>

      <Col className = 'col-20  horizontal-centered-children'>
        <Link style = {{textDecoration: 'none'}}  className = "link" to="/land">
          < p style = {{textDecoration: 'none',fontSize:'1.6vw',marginTop:'1vw'}}>Land Acknowledgement</p>
        </Link>
      </Col>
    </Row>


    <Row className = 'horizontal-centered-children'style = {{marginTop:'20vh'}}>
      <ScrollTrigger onEnter={onEnterImg1} >
        <img src={img1} alt="Logo" style = {{maxWidth:'60vw',maxHeight:'60vw'}} className = {(showImg1? 'appear' : 'fade-in')} />
      </ScrollTrigger>
    </Row>


    <Row className = "horizontal-centered-children" style = {{minHeight:'3vh',maxHeight:'6vh'}}>
      <Col className = 'col-30'>
      &nbsp;
      </Col>
      <Col className = 'col-40'>
        <ScrollTrigger onEnter={onEnterCaption1} >
          <p className = {'caption '+(showCaption1? 'appear' : 'fade-in')} >
            {caption1}
          </p>
        </ScrollTrigger>
      </Col>
      <Col className = 'col-30'>
      &nbsp;
      </Col>
    </Row>

    <Row className = "horizontal-centered-children">
      <ScrollTrigger onEnter={onEnterSubCaption} >
        <p className = {'caption '+(showSubCaption? 'appear' : 'fade-in')}>
          {subCaption1}
        </p>
      </ScrollTrigger>
    </Row>

    <ScrollTrigger onEnter={onEnterFamilyName} >
      <Row className = 'horizontal-centered-children 'style = {{height:'3vh', marginTop:'5vh'}} >
        <p  className = {'name-title bio_p '+(showFamilyName? 'appear' : 'fade-in')}>
          {familyName}
        </p>
      </Row>
      <Row className = {'horizontal-centered-children '+(showFamilyName? 'appear' : 'fade-in')}>
        <hr className = "underline"/>
      </Row>
    </ScrollTrigger>

    <ScrollTrigger onEnter={onEnterPart1Label} >
      {showPart1Label ?
        <Row className = {'horizontal-centered-children '+(showPart1? 'appear' : 'fade-in')}>
          <p className = " name-title bio_p" style = {{color:'red'}} >
            Part I
          </p>
        </Row>
      :null}
    </ScrollTrigger>


    {hasP1 ?<Row>
      <ScrollTrigger onEnter={onEnterP1} >
        <p className = {'bio-p page_text '+(showP1? 'appear' : 'fade-in')}>{paragraph1}</p>
      </ScrollTrigger>
    </Row>
      :
      null
    }

    {hasQuoteBy ?
      <Row className = 'horizontal-centered-children'>
        <ScrollTrigger onEnter={onEnterQuoteBy} >
          <p className = {'bio-p page_text '+(showQouteBy? 'appear' : 'fade-in')}>{quoteBy}</p>
        </ScrollTrigger>
      </Row>: null}

    {hasP2 ?
      <Row>
        <ScrollTrigger onEnter={onEnterP2} >
          <p className = {'bio-p page_text '+(showP2? 'appear' : 'fade-in')}>{paragraph2}</p>
        </ScrollTrigger>
      </Row>
      :
      null
    }

    {hasP3 ?
      <Row>
        <ScrollTrigger onEnter={onEnterP3} >
          <p className = {'bio-p page_text '+(showP3? 'appear' : 'fade-in')}>{paragraph3}</p>
        </ScrollTrigger>
      </Row>
      :
      null
    }

    {hasP4 ?
      <Row>
        <ScrollTrigger onEnter={onEnterP4} >
          <p className = {'bio-p page_text '+(showP4? 'appear' : 'fade-in')}>{paragraph4}</p>
        </ScrollTrigger>
      </Row>
      :
      null
    }
    {hasP5 ?
      <Row>
        <ScrollTrigger onEnter={onEnterP5} >
          <p className = {'bio-p page_text '+(showP5? 'appear' : 'fade-in')}>{paragraph5}</p>
        </ScrollTrigger>
      </Row>
      :
      null
    }
    {hasP6 ?
      <Row>
        <ScrollTrigger onEnter={onEnterP6} >
          <p className = {'bio-p page_text '+(showP6? 'appear' : 'fade-in')}>{paragraph6}</p>
        </ScrollTrigger>
      </Row>

      :
      null
    }

{hasExtraP?
  <Row>
    <ScrollTrigger onEnter={onEnterExtraP} >
      <p className = {'bio-p page_text '+(showExtraP? 'appear' : 'fade-in')}>{extraParagraph}</p>
    </ScrollTrigger>
  </Row>
  :null}



    <ScrollTrigger onEnter={onEnterPart2Label} >
      {showPart2Label ?
        <Row className = {'horizontal-centered-children '+(showPart2? 'appear' : 'fade-in')}>
          <p className = "name-title bio_p" style = {{color:'red'}} >
            Part II
          </p>
        </Row>
      :null}
    </ScrollTrigger>

    {hasP7?
      <Row>
        <ScrollTrigger onEnter={onEnterP7} >
          <p className = {'bio-p page_text '+(showP7? 'appear' : 'fade-in')}>{paragraph7}</p>
        </ScrollTrigger>
      </Row>
      :null}



    {hasP8?
      <Row>
        <ScrollTrigger onEnter={onEnterP8} >
          <p className = {'bio-p page_text '+(showP8? 'appear' : 'fade-in')}>{paragraph8}</p>
        </ScrollTrigger>
      </Row>
      :null}


    {hasP9?
      <Row>
        <ScrollTrigger onEnter={onEnterP9} >
          <p className = {'bio-p page_text '+(showP9? 'appear' : 'fade-in')}>{paragraph9}</p>
        </ScrollTrigger>
      </Row>
      :null}


    {hasP10?
      <Row>
        <ScrollTrigger onEnter={onEnterP10} >
          <p className = {'bio-p page_text '+(showP10? 'appear' : 'fade-in')}>{paragraph10}</p>
        </ScrollTrigger>
      </Row>
      :null}


    <ScrollTrigger onEnter={onEnterPart3Label} >
      {showPart3Label ?
        <Row className = {'horizontal-centered-children '+(showPart3? 'appear' : 'fade-in')}>
          <p className = " name-title bio_p" style = {{color:'red'}} >
            Part III
          </p>
        </Row>
      :null}
    </ScrollTrigger>

    {hasP11?
      <Row>
        <ScrollTrigger onEnter={onEnterP11} >
          <p className = {'bio-p page_text '+(showP11? 'appear' : 'fade-in')}>{paragraph11}</p>
        </ScrollTrigger>
      </Row>
      :null}


    <Row className = 'horizontal-centered-children'>
      <ScrollTrigger onEnter={onEnterImg2} >
        <img src={img2}  style = {{maxWidth:'60vw',maxHeight:'60vw',marginTop:'5vh'}} alt = '' className = {(showImg2? 'appear' : 'fade-in')}/>
      </ScrollTrigger>
    </Row>

    {caption2.length>0?
    <Row className = "horizontal-centered-children"style = {{marginBottom:'5vh'}}>
      <ScrollTrigger onEnter={onEnterCaption2} >
        <p className = {'caption '+(showCaption2? 'appear' : 'fade-in')}>
          {caption2}
        </p>
      </ScrollTrigger>
    </Row>
      :
      <Row style = {{height:'3vh'}}>
      &nbsp;
      </Row>
    }

    <Row className = 'horizontal-centered-children'>
      <ScrollTrigger onEnter={onEnterImg3} >
        <img src={img3} alt = '' style = {{maxWidth:'60vw',maxHeight:'60vw'}} className = {(showImg3? 'appear' : 'fade-in')} />
      </ScrollTrigger>
    </Row>

    <Row className = "horizontal-centered-children" style = {{marginBottom:'10vh'}}>

        <p className = {'caption '}>
          {caption3}
        </p>

    </Row>
  </Container>
  </>
}
</>
)
}
export default FamilyPage
