// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import bejkosalaj_1 from '../familyPictures/bejkosalaj_1.jpg'
import bejkosalaj_2 from '../familyPictures/bejkosalaj_2.jpg'


const Bejkosalaj = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Bejkosalaj-Kuki Family'
  img1 = {bejkosalaj_2}
  caption1 = 'Left to Right: Noor Kuki, Amina Kuki, Lumturi Bejkosalaj'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = '“My parents always believed that your life should serve a purpose. Their marriage and partnership symbolized the union of two individuals that cared deeply for their country of origin, their religion, but also their Canadian community at large.”'

  paragraph2 = "Lumturi’s parents met in Canada, but both were anti-communists who fled Albania when communism took over in 1944. Her father, Nysret came to Ottawa in 1949 and worked on farm outside of the city. Lumturi said, “being raised in the city, it was a hard transition for him to be working manual labour and rugged farm life.” Her mother, Shano, came with her family to Canada in 1952 when she was 10 years old, after spending 9 years in refugee camps in Greece and Italy. Shano’s family arrived at Pier 21 in Halifax by ship, then rode the national rail to Toronto, where they settled for some time. Lumturi said that her mother’s family struggled upon their arrival. They couldn’t speak any English so they relied on a few Albanians to translate for them since there were no services for newcomers, and they struggled to find lodging because “most owners wouldn’t rent apartments to families with children because they didn’t want noise nor immigrants.” On top of that, Lumturi shared her mother was often “labelled in school as a dirty “DP” (displaced person). Immigrants were not welcome. She went home crying to her mother [because] she couldn’t understand why she was being called that when she was showered and clean.”"

  paragraph3 = 'When both her parents were connected through the Albanian communities, they married and settled in Ottawa and became founding members of the Muslim community there. They were also founding members of the Ottawa Main Mosque in 1962. Lumturi shared, “it was a close knit group that banned together regardless of their sect, nationality or culture. I have incredibly warm memories of growing up in such a community. We celebrated the holidays together and socialized greatly with one another.” She continued, “the community has greatly changed – it has expanded beyond the point of knowing everyone. In my parents’ time, there were lots of community gatherings – fundraising activities, bazaars and bake sales where people spent the time and contributed to the community. The sense of community has been lost along the way.”'

  paragraph4 = "Lumturi beams with so much pride thinking about how much her parents, who have now passed, have impacted different communities across Ottawa. She told me they were known as the unofficial ambassadors of Albania for how deeply they were involved in the Albanian community while the country was communist and closed off from the world. “They proudly represented Albania in social activities and government platforms. Our home was open to fellow Albanian compatriots, day and night where Albanian customs and traditions remained alive and passed down to the next generation.” In 2014, Shano was honoured for how well she represented Albanian values and traditions among its diasporic community. The Republic of Albania awarded her with an honorary medal titled “Pride of the Nation.” Shano was also greatly involved in the Muslim community and general Ottawa community, serving as the president of the Ottawa Women’s Auxiliary as well as the president of the Ottawa Women’s Muslim Organization. Her dedication to service lead her to be the recipient of the “Ontario Volunteer Service Award” in 2006 and the “Canadian Muslim Women Who Inspire” Award in 2012. Lumturi told me, “they believed in helping humanity from all walks of life and touched people’s lives beyond measure. All while raising a family and owning successful businesses.”"

  paragraph5 = 'Shano had four children in Ottawa, Lumturi being her youngest. After receiving a BA in psychology and sociology, spending much of her time volunteering (what was noted above just scratches the surface), and raising her family, she went on to become a professional interpreter with the Refugee Board for over 20 years. When the Kosovar refugees came to Canada, her services were in high demand, she even found herself alongside 14 women in childbirth. Shano’s husband, Nysret, was extremely well known as a successful businessman as well. He owned four restaurants with more than 300 employees. He dedicated much of his time sponsoring and supporting refugees. He was a pillar of support to many of them, helping them buy homes, find employment, and bring over more members of their family from abroad. Their utmost dedication to bettering the lives of refugees may have been born from their personal experiences, or just their exuberant love for all citizens of the world. Shano was someone who permeated boundless love, generosity, and encouragement to those around her. You could feel it with her wonderful laugh or her constant smile. The ones who saw always these qualities first and foremost was her family, who were always her top priority. But making people feel the same way her family did lead her to bring people together often, whether it be in her home, or at a formal gala.'

  paragraph6 = 'Lumturi follows in her parent’s footsteps, with both her dedication to the Ottawa community and her entrepreneurial spirit. Her hopes for the daughters, Amina and Noor, is that they will “continue to be hard working and honest Canadian citizens while remaining fiercely Albanian patriots, maintaining their roots and language as well as being devout practicing Muslims.”'

  showPart2Label = {false}

  paragraph7 = ''

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {bejkosalaj_1}

  caption2 = 'Pictured in Frame: Shano Beqaj Bejkosalaj'

  extraParagraph = ''

  quoteBy = ''
  />
</>
)
}
export default Bejkosalaj
