// import { Row, Col, Container} from 'react-bootstrap';
import FamilyPage from '../familyPage.js'
import badawi_1 from '../familyPictures/badawi_1.jpg'
import badawi_2 from '../familyPictures/badawi_2.jpg'
import badawi_3 from '../familyPictures/badawi_3.jpg'

const Badawi = ()=>{

return(
<>
  <FamilyPage
  familyName = 'The Badawi Family'
  img1 = {badawi_1}
  caption1 = 'Left to Right: Huriya Gattous, Iman Badawi, Aisha Gattous, Yesir Alamin, Azza Elshazly Badawi, Noha Badawi, Amina Khan, Zaynab Khan, Tasneem Gattous'

  subCaption1 = ''

  showPart1Label = {false}

  paragraph1 = "“The value the Badawi family brought to the community doesn’t lie in a single story or event. The entire family has been a pillar of the community in so many ways; from major achievements such as founding schools and community centres to hosting events and bringing people together.”"

  quoteBy = "- Aisha"

  paragraph2 = "Azza, her husband Jamal, and her two eldest daughters, arrived in Halifax in 1970. They are originally from Egypt but came to Halifax for her husband’s job at Saint Mary’s University after spending 7 years in the US doing their graduate studies, where Azza earned her Master’s Degree in Education. She told me, “my husband enjoyed his work and I liked Halifax and living by the ocean. The Muslim community was small and friendly. At that time, we had no plans to immigrate to Canada. We arrived as a family of four. Now we have five children, five children in-law, twenty-three grandchildren and three great grandchildren.”"

  paragraph3 = "Azza looks back fondly at the early days, where she loved exploring her new city, and became well acquainted with both the Saint Mary’s faculty and the small Muslim community who embraced them with warmth and friendliness. She told me, “I was a part of the early Muslim community in Halifax. The community was small, around 20 families. When we arrived in 1970 there was no masjid in Halifax. The community met in the basement of the church at the corner of Robie Street and Coburg Road, in the heart of the city. Less than 2 years later, the masjid was built [which]… we participated in the building of. We met every Sunday in the masjid. The children had Islamic school in the morning, followed by dhuhr prayer and afterward socializing over tea and snacks. We enjoyed summer camping together on a lovely lake. We all knew each other and offered help and support when needed. It was a highly educated community, mostly doctors and university professors. Now the Muslim community is so big with at least six masjids in Halifax. There are a lot of immigrants from war-torn Muslim countries. The community is so much larger and much more diverse.” Azza thinks the reception of immigrants is much different now than when she and her family arrived, telling me, “Canadian culture has changed a lot since the early seventies with the rise of Islamophobia, racism and white supremacy."

  paragraph4 = "An interesting moment from that time that stood out to Azza was: “In the early days, no Muslim women in eastern Canada wore hijab except for me and my daughters. A lot of times we were mistaken for Catholic nuns and were given the courtesy and respect. One time an older gentleman gave the courtesy and asked, ‘which order are you sister?’ Without thinking much, I said ‘none.’ He understood it to be ‘nun.’ I had to explain that I am a Muslim not a nun.”"

  paragraph5 = "When I asked some of the family members what they are most proud of regarding their family, they all humbly avoided their own accomplishments and generously showered the others with love and admiration. What was a commonality between them all is their deep value in servitude, whether it be how Azza volunteered for her community for almost 35 years, or how Iman was involved in establishing one of the Islamic schools in Halifax and how she and her husband opened one of the local mosques, or how Noha has a deep commitment to volunteering in her community and at the Islamic schools, or how Yesir works in a youth group home. Another strong commonality between each person’s response was how the family has strong values for patience, generosity, kindness, and are calm and resilient during even the most difficult of times. Iman shared, “I will say that I am endlessly amazed by the immeasurable kindness of my parents, their unconditional love and support for all of us, and their gentle wisdom. They are ever ready to lend a compassionate ear and offer loving advice. Their personalities exude faith and devotion to God, and inspire all of us to reach for excellence. The selflessness that I have witnessed from my parents, as they devoted their lives to serving humanity, is unmatched, and is an ocean of inspiration for me.”"

  paragraph6 = "Yesir, Azza’s daughter-in-law, told me about her immediate family. The Alamin’s came to Canada in 1990 from the United Kingdom, where her parents completed their PhDs. She told me, “the first Gulf War was happening at the time my parents finished their doctorates. My parents didn’t want to bring my brothers and I back to a war-torn country (Iraq). They decided Canada was a great place to raise their children.” Yesir told me what she’s most proud of is how her family “adapted to Canadian society, received a higher education, while still maintaining our cultural values and religious beliefs. My mom is a very spiritual person. She is also always ambitious, and very hard working. She has passed that onto me, and I hope I too will pass that on to my daughters.” When I asked Yesir what she thinks has changed the most between each generation of women, she told me, “the increased drive to accomplish more. To have our voices heard within our communities, workforce, and in our everyday lives. Women are amazing, all women from all communities, not just Muslim. We are strong, intelligent, and nurturing, and each generation is empowering the next to do more."

  showPart2Label = {false}

  paragraph7 = "Even though the Badawi family’s success is plentiful, they all agreed that they did not want to fall into the dichotomy of good immigrant and bad immigrant. Iman continued to say, “Immigrants should not be made to feel that they have to reach society’s ideal of success in order to be accepted and respected. Labeling immigrants as good or bad is offensive. After all, a non-immigrant citizen who fails to achieve ‘success’ is not labeled as a ‘bad person.’” When I asked the family what their hopes are for the next generation, I got a wide range of answers. Zaynab told me, “I hope they will grow in a safe environment with less racism and discrimination. I also hope that when people ask, “Where are you from?” and they answer, “Canada” the reply won’t be, “Where are you REALLY from?” Amina told me, “I want them to be comfortable being Muslim without feeling like they don’t fit in with the majority. I want them to be proud of their religious and cultural backgrounds and their identity.” Tasneem told me, “I hope that they will be able to let their voices be heard and persist even if people around them try to silence them. I hope that they will be able to speak out about things that are important to them.” Huriya added, “I would want them to not be afraid to hold on to Islam even if they’re living in a culture where most people are not Muslim.” Yesir ended by saying, “My hope… is [they] find true happiness. We all have our journey in this life. I hope they always feel supported and loved during their journey, whatever that may be.”"

  paragraph8 = ''

  paragraph9 = ''

  paragraph10 = ''

  showPart3Label = {false}

  paragraph11 = ''


  img2 = {badawi_2}

  caption2 = ''

  img3 = {badawi_3}
  extraParagraph = ''


  />
</>
)
}
export default Badawi
